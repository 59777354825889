import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import styles from "common/styles/widgets.js";
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import Box from "@material-ui/core/Box";
import FormikField from "components-lib/FormikField/FormikField";
import Button from "components/CustomButtons/Button.js";
import Typography from "@material-ui/core/Typography";
import { colors } from "common/styles/configLayout.js";
import EditIcon from '@material-ui/icons/Edit';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { pathOr } from 'rambda';
import CheckIcon from '@material-ui/icons/Check';
import BlockIcon from '@material-ui/icons/Block';
import { connect } from 'react-redux';
import UCI_SYNC_MEMBER from 'queries/MembersQueries/uciSyncMember';
import ISS_SYNC_MEMBER from 'queries/MembersQueries/issSyncMember';
import RepeatIcon from '@material-ui/icons/Repeat';
import { variables } from 'common/styles/configLayout'
import RoleEnum from 'common/enums/RoleEnum';

const useStyles = makeStyles((theme) => styles(theme))

const PersonalDataCard = (props) => {
    const { intl, role, formikProps, allLocations, readMode, setReadMode, isMyProfile, setValidateParam, validateParam, identifierData, refetch, disableButton } = props;
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [uciSyncMember] = useMutation(UCI_SYNC_MEMBER)
    const [issSyncMember] = useMutation(ISS_SYNC_MEMBER)

    // useEffect(() => {
    //     console.log(formikProps)
    // }, [formikProps]);

    const handleUciSyncMember = (memberId) => {
        uciSyncMember({
            variables: {
                memberId: memberId,
                background: false
            },
        }).then((response) => {
            enqueueSnackbar( intl.formatMessage({ id: 'member.personalDataCard.snackbar.success.uci' }), { variant: 'success' });
            refetch();
        }).catch((err) => {
            console.log('[Error]: ', err);
        });
    }

    const handleIssSyncMember = (memberId) => {
        issSyncMember({
            variables: {
                memberId: memberId,
                background: false
            },
        }).then((response) => {
            enqueueSnackbar(intl.formatMessage({ id: 'member.personalDataCard.snackbar.success.iss' }), { variant: 'success' });
            refetch();
        }).catch((err) => {
            console.log('[Error]: ', err);
        });
    }

    const handleValidation = (formikProps) => {
        formikProps.validateForm()
        if (formikProps.isValid) {
            setValidateParam(true)
            formikProps.handleSubmit()
        } else if (!formikProps.isValid) {
            setValidateParam(true)
            formikProps.handleSubmit()
        } else
            console.log("ERROR")
    }

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="body1" style={{ color: colors.primary }}>{intl.formatMessage({ id: 'profile.personalDataCard.personalTitle' })}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormikField
                                name="email"
                                labelText={intl.formatMessage({ id: 'personal.email' })}
                                formikProps={formikProps}
                                readMode={(identifierData?.person?.profile?.email) ? true : readMode}
                                validateParam={validateParam}
                                highlightChange
                                mandatory={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormikField
                                name="firstName"
                                labelText={intl.formatMessage({ id: 'personal.firstName' })}
                                formikProps={formikProps}
                                readMode={readMode}
                                validateParam={validateParam}
                                highlightChange
                                mandatory={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormikField
                                name="lastName"
                                labelText={intl.formatMessage({ id: 'personal.lastName' })}
                                formikProps={formikProps}
                                readMode={readMode}
                                validateParam={validateParam}
                                highlightChange
                                mandatory={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormikField
                                name="preNominals"
                                labelText={intl.formatMessage({ id: 'personal.preNominals' })}
                                formikProps={formikProps}
                                readMode={readMode}
                                validateParam={validateParam}
                                highlightChange
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormikField
                                name="postNominals"
                                labelText={intl.formatMessage({ id: 'personal.postNominals' })}
                                formikProps={formikProps}
                                readMode={readMode}
                                validateParam={validateParam}
                                highlightChange
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormikField
                                name="personalId"
                                labelText={intl.formatMessage({ id: 'personal.idNumber' })}
                                formikProps={formikProps}
                                readMode={readMode}
                                validateParam={validateParam}
                                type="number"
                                highlightChange
                                mandatory={(formikProps?.values?.countryOfBirth === variables.defaultCountry)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormikField
                                name="birth"
                                labelText={intl.formatMessage({ id: 'personal.birth' })}
                                formikProps={formikProps}
                                readMode={readMode}
                                validateParam={validateParam}
                                type="datePicker"
                                highlightChange
                                mandatory={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormikField
                                name="gender"
                                labelText={intl.formatMessage({ id: 'personal.gender' })}
                                formikProps={formikProps}
                                readMode={readMode}
                                validateParam={validateParam}
                                mandatory={true}
                                type="select"
                                options={[
                                    {
                                        value: 'Male', 
                                        label: intl.formatMessage({ id:  'select.Male' })
                                    },
                                    {
                                        value: 'Female',
                                        label: intl.formatMessage({ id:  'select.Female' })
                                    },

                                ]}
                                highlightChange
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormikField
                                name="phone"
                                labelText={intl.formatMessage({ id: 'personal.phone' })}
                                formikProps={formikProps}
                                readMode={readMode}
                                validateParam={validateParam}
                                highlightChange
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormikField
                                name="countryOfBirth"
                                labelText={intl.formatMessage({ id: 'personal.countryOfBirth' })}
                                formikProps={formikProps}
                                validateParam={validateParam}
                                type="autocomplete"
                                options={allLocations.countries}
                                highlightChange={false}
                                readMode={readMode}
                                mandatory={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormikField
                                name="nationality"
                                labelText={intl.formatMessage({ id: 'personal.nationality' })}
                                formikProps={formikProps}
                                validateParam={validateParam}
                                type="autocomplete"
                                options={allLocations.countries}
                                highlightChange={false}
                                readMode={readMode}
                                mandatory={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormikField
                                name="isYouthWorker"
                                labelText={intl.formatMessage({ id: 'personal.isYouthWorker' })}
                                formikProps={formikProps}
                                validateParam={validateParam}
                                type="select"
                                options={[
                                    {
                                        value: true,
                                        label: intl.formatMessage({ id:  'select.true' })
                                    },
                                    {
                                        value: false,
                                        label: intl.formatMessage({ id:  'select.false' })
                                    },

                                ]}
                                highlightChange={false}
                                mandatory={true}
                                readMode={readMode}
                            />
                        </Grid>
                        {!isMyProfile && <>
                            <Grid item xs={12}><Typography variant="body1" style={{ color: colors.primary }}>{intl.formatMessage({ id: 'member.personalDataCard.uci.title' })}</Typography></Grid>
                            <Grid item xs={6}>
                                <Box className={classes.readOnlyGrid} style={{ height: "100%" }}>
                                    <Typography variant="body2" style={{ fontSize: "0.8em", color: colors.labelAndBorderInputs }}>{intl.formatMessage({ id: 'member.personalDataCard.federationId' })}</Typography>
                                    <Typography variant="body2" style={{ fontSize: "1em" }}>{(identifierData?.federationId) ? (identifierData?.federationId) : "-"}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box className={classes.readOnlyGrid} style={{ height: "100%" }}>
                                    <Typography variant="body2" style={{ fontSize: "0.8em", color: colors.labelAndBorderInputs }}>{intl.formatMessage({ id: 'member.personalDataCard.uciId' })}</Typography>
                                    <Typography variant="body2" style={{ fontSize: "1em" }}>{(identifierData?.uciId) ? (identifierData?.uciId) : "-"}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box className={classes.readOnlyGrid} style={{ height: "100%" }}>
                                    <Typography variant="body2" style={{ fontSize: "0.8em", color: colors.labelAndBorderInputs }}>{intl.formatMessage({ id: 'member.personalDataCard.issSyncedAt' })}</Typography>
                                    <Typography variant="body2" style={{ fontSize: "1em" }}>{(identifierData?.issSyncedAt) ? (moment(identifierData?.issSyncedAt).format("DD.MM.YYYY HH:mm:ss")) : "-"}</Typography>
                                    {(role === RoleEnum.ADMIN) &&
                                        <Grid container spacing={0}>
                                            <Grid item xs={12} align="center" style={{ padding: "0.5rem 0", }}>
                                                <Button 
                                                    style={{ textAlign: "center",}} 
                                                    table 
                                                    width= "auto"
                                                    minWidth= "6.5rem"
                                                    whiteSpace= "normal"
                                                    onClick={() => handleIssSyncMember(identifierData?.id)} round color="primary" 
                                                ><RepeatIcon /> {intl.formatMessage({ id: 'member.personalDataCard.button.iss' })}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    }

                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box className={classes.readOnlyGrid} style={{ height: "100%" }}>
                                    <Typography variant="body2" style={{ fontSize: "0.8em", color: colors.labelAndBorderInputs }}>{intl.formatMessage({ id: 'member.personalDataCard.uciSyncedAt' })}</Typography>
                                    <Typography variant="body2" style={{ fontSize: "1em" }}>{(identifierData?.uciSyncedAt) ? (moment(identifierData?.uciSyncedAt).format("DD.MM.YYYY HH:mm:ss")) : "-"}</Typography>
                                    {(role === RoleEnum.ADMIN) &&
                                        <Grid container spacing={0}>
                                            <Grid item xs={12} align="center" style={{ padding: "0.5rem 0" }}>
                                                <Button 
                                                    table
                                                    width= "auto"
                                                    minWidth= "6.5rem"
                                                    whiteSpace= "normal"
                                                    onClick={() => handleUciSyncMember(identifierData?.id)} round color="primary" ><RepeatIcon /> {intl.formatMessage({ id: 'member.personalDataCard.button.uci' })}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    }

                                </Box>
                            </Grid>
                        </>
                        }
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}><Typography variant="body1" style={{ color: colors.primary }}>{intl.formatMessage({ id: 'profile.personalDataCard.addressTitle' })}</Typography></Grid>
                        <Grid item xs={12} sm={8}>
                            <FormikField
                                name="address1"
                                labelText={intl.formatMessage({ id: 'address.street' })}
                                formikProps={formikProps}
                                readMode={readMode}
                                validateParam={validateParam}
                                highlightChange
                                mandatory={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormikField
                                name="address2"
                                labelText={intl.formatMessage({ id: 'address.streetNumber' })}
                                formikProps={formikProps}
                                readMode={readMode}
                                validateParam={validateParam}
                                highlightChange
                                mandatory={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormikField
                                name="postal"
                                labelText={intl.formatMessage({ id: 'address.postal' })}
                                formikProps={formikProps}
                                readMode={readMode}
                                validateParam={validateParam}
                                highlightChange
                                mandatory={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8} >
                            <FormikField
                                name="city"
                                disabled={!formikProps?.values?.country}
                                labelText={intl.formatMessage({ id: 'address.city' })}
                                type={(formikProps?.values?.country === variables.defaultCountry) ? "autocomplete" : ''}
                                options={(formikProps?.values?.country === variables.defaultCountry) ?  allLocations.cities : []}
                                formikProps={formikProps}
                                readMode={readMode}
                                validateParam={validateParam}
                                highlightChange
                                mandatory={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormikField
                                name="country"
                                labelText={intl.formatMessage({ id: 'address.country' })}
                                type="autocomplete"
                                options={allLocations.countries}
                                formikProps={formikProps}
                                readMode={readMode}
                                validateParam={validateParam}
                                highlightChange
                                mandatory={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormikField
                                name="district"
                                disabled={!formikProps?.values?.country}
                                labelText={intl.formatMessage({ id: 'personal.district' })}
                                type={(formikProps?.values?.country === variables.defaultCountry) ? "autocomplete" : ''}
                                options={(formikProps?.values?.country === variables.defaultCountry) ? allLocations.districts : []}
                                formikProps={formikProps}
                                readMode={readMode}
                                validateParam={validateParam}
                                highlightChange
                                mandatory={true}
                            />
                        </Grid>
                        <Grid item xs={12}><Typography variant="body1" style={{ color: colors.primary, paddingTop: "16px", paddingBottom: "3px" }}>{intl.formatMessage({ id: 'profile.personalDataCard.emergencyContactTitle' })}</Typography></Grid>
                        <Grid item xs={12} sm={6}>
                            <FormikField
                                name="emergencyFirstName"
                                labelText={intl.formatMessage({ id: 'personal.firstName' })}
                                formikProps={formikProps}
                                readMode={readMode}
                                validateParam={validateParam}
                                highlightChange
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormikField
                                name="emergencyLastName"
                                labelText={intl.formatMessage({ id: 'personal.lastName' })}
                                formikProps={formikProps}
                                readMode={readMode}
                                validateParam={validateParam}
                                highlightChange
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormikField
                                name="emergencyPhone"
                                labelText={intl.formatMessage({ id: 'personal.phone' })}
                                formikProps={formikProps}
                                readMode={readMode}
                                validateParam={validateParam}
                                highlightChange
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid >
            {role !== RoleEnum.MEMBER &&
                <Box className={classes.floatRight}>
                    <br />
                    <Button
                        color="primary"
                        size="sm"
                        disabled={disableButton}
                        round
                        onClick={() => {
                            (readMode)
                                ? setReadMode(readMode ? false : true)
                                : handleValidation(formikProps)
                        }}
                    >
                        {(readMode)
                            ? <><EditIcon /> {intl.formatMessage({ id: 'button.editMode' })}</>
                            : <><CheckIcon /> {intl.formatMessage({ id: 'button.saveChanges' })}</>
                        }
                    </Button>
                    {!readMode &&
                        < Button
                            // color="primary"
                            size="sm"
                            round
                            onClick={() => { formikProps.resetForm(); setReadMode(readMode ? false : true) }}
                        >
                            <BlockIcon /> {intl.formatMessage({ id: 'button.cancelChanges' })}
                        </Button>
                    }
                </Box>
            }
        </>
    )
}

const mapStateToProps = (state) => ({
    allLocations: state.allLocations,
    role: state.role,
});

export default connect(mapStateToProps)(injectIntl(PersonalDataCard));
