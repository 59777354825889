import React, { useState, useEffect } from "react";
import Grid from '@material-ui/core/Grid';
import { injectIntl } from 'react-intl';
import Button from "components/CustomButtons/Button.js";
import { useMutation } from '@apollo/client';
import { withRouter } from "react-router-dom";
import paths from "paths";
import { getValidationSchemaInviteStatutory } from "../validationSchema";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { generatePath } from 'react-router-dom';
import {variables } from "common/styles/configLayout.js";
import moment from "moment";
import IconCard from 'components-lib/IconCard/IconCard';
import CLUB_REGISTER_INVITE from "queries/MembersQueries/clubRegisterInvite";
import PageHeader from 'components-lib/PageHeader/PageHeader';
import CheckIcon from '@material-ui/icons/Check';
import InvitationFormData from "./InvitationFormData";

const StatutoryInvitePage = (props) => {
  const { history, intl } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [clubRegisterInvite] = useMutation(CLUB_REGISTER_INVITE);

  const [validateParam, setValidateParam] = useState(false)
  const [disableButton, setDisableButton] = useState(false);

  const handleInvite = (values) => {
    const result = { ...values };
    delete result.__typename;
    delete result.birth;
    delete result.isYouthWorker;
    let tempDate = moment(values?.birth).format('YYYY-MM-DD');
    setDisableButton(true)
    clubRegisterInvite({
      variables: {
        input: {
          person: {
            ...result,
            birth: (values?.birth) ? tempDate : undefined
          },
          isYouthWorker: (values?.isYouthWorker) ? true : false
        },
      },
    }).then((response) => {
      enqueueSnackbar("Pozvánka bola odoslaná", { variant: 'success' })
      history.push(`/admin${generatePath(paths.members.list)}`);
      setDisableButton(false);
    }).catch((err) => {
      console.log('[error]', err);
      setDisableButton(false);
    });
  };

  const handleBackAction = () => {
    history.goBack();
  };

  return (<Grid container spacing={2}>
    <Grid item xs={12} lg={8}>
      <PageHeader
        withBackButton={true}
        handleBackAction={handleBackAction}
        title={"Pozvánka osoby na založenie klubu"}
      />
      <Formik
        enableReinitialize
        validationSchema={getValidationSchemaInviteStatutory(intl)}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={(values) => { handleInvite(values); }}
        initialValues={{ country: variables.defaultCountry, nationality: variables.defaultCountry, countryOfBirth: variables.defaultCountry }}
      >
        {(formikProps) => (
          <>
            <IconCard >
              <Grid container spacing={1}>
                <InvitationFormData
                  formikProps={formikProps}
                  validateParam={validateParam}
                />
              </Grid>
            </IconCard>
            <Grid container spacing={1}>
              <Grid item xs={12} align="center">
                <br />
                <Button
                  color="primary"
                  size="sm"
                  disabled={disableButton}
                  round
                  onClick={() => { setValidateParam(true); formikProps.handleSubmit() }}
                >
                  <CheckIcon /> Odoslať pozvánku
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Formik>
    </Grid >
  </Grid >
  );
};

export default injectIntl(withRouter(StatutoryInvitePage));
