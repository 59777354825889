/*eslint-disable*/
import React, { useState, useEffect } from "react";
import {
  useTable,
  useFilters,
  useAsyncDebounce,
  useSortBy,
  usePagination
} from "react-table";
import classnames from "classnames";
// A great library for fuzzy filtering/sorting items
import matchSorter from "match-sorter";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton";
import { Grid } from "@material-ui/core";
import { generatePath } from 'react-router-dom';

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import TablePagination from "@material-ui/core/TablePagination";
import { map, find, pathOr } from "rambda";
import { injectIntl } from 'react-intl';

//For Modal
import Modal from "components-lib/Modal/Modal";
import Button from "components/CustomButtons/Button.js";
import TextField from "@material-ui/core/TextField";
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import EventIcon from '@material-ui/icons/Event';
import { colors, parameters } from "common/styles/configLayout.js";
import Typography from "@material-ui/core/Typography";
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { ThemeProvider } from '@material-ui/styles';
import DateFnsUtils from '@date-io/date-fns';
import materialTheme from 'components-lib/FormikField/muiDatePicker'
import { KeyboardDatePicker, DateTimePicker, DatePicker } from "@material-ui/pickers";
import moment from 'moment';
import useStylesPicker from './styles'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import useStyles from './styles'
import LockIcon from '@material-ui/icons/Lock';
import UnfoldLessIcon from '@material-ui/icons/UnfoldLess';
import { ArrowDropUp, ArrowDropDown } from "@material-ui/icons";
import SnackbarContent from '@material-ui/core/SnackbarContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import InboxIcon from '@material-ui/icons/Inbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import Box from '@material-ui/core/Box';
import paths from 'paths';
import TitleDatum from 'components/TitleDatum/TitleDatum';
import { DateRangePicker } from "materialui-daterange-picker";


// css
import '../../assets/css/inputSelect.css';
// import '../../assets/css/tableActivity.css';

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: colors.primary,
    paddingTop: 0,
    marginTop: "-10px",
    paddingBottom: 0,
    //borderBottomColor: 'rgba(60,72,88, 0.4)',
    borderBottom: 'none',
    //paddingRight: "2px",

    //marginBottom: "-50px",
    //marginTop: "0px"
  },
  body: {
    backgroundColor: 'transparent',
    color: colors.textBody,
    //borderBottom: "none",
    //borderBottom: "none",
    borderBottomColor: 'rgba(60,72,88, 0.4)',
    fontSize: 12,
    paddingTop: "4px",
    paddingBottom: "4px",
    paddingRight: "1px",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {

    '&:hover': {
      backgroundColor: colors.tableWidgetsHover
    },
    //paddingTop: "-20px",
    //paddingBottom: "-20px",
  },
}))(TableRow);


//const useStyles = makeStyles(newStyles);



// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter }
}) {
  const count = preFilteredRows.length;

  return (
    <CustomInput
      formControlProps={{
        fullWidth: true
      }}
      inputProps={{
        value: filterValue || "",
        onChange: e => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        },
        placeholder: `Search ${count} records...`
      }}
    />
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val;

// Our table component
function Tables({ columns, data, onPageChange, onRowsCountChange, currentPage, rowsPerPage, history, totalCount, filters, handleFilterChange, intl, sorts, handleSortChange, filterView, paginateView, columnsWidth }) {
  const [numberOfRows, setNumberOfRows] = React.useState(rowsPerPage);
  const [pageSelect, handlePageSelect] = React.useState(0);
  const [pageNumber, setPageNumber] = React.useState(0);
  const classes = useStyles();
  const classesPicker = useStylesPicker();
  //MILAN START
  const [startValue, setStartValue] = React.useState(null);
  const [endValue, setEndValue] = React.useState(null);
  const [columnFilter, setColumnFilter] = React.useState(" ");
  const [columnOperator, setColumnOperator] = React.useState(" ");
  //For modal
  const [assignModalOpen, setAssignModalOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [dateRange, setDateRange] = React.useState({});
  const toggle = () => setOpen(!open);

  const [currentFilterKey, setCurrentFilterKey] = React.useState('');
  const [tableWidth, setTableWidth] = useState(window.innerWidth >= 960 ? '72.5vw' : '86.2vw');

  const handleModalOpen = (filter, operator) => {
    setColumnFilter(filter);
    setColumnOperator(operator);
    setAssignModalOpen(true)
  };

  //MILAN END

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      }
    }),
    []
  );

  const getContent = (content, id, key) => {

    // console.log(id.row.original.id)

    if (key === 0) {
      return <>
        <span style={{ color: colors.disabled, backgroundColor: colors.disabledBackground, padding: '5px', borderRadius: '4px' }}>{content}</span>
        {' '}
      </>
    } else if (key === 2) {
      return <>
        <span style={{ color: colors.textBody }}>{content} </span>
        {' '}
      </>
    }


    else
      return <span style={{ color: colors.textBody }}>{content}{' '}</span>
  }


  const handleGetLink = (entities, event) => {
    console.log('entities', entities)
    console.log('event', event)
    let entity = entities?.[entities?.length - 1]
    switch (event) {
      //case "ClubRegistrationInvitationSend": return null
      case "FunctionAssignment": return history.push(`/admin${generatePath(paths.members.detail, { memberId: (entities).find(item => item.type === "Member")?.refId })}?tab=1`);
      //case "ClubJoinInvitationSend": return null;
      case "MemberLicenseActivated": return history.push(`/admin${generatePath(paths.members.detail, { memberId: (entities).find(item => item.type === "Member")?.refId })}?tab=3`);
      //case "ClubLicenseActivated": return null;
      //case "UserBlocked": return null;
      //case "UserUnblocked": return null;
      //case "ClubLicenseRequestCreated": return null;
      case "MemberLicenseRequestCreated": return history.push(`/admin${generatePath(paths.licenses.detail, { licenseId: (entities).find(item => item.type === "MemberLicenseRequest")?.refId })}`);
      //case "ClubLicensePaymentConfirmed": return history.push(`/admin${generatePath(paths.clubs.detail, { clubId: (entities).find(item => item.type === "Club")?.refId })}`);
      case "MemberLicensePaymentConfirmed": return history.push(`/admin${generatePath(paths.licenses.detail, { licenseId: (entities).find(item => item.type === "MemberLicenseRequest")?.refId })}`);
      case "ClubRegisterRequestCreated": 
        return history.push({
          pathname: `/admin${generatePath(paths.requests.detail, { requestId: (entities).find(item => item.type === "Request")?.refId })}`,
          state: "ActivityTable"});
      case "ClubRegisterRequestReturned": 
        return history.push({
          pathname: `/admin${generatePath(paths.requests.detail, { requestId: (entities).find(item => item.type === "Request")?.refId })}`,
          state: "ActivityTable"});
      case "ClubRegisterRequestApproved": 
        return history.push({
          pathname: `/admin${generatePath(paths.requests.detail, { requestId: (entities).find(item => item.type === "Request")?.refId })}`,
          state: "ActivityTable"});
      case "ClubUpdateRequestCreated": 
        return history.push({
          pathname: `/admin${generatePath(paths.requests.detail, { requestId: (entities).find(item => item.type === "Request")?.refId })}`,
          state: "ActivityTable"});
      case "ClubUpdateRequestReturned": 
        return history.push({
          pathname: `/admin${generatePath(paths.requests.detail, { requestId: (entities).find(item => item.type === "Request")?.refId })}`,
          state: "ActivityTable"});
      case "ClubUpdateRequestApproved": 
        return history.push({
          pathname: `/admin${generatePath(paths.requests.detail, { requestId: (entities).find(item => item.type === "Request")?.refId })}`, 
          state: "ActivityTable"});
      case "ClubTransferRequestCreated": 
        return history.push({
          pathname: `/admin${generatePath(paths.transfers.detail, { requestId: (entities).find(item => item.type === "Request")?.refId })}`,
          state: "ActivityTable"});
      case "ClubHostingRequestCreated": 
        return history.push({
          pathname: `/admin${generatePath(paths.hosting.detail, { requestId: (entities).find(item => item.type === "Request")?.refId })}`,
          state: "ActivityTable"});
      case "ClubTransferRequestResponseSubmitted": 
        return history.push(
          {pathname: `/admin${generatePath(paths.transfers.detail, { requestId: (entities).find(item => item.type === "Request")?.refId })}`,
          state: "ActivityTable"});
      case "ClubTransferRequestApproved": 
        return history.push(
          {pathname: `/admin${generatePath(paths.transfers.detail, { requestId: (entities).find(item => item.type === "Request")?.refId })}`,
          state: "ActivityTable"});
      case "ClubHostingRequestResponseSubmitted": 
        return history.push({
          pathname: `/admin${generatePath(paths.hosting.detail, { requestId: (entities).find(item => item.type === "Request")?.refId })}`,
          state: "ActivityTable"});
      case "ClubHostingRequestApproved": 
        return history.push({
          pathname: `/admin${generatePath(paths.hosting.detail, { requestId: (entities).find(item => item.type === "Request")?.refId })}`,
          state: "ActivityTable"});
      case "MembershipSuspended": return history.push(`/admin${generatePath(paths.members.detail, { memberId: (entities).find(item => item.type === "Member")?.refId })}`);
      case "MembershipResumed": return history.push(`/admin${generatePath(paths.members.detail, { memberId: (entities).find(item => item.type === "Member")?.refId })}`);
      // case "PersonDocumentUploaded": return history.push(`/admin${generatePath(paths.members.detail, { memberId: (entities).find(item => item.type === "Member")?.refId })}?tab=2`);
      // case "PersonDocumentApproved": return history.push(`/admin${generatePath(paths.clubs.detail, { clubId: (entities).find(item => item.type === "Club")?.refId })}`);
      // case "PersonDocumentRejected": return history.push(`/admin${generatePath(paths.members.detail, { memberId: (entities).find(item => item.type === "Member")?.refId })}?tab=2`);
      // case "PersonDocumentRemoved": return history.push(`/admin${generatePath(paths.members.detail, { memberId: (entities).find(item => item.type === "Member")?.refId })}?tab=2`);
      case "ClubDocumentUploaded": return history.push(`/admin${generatePath(paths.clubs.detail, { clubId: (entities).find(item => item.type === "Club")?.refId })}?tab=2`);
      case "ClubDocumentApproved": return history.push(`/admin${generatePath(paths.clubs.detail, { clubId: (entities).find(item => item.type === "Club")?.refId })}?tab=2`);
      case "ClubDocumentRejected": return history.push(`/admin${generatePath(paths.clubs.detail, { clubId: (entities).find(item => item.type === "Club")?.refId })}?tab=2`);
      case "ClubDocumentRemoved": return history.push(`/admin${generatePath(paths.clubs.detail, { clubId: (entities).find(item => item.type === "Club")?.refId })}?tab=2`);
      // case "PersonProfileChangeApproved": return history.push(`/admin${generatePath(paths.profile)}`);
      case "PersonProfileChangeApproved": 
        return history.push({
          pathname: `/admin${generatePath(paths.clubs.detail, { clubId: (entities).find(item => item.type === "Club")?.refId })}`,
          state: {
            type: 'PersonProfileChangeApproved',
            refId: entities[0].refId
          }
        });
      case "PersonProfileChangeRejected": return history.push(`/admin${generatePath(paths.profile)}`);

      default: return null



      /*switch (entity?.type) {
        //case "ActionInvitation": return null
        case "Club": return history.push(`/admin${generatePath(paths.clubs.detail, { clubId: entity?.refId })}`);
        case "ClubFunction": return history.push(`/admin${generatePath(paths.clubs.detail, { clubId: entity?.refId })}`);
        case "ClubLicense": return history.push(`/admin${generatePath(paths.clubs.detail, { clubId: entity?.refId })}`);
        case "ClubMemberLicense": return history.push(`/admin${generatePath(paths.members.detail, { memberId: entity?.refId })}?tab=3`);
        //case "ClubLicenseRequest": return history.push(`/admin${generatePath(paths.clubs.detail, { clubId: entity?.refId })}`);
        case "MemberLicenseRequestLicense": return history.push(`/admin${generatePath(paths.licenses.detail, { licenseId: entity?.refId })}`);
        case "MemberLicenseRequest": return history.push(`/admin${generatePath(paths.licenses.detail, { licenseId: entity?.refId })}`);
        //case "Person":  return history.push(`/admin${generatePath(paths.clubs.detail, { clubId: entity?.refId })}`);
        case "Request": return history.push(`/admin${generatePath(paths.requests.detail, { requestId: entity?.refId })}`);
        case "RequestResponse": return history.push(`/admin${generatePath(paths.requests.detail, { requestId: entity?.refId })}`);
        case "Payment": return history.push(`/admin${generatePath(paths.payments.detail, { paymentId: entity?.refId })}`);
        case "Member": return history.push(`/admin${generatePath(paths.members.detail, { memberId: entity?.refId })}?tab=1`);
        //case "Document":  return history.push(`/admin${generatePath(paths.clubs.detail, { clubId: entity?.refId })}`);
        default: return null*/
    }
  }



  const handlePageChange = (count) => {
    setPageNumber(pageNumber + count);
  };

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    visibleColumns,
    nextPage,
    pageOptions,
    pageCount,
    previousPage,
    canPreviousPage,
    canNextPage,
    setPageSize,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: { pageSize: numberOfRows, pageIndex: 0 }
    },
    useFilters, // useFilters!
    useSortBy,
    usePagination
  );
  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  // const firstPageRows = rows.slice(0, 10);
  let pageSelectData = Array.apply(null, Array(pageOptions.length)).map(
    function () { }
  );

  let numberOfRowsData = [25, 50, 100];


  const handleParseStartValue = (value, column) => {
    setStartValue(value)
    let start = moment(value).format("DD.MM.YYYY")
    handleFilterChange(columnFilter, start, moment(endValue).format("DD.MM.YYYY"), column.filterDataType, columnOperator)
  }

  const handleParseEndValue = (value, column) => {
    setEndValue(value)
    let end = moment(value).format("DD.MM.YYYY")
    handleFilterChange(columnFilter, moment(startValue).format("DD.MM.YYYY"), end, column.filterDataType, columnOperator)
  }

  const handleOpenDatePicker = (filterKey) => {
    setCurrentFilterKey(filterKey);
    setOpen(true)
  }

  const renderModalButtons = (columnFilter, columnFilterDataType, columnOperator) => {
    return (
      <>
        <Button
          disabled={false}
          onClick={() => {
            handleFilterChange(columnFilter, moment(dateRange.startDate).format("YYYY-MM-DD"), moment(dateRange.endDate).format("YYYY-MM-DD"), columnFilterDataType, columnOperator)
            setOpen(false)
            onPageChange(0);
            handlePageChange(0);
          }}
          color="primary"
          round
          table
          size="sm">
          Zobraziť dáta
        </Button>
      </>
    );
  };
  
  const handleResize = () => {
    // Aktualizujeme šírku tabuľky na základe veľkosti okna
    if (window.innerWidth >= 960) {
      setTableWidth('72.5vw');
    } else {
      setTableWidth('86.2vw');
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  },[]);

  return (<>
    <TableContainer style={{ overflowX: 'auto' }} >
      <Table aria-label="simple table" style={{ width: "100% !important" }}>
        <colgroup>
          {columnsWidth?.map(item =>
            <col style={{ width: item }} />
          )}
        </colgroup>
        <TableHead 
          className="table-head"
          style={{ 
            borderRadius: parameters.tablesRounded,
            // width: window.innerWidth >= 960 ? '76vw' : '86.2vw',
            width: tableWidth,
            minWidth: '30px',
            maxWidth: '100%',
            whiteSpace: 'nowrap',
            display: 'flex',
          }}>
            <Grid item
                   style={{
                    //  display: 'flex', 
                    //  width: "auto", maxWidth: "100%", 
                      position: "relative",
                      display: "inline-block",
                      flexWrap: 'nowrap',
                      width: 'auto',
                      overflowX: 'scroll',
                      whiteSpace: 'nowrap',
                      scrollbarWidth: 'none',                       
                   }}
                 >
          {headerGroups.map((headerGroup, key) => (
            <TableRow style={{ borderRadius: parameters.tablesRounded }} key={key}>
              {headerGroup.headers.map((column, key) => {
                column.filterComponent = pathOr('input', ['filterComponent'], column);
                column.canFilter = !!pathOr(false, ['filterKey'], column);
                column.canSort = !!pathOr(false, ['sortKey'], column);
                const activeSortDirection = pathOr('', ['order'], find((sort) => sort.field === column.sortKey, sorts))
                column.activeSortDirection = activeSortDirection;
                column.canSort = !!pathOr(false, ['sortKey'], column);
                const cursorPointer = column.canSort ? '-cursor-pointer' : '';
                return (
                  <StyledTableCell
                    style={{
                      width: 'auto',
                      minWidth: '30px',
                      maxWidth: '100%',
                      whiteSpace: 'nowrap',
                      borderTopLeftRadius: (key === 0) && parameters.tablesRounded,
                      borderBottomLeftRadius: (key === 0) && parameters.tablesRounded,
                      borderTopRightRadius: (key === headerGroup.headers.length - 1) && parameters.tablesRounded,
                      borderBottomRightRadius: (key === headerGroup.headers.length - 1) && parameters.tablesRounded
                    }}
                    key={key}
                    className={classnames(`rt-th rt-resizable-header ${cursorPointer}`, {
                      "-cursor-pointer": column.canSort && !column.activeSortDirection,
                      "-sort-asc": activeSortDirection === 'ASC',
                      "-sort-desc": column.canSort && activeSortDirection === 'DESC'
                    })}
                  >
                      <div
                        style={{
                          margin: '8px -5px 12px -10px',
                        }}
                      >
                        {filterView &&
                          (column.filterKey === "actions" || column.filterKey === "none") ?
                          <>
                            {(column.filterKey === "actions") ?
                              <></>
                              :
                              <div className={classes.inputContainer}
                                style={{
                                  minWidth: column.minWidth, 
                                  maxWidth: column.maxWidth
                                }}
                              >
                                <Typography style={{ color: colors.textBody, marginLeft: "10px" }}>{<span style={{ fontSize: "0.8em" }}>{column.render("Header")}</span>}</Typography>

                              </div>
                            }
                          </>
                          : <>
                            {column.canFilter && column.filterComponent === 'input' && (
                              <div className={classes.inputContainer}
                                style={{
                                  minWidth: column.minWidth, 
                                  maxWidth: column.maxWidth
                                }}
                              >
                                <span className={classes.input}>
                                  <TextField
                                    //style={{ borderRadius: parameters.tablesRounded }}
                                    className={classes.root}
                                    size="small"
                                    label={<span style={{ fontSize: "0.8em" }}>{column.render("Header")}</span>}
                                    variant="outlined"
                                    defaultValue="Success"
                                    id="validation-outlined-input"

                                    inputProps={{
                                      value: pathOr(
                                        '',
                                        [column.filterKey, column.filterOperator],
                                        filters
                                      ),
                                      style: {
                                        fontSize: "0.8rem",
                                        // transform: "translate(10px, 12px) scale(1)",
                                       },
                                      //type: type,
                                      onChange: e => {
                                        handleFilterChange(column.filterKey, e.target.value, null, column.filterDataType, column.filterOperator);
                                        onPageChange(0);
                                        handlePageChange(0);
                                        // setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
                                      },
                                      //disabled: disabled,
                                      placeholder: "Vyhľadať",
                                      //...inputProps
                                    }}
                                    name={name}
                                  />
                                </span>
                                <div
                                  className={classes.iconHover}
                                  onClick={(e) => {
                                    handleSortChange(column.sortKey, column.canSort);
                                    onPageChange(0);
                                    handlePageChange(0);
                                  }}
                                >
                                  {/*<i style={{ fontSize: 20 }} className="fas fa-sort"></i>*/}
                                  <div className={classes.icon}>
                                    <div>
                                      <i style={{ fontSize: 20, color: (activeSortDirection === 'ASC') && colors.primary }} className="fas fa-sort-up"></i>
                                    </div>
                                    <div style={{ marginTop: '-20px' }}>
                                      <i style={{ fontSize: 20, color: (activeSortDirection === 'DESC') && colors.primary }} className="fas fa-sort-down"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}

                          {column.canFilter && column.filterComponent === 'dateRange' && (
                            <div className={classes.inputContainer}
                              style={{
                                minWidth: column.minWidth, 
                                maxWidth: column.maxWidth
                              }}
                            >
                              <TitleDatum
                                inputLabel={column.Header}
                                filterKey={column.filterKey}
                                filterOperator={column.filterOperator}
                                filterDataType={column.filterDataType}
                                filters={filters}
                                onOpenDatePicker={handleOpenDatePicker}
                                handleFilterChange={handleFilterChange}
                              />
                              <div
                                className={classes.iconHover}
                                onClick={(e) => {
                                  handleSortChange(column.sortKey, column.canSort);
                                  onPageChange(0);
                                  handlePageChange(0);
                                }}
                                style={{
                                  display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                }}
                              >
                                <div className={classes.icon}>                                  
                                  <div>
                                    <i style={{ fontSize: 20, color: (activeSortDirection === 'ASC') && colors.primary }} className="fas fa-sort-up"></i>
                                  </div>
                                  <div style={{ marginTop: '-20px' }}>
                                    <i style={{ fontSize: 20, color: (activeSortDirection === 'DESC') && colors.primary }} className="fas fa-sort-down"></i>
                                  </div>
                                </div>
                              </div>

                              <Modal
                                title={`Zvoľte obdobie za ktoré chcete zobraziť dáta.`}
                                open={open}
                                onClose={() => setOpen(false)}
                                actions={renderModalButtons(currentFilterKey, column.filterDataType, column.filterOperator)}
                                fullWidth
                                maxWidth='md'
                              //width={'lg'}
                              >
                                <div className={classes.root}>
                                  <DateRangePicker
                                    open={true}
                                    toggle={toggle}
                                    maxDate={new Date()}
                                    onChange={(range) => setDateRange(range)}
                                  />

                                </div>
                              </Modal>

                            </div>
                          )}

                            {column.canFilter && column.filterComponent === 'title' && (
                              <div className={classes.inputContainer}
                              style={{minWidth: column.minWidth, maxWidth: column.maxWidth, justifyContent: "center"}}
                              >
                                <div
                                    className={column.render("Header")}
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      right: "0",
                                      left: "unset",
                                      fontSize: "1.25rem",
                                      paddingRight: "0.5rem",
                                    }}
                                  >
                                    <div
                                      style={{
                                        // padding: "1.5rem 0.5rem 1.5rem 0.5rem",
                                        borderBottom: "0",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        textAlign: "left",
                                        fontSize: "0.8rem",
                                        // opacity: "0.7",
                                        // background: "transparent",
                                        color: "#E1ECFA",
                                        // fontSize: "0.75rem",
                                        // fontWeight: "700",
                                        width: "100%",
                                        height: "100%",
                                      }}
                                    >
                                      <div className='titleTableText' >{column.render("Header")}</div>
                                      {/* {clearIcon === true && (
                                          <span
                                            className='deleteIconHeaderContainer'
                                            style={{
                                              visibility: clearIcon === true && filterState[tableName] && Object.keys(filterState[tableName]).length > 0 ? 'visible' : 'hidden',
                                              ...(inputLabel === '' ? { marginLeft: '-0.7rem' } : {}),
                                            }}
                                            onClick={handleClear}
                                          >
                                            {cross}
                                          </span>
                                      )} */}
                                    </div>
                                  </div>
                                <div
                                  className={classes.iconHover}
                                  onClick={(e) => {
                                    handleSortChange(column.sortKey, column.canSort);
                                    onPageChange(0);
                                    handlePageChange(0);
                                    console.log(column.sortKey, column.canSort);
                                  }}
                                >
                                  <div className={classes.icon}>
                                    <div>
                                      <i style={{ fontSize: 20, color: (activeSortDirection === 'ASC') && colors.primary }} className="fas fa-sort-up"></i>
                                    </div>
                                    <div style={{ marginTop: '-20px' }}>
                                      <i style={{ fontSize: 20, color: (activeSortDirection === 'DESC') && colors.primary }} className="fas fa-sort-down"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}

                            {column.canFilter && column.filterComponent === 'select' && (<>
                              <div className={classes.inputContainer} style={{ width: "100%", minWidth: column.minWidth, maxWidth: column.maxWidth }} >
                                <span className={classes.input} style={{ width: "100%" }}>
                                  <FormControl size="small" className={classes.root} style={{ width: "100%" }} fullWidth variant="outlined" >
                                    <InputLabel htmlFor="outlined-age-native-simple" style={{ color: colors.textBody }}>{<span style={{ fontSize: "0.8em" }}>{column.render("Header")}</span>}</InputLabel>
                                    <Select
                                      MenuProps={{ classes: { paper: classes.select } }}
                                      //style={{ borderRadius: '4px', width: "100%" }}
                                      style={{ fontSize: "0.8rem" }}
                                      label={<span style={{ fontSize: "0.8em" }}>{column.render("Header")}</span>}
                                      value={pathOr('', [column.filterKey, column.filterOperator], filters)}
                                      onChange={(e) => {
                                        handleFilterChange(column.filterKey, e.target.value, null, column.filterDataType, column.filterOperator),
                                        onPageChange(0),
                                        handlePageChange(0)
                                        }
                                      }
                                      inputProps={{
                                        classes: { icon: classes.selectIcon }
                                      }}  
                                    >
                                      <MenuItem
                                        value={0}
                                        className="inputSelect"
                                        classes={{
                                          root: classes.selectMenuItem,
                                          selected: classes.selectMenuItemSelected
                                        }}
                                      >
                                        {intl.formatMessage({ id: 'queryTable.select.all' })}
                                      </MenuItem>
                                      {map((option, index) =>
                                        <MenuItem
                                          key={`filter.${column.filterKey}.${index}`}
                                          value={option.value}
                                          className="inputSelect"
                                          classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                          }}
                                        >
                                          {option.label}
                                        </MenuItem>
                                        , column.filterOptions || [])}
                                    </Select>
                                  </FormControl>
                                </span>
                                <div
                                  className={classes.iconHover}
                                  onClick={(e) => {
                                    handleSortChange(column.sortKey, column.canSort);
                                    onPageChange(0);
                                    handlePageChange(0);
                                  }}
                                >
                                  {/*<i style={{ fontSize: 20 }} className="fas fa-sort"></i>*/}
                                  <div className={classes.icon}>
                                    <div>
                                      <i style={{ fontSize: 20, color: (activeSortDirection === 'ASC') && colors.primary }} className="fas fa-sort-up"></i>
                                    </div>
                                    <div style={{ marginTop: '-20px' }}>
                                      <i style={{ fontSize: 20, color: (activeSortDirection === 'DESC') && colors.primary }} className="fas fa-sort-down"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                            )}

                            {/* MILAN START */}
                            {column.canFilter && column.filterComponent === 'date' && (
                              <>
                                <div className={classes.inputContainer}>
                                  <span className={classes.input}>
                                    <TextField
                                      //style={{ borderRadius: parameters.tablesRounded }}
                                      disabled={true}
                                      className={classes.root}
                                      size="small"
                                      label={<span style={{ fontSize: "0.8em" }}>{column.render("Header")}</span>}
                                      variant="outlined"
                                      defaultValue="Success"
                                      id="validation-outlined-input"
                                      onClick={() => (pathOr('', [column.filterKey, column.filterOperator], filters))
                                        ? handleFilterChange(column.filterKey, null, null, column.filterDataType, column.filterOperator)
                                        : setOpen(true)
                                      }
                                      inputProps={{
                                        value: (pathOr('', [column.filterKey, column.filterOperator], filters))
                                          ? 'Zrušiť filter'
                                          : '',
                                        //type: type,
                                        onChange: e => {
                                          handleFilterChange(column.filterKey, e.target.value, null, column.filterDataType, column.filterOperator);
                                          onPageChange(0);
                                          handlePageChange(0);
                                          // setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
                                        },
                                        //disabled: true,
                                        placeholder: "Vyhľadať",
                                        //...inputProps
                                      }}
                                      name={name}
                                    />
                                  </span>
                                  <div
                                    className={classes.iconHover}
                                    onClick={(e) => {
                                      handleSortChange(column.sortKey, column.canSort);
                                      onPageChange(0);
                                      handlePageChange(0);
                                    }}
                                  >
                                    {/*<i style={{ fontSize: 20 }} className="fas fa-sort"></i>*/}
                                    <div className={classes.icon}>
                                      <div>
                                        <i style={{ fontSize: 20, color: (activeSortDirection === 'ASC') && colors.primary }} className="fas fa-sort-up"></i>
                                      </div>
                                      <div style={{ marginTop: '-25px' }}>
                                        <i style={{ fontSize: 20, color: (activeSortDirection === 'DESC') && colors.primary }} className="fas fa-sort-down"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <Modal
                                  title={"Zvoľte obdobie za ktoré chcete zobraziť dáta."}
                                  open={open}
                                  onClose={() => setOpen(false)}
                                  actions={renderModalButtons(column.filterKey, column.filterDataType, column.filterOperator)}
                                  fullWidth
                                  maxWidth='md'
                                //width={'lg'}
                                >
                                  <div className={classes.root}>

                                    {/*<DateRangePicker
                                            startText="Check-in"
                                            endText="Check-out"
                                            value={value}
                                            onChange={(newValue) => setValue(newValue)}
                                            renderInput={(startProps, endProps) => (
                                              <React.Fragment>
                                                <TextField {...startProps} />
                                                <TextField {...endProps} />
                                              </React.Fragment>
                                            )}
                                            />*/}
                                    <DateRangePicker
                                      open={true}
                                      toggle={toggle}
                                      maxDate={new Date()}
                                      onChange={(range) => setDateRange(range)}
                                    />

                                  </div>
                                </Modal>
                                {/*<Grid container spacing={1}>
                                  <Grid item sm={5}>
                                    <FormControl className={classesPicker.root} disabled style={{ paddingTop: "16px", color: colors.textBody }}>
                                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <ThemeProvider theme={materialTheme}>
                                          <DatePicker
                                            className={classesPicker.datePicker}
                                            clearable
                                            value={startValue}
                                            onChange={(e) => { handleParseStartValue(e, column) }}
                                            label={<Typography variant="body2" style={{ fontSize: "0.8em", color: colors.textBody }}>Od</Typography>}
                                            format="dd.MM.yyyy"
                                            formControlProps={{
                                              fullWidth: true
                                            }}


                                          />
                                        </ThemeProvider>
                                      </MuiPickersUtilsProvider>
                                    </FormControl>
                                  </Grid>
                                  <Grid item sm={5}>
                                    <FormControl disabled className={classesPicker.root} style={{ paddingTop: "16px", color: colors.textBody }}>
                                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <ThemeProvider theme={materialTheme}>
                                          <DatePicker
                                            className={classesPicker.datePicker}
                                            clearable
                                            value={endValue}
                                            onChange={(e) => { handleParseEndValue(e, column) }}
                                            label={<Typography variant="body2" style={{ fontSize: "0.8em", color: colors.textBody }}>Do</Typography>}
                                            format="dd.MM.yyyy"
                                            formControlProps={{
                                              fullWidth: true
                                            }}
                                          />
                                        </ThemeProvider>
                                      </MuiPickersUtilsProvider>
                                    </FormControl>
                                  </Grid>
                                  <Grid item sm={2}>
                                    <FormControl style={{ paddingTop: "16px", color: colors.textBody }}>
                                      <IconButton
                                        size="small"
                                        color="secondary"
                                        onClick={(e) => {
                                          setStartValue(null),
                                            setEndValue(null),
                                            handleFilterChange(columnFilter, null, null, column.filterDataType, columnOperator)
                                        }}
                                      >
                                        <CloseIcon className={classes.circle} />
                                      </IconButton >
                                    </FormControl>
                                  </Grid>
                                      </Grid>*/}
                              </>
                            )
                            }
                          </>
                        }
                      </div>
                  </StyledTableCell>
                )
              })}
            </TableRow >
          ))}
          </Grid>
        </TableHead>
      </Table >


      {page.map((row, i) => {
        // console.log(row?.original?.entities)
        // console.log('event', row?.original?.event)
        prepareRow(row);
        return (
          <Grid item xs={12}>
            <SnackbarContent
              onClick={() => handleGetLink(row?.original?.entities, row?.original?.event)}
              className={
                (row?.original?.event === "ClubRegistrationInvitationSend" ||
                  // row?.original?.event === "ClubJoinInvitationSend" ||
                  row?.original?.event === "PersonDocumentApproved" ||
                  row?.original?.event === "PersonDocumentUploaded" ||
                  row?.original?.event === "PersonDocumentRejected" ||
                  row?.original?.event === "PersonDocumentRemoved" ||
                  row?.original?.event === "ClubLicenseActivated" ||
                  row?.original?.event === "UserBlocked" ||
                  row?.original?.event === "UserUnblocked" ||
                  row?.original?.event === "ClubLicenseRequestCreated" ||
                  row?.original?.event === "ClubLicensePaymentConfirmed"
                ) ? classes.activityBoxWithoutHover : classes.activityBox}
              message={
                row.cells.map((cell, key) => {
                  return (
                    <span key={key}>
                      {getContent(cell.render("Cell"), cell, key)}
                    </span>
                  );
                })
              }
            />
          </Grid >
        );
      })}


    </TableContainer>
    {paginateView &&
      <div className={classes.root}>
        <TablePagination
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            MenuProps: { classes: { paper: classes.selectDropdown } }
          }}
          classes={{
            menuItem: classes.menuItem,
            select: classes.select,
            selectIcon: classes.selectIcon,
            caption: classes.caption,
          }}
          style={{ color: colors.textBody, fontSize: "14px" }}
          //classes={{ selectIcon: classes.select, paper: classes.selectDropdown }}
          backIconButtonProps={{
            "aria-label": "Previous Page",
            style: { color: currentPage === 0 ? "#b5b8c4" : colors.textBody },
            autoid: "pagination-button-next-collector",
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page",
            style: { color: currentPage === Math.ceil(totalCount / rowsPerPage) - 1 ? "#b5b8c4" : colors.textBody },
            autoid: "pagination-button-previous-collector",
          }}
          autoid="invoice-table-pagination-collector"

          labelRowsPerPage={intl.formatMessage({ id: 'tableTexts.rowsPerPage' })}
          //labelRowsPerPage="Rows per page:"
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${intl.formatMessage({ id: 'tableTexts.of' })} ${count !== -1 ? count : `${intl.formatMessage({ id: 'tableTexts.moreThan' })} ${to}`}`}
          rowsPerPageOptions={numberOfRowsData}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          //className={classes.paginationBullet}
          page={currentPage}
          // ActionsComponent={CustomPaginationActions}
          onChangePage={(e, newPageNumber) => {
            onPageChange(newPageNumber);
            handlePageChange(newPageNumber);
          }}
          onChangeRowsPerPage={(e) => {
            onRowsCountChange(e.target.value);
            setNumberOfRows(e.target.value);
            setPageSize(e.target.value);
            onPageChange(0);
            handlePageChange(0);
          }}
        />
      </div>
    }

  </>
  );
}
// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter(row => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = val => typeof val !== "number";

export default injectIntl(Tables);
