import PageHeader from "components-lib/PageHeader/PageHeader";
import React from "react";
import { injectIntl } from "react-intl";
import Grid from '@material-ui/core/Grid';
import ActivityTable from './ActivityTable';
import ACTIVITIES_TABLE from 'queries/ActivitiesQueries/activitiesTable';

import { useQuery } from '@apollo/client';

const ActivityListPage = (props) => {
  const { intl, history } = props;

  // const { loading, error, data } = useQuery(ACTIVITIES_TABLE, {
  //   variables: {
  //     offset: 0,
  //     limit: 10,
  //     sort: null,
  //   },
  // });

  // console.log('Loading:', loading);
  // console.log('Error:', error);
  // console.log('Data:', data);

  // if (loading) return <p>Loading...</p>;
  // if (error) return <p>Error: {error.message}</p>;

  return (<>
    <PageHeader
      withBackButton={false}
      title={intl.formatMessage({ id: 'activities.title' })}
    />
    <Grid container spacing={1}>
      <Grid item xs={12} xl={10}>    
          <ActivityTable
            query={ACTIVITIES_TABLE}
            defaultFilter={null}
            history={history}
            queryDataPath={['activitiesTable', 'items']}
          />
      </Grid>
    </Grid>
  </>
  );
};
export default injectIntl(ActivityListPage);