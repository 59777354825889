//import { dangerColor } from "assets/jss/material-dashboard-pro-react.js";
import { colors, parameters } from "common/styles/configLayout.js";
const devicesListPageStyles = (theme) => {

  return {
    iconAlign: {
      verticalAlign: "text-bottom"
    },
    floatRight: {
      float: "right"
    },
    tenantButtons: {
      marginRight: theme.spacing(3)
    },
    marginBottom: {
      marginBottom: theme.spacing(6)
    },
    tenantCardButtons: {
      position: "absolute",
      width: "100%",
      bottom: "0",
    },
    tenantCardButtonsMargin: {
      float: "right",
      marginRight: theme.spacing(2)
    },
    selectStyle: {
      margin: "0 10px 20px 10px",
      width: "300px"
    },
    warning: {
      color: "#EE9600"
    },
    warningIcon: {
      marginRight: "15px",
      verticalAlign: "middle",
      lineHeight: "normal"
    },
    badge: {
      right: -3,
      top: 13,
      padding: '0 4px',
    },


    //Circles
    successCircle: {
      height: "1px",
      width: "10px",
      backgroundColor: "green",
      borderRadius: "50%",
      display: "inline-block",
      marginRight: "10px"
    },

    warningCircle: {
      height: "10px",
      width: "10px",
      backgroundColor: "orange",
      borderRadius: "50%",
      display: "inline-block",
      marginRight: "10px"
    },

    errorCircle: {
      height: "10px",
      width: "10px",
      backgroundColor: "red",
      borderRadius: "50%",
      display: "inline-block",
      marginRight: "10px"
    },
    oneCircle: {
      height: "10px",
      width: "10px",
      backgroundColor: colors.primary,
      borderRadius: "50%",
      display: "inline-block",
      marginRight: "5px"
    },
    twoCircle: {
      height: "10px",
      width: "10px",
      backgroundColor: colors.two,
      borderRadius: "50%",
      display: "inline-block",
      marginRight: "5px"
    },
    threeCircle: {
      height: "10px",
      width: "10px",
      backgroundColor: colors.three,
      borderRadius: "50%",
      display: "inline-block",
      marginRight: "5px"
    },
    fourCircle: {
      height: "10px",
      width: "10px",
      backgroundColor: colors.four,
      borderRadius: "50%",
      display: "inline-block",
      marginRight: "5px"
    },
    fiveCircle: {
      height: "10px",
      width: "10px",
      backgroundColor: colors.five,
      borderRadius: "50%",
      display: "inline-block",
      marginRight: "5px"
    },
    pointer: {
      cursor: 'pointer'
    },

    //Chart Colors
    pieChartOne: {
      fill: colors.one,
      stroke: colors.one,
    },
    pieChartTwo: {
      fill: colors.two,
      stroke: colors.two,
    },
    pieChartThree: {
      fill: colors.three,
      stroke: colors.three,
    },
    pieChartFour: {
      fill: colors.four,
      stroke: colors.four,
    },
    pieChartFive: {
      fill: colors.five,
      stroke: colors.five,
    },
    circle: {
      height: "22px",
      width: "22px",
      borderRadius: "50%",
      backgroundColor: colors.primary,
      color: "black",
      display: "inline-block",
      marginRight: "5px",
      //marginTop: '8px',
      marginBottom: '-8px',
      // marginBottom: "-5px",
      //marginTop: "4px",
      padding: "3px",

      //marginBottom: "0px",
      cursor: 'pointer',
      "&:hover,&:focus": {
        backgroundColor: colors.primaryHover,
      }
    },
    errorCircle: {
      height: "22px",
      width: "22px",
      borderRadius: "50%",
      backgroundColor: colors.error,
      color: "black",
      display: "inline-block",
      marginRight: "5px",
      marginBottom: '-8px',
      padding: "3px",

      //marginBottom: "0px",
      cursor: 'pointer',
      "&:hover,&:focus": {
        backgroundColor: colors.errorHover,
      }
    },
    attachIcons: {
      height: "20px",
      width: "20px",
      //borderRadius: "50%",
      //backgroundColor: colors.primary,
      color: colors.textReadInputs,
      display: "inline-block",
      marginRight: "5px",
      // marginBottom: "-5px",
      marginTop: "4px",
      marginBottom: "-6px",
      cursor: 'pointer',
    },
    circleSelect: {
      height: "20px",
      width: "20px",
      display: "inline-block",
      marginRight: "5px",
      marginBottom: "-5px",
    },

    dashboardIcon: {
      color: "red",
      borderRadius: "50%",
      padding: "5px",
      backgroundColor: "green",
    },

    tabsIcon: {
      height: "20px",
      width: "20px",
      //borderRadius: "50%",
      display: "inline-block",
      marginRight: "2px",
      marginBottom: "-4px",
      //marginTop: "12px"
    },



    problemBlock: {
      backgroundColor: colors.disabledBackground,
      padding: "1px 10px 10px 10px",
      borderRadius: "10px"
    },

    //Dropzone
    dropZone: {
      height: '60px',
      backgroundColor: colors.backgroundReadInputs,
      color: colors.textBody,
      minHeight: "60px"
    },
    previewContainer: {
      container: 'true',
      width: '100%',
      height: '100%',
    },
    previewChip: {
      xs: '12',
      width: '100%',
      color: colors.textBody,
      borderColor: colors.textBody,
    },
    preview: {
      //width: '100%',
      height: '10px',
      //item: 'true',
    },
    previewImg: {
      //height: '100%',
      //width: '100%',
    },

    dialog: {
      position: 'absolute',
      left: 0,
      top: 0
    },
    readOnlyGrid: {
      backgroundColor: colors.backgroundReadInputs,
      borderRadius: parameters.readInputsRounded,
      padding: "4px 14px",
      color: colors.textReadInputs,
      margin: "1px"
    },

    listCircle: {
      height: "20px",
      width: "20px",
      borderRadius: "50%",
      display: "inline-block",
      marginRight: "5px",
      marginBottom: "-5px",
      marginTop: "12px",
      cursor: 'pointer',
    },

    specialCircle: {
      height: "17px",
      width: "17px",
      borderRadius: "50%",
      display: "inline-block",
      marginLeft: "3px",
      marginBottom: "-5px",
      marginTop: "0px",
      cursor: 'pointer',
    },
    specialCircle2: {
      height: "17px",
      width: "17px",
      borderRadius: "50%",
      display: "inline-block",
      marginBottom: "-5px",
      paddingBottom: "-5px",
      marginTop: "0px",
      cursor: 'pointer',
    },
    selectIcon: {
      color: colors.labelAndBorderInputs,
      "&:hover": {
        color: colors.textBody,
      }
    },

  }

}
export default devicesListPageStyles
