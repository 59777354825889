import React from 'react';
import Datetime from "react-datetime";
import CustomInput from 'components/CustomInput/CustomInput';
import { pathOr } from 'rambda';


import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { injectIntl } from 'react-intl';

import moment from 'moment';
import { makeStyles } from "@material-ui/core/styles";
import styles from "common/styles/widgets.js";
import selectStyles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import CustomCheckbox from "../CustomCheckbox/CustomCheckbox";
import Switch from "@material-ui/core/Switch";
import { withStyles } from '@material-ui/core/styles';
import { Grid } from "@material-ui/core";
import { ThemeProvider } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Box } from '@material-ui/core';

import { colors, widgets, inputs } from "common/styles/configLayout.js";
import { DateTimePicker, DatePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import materialTheme from './muiDatePicker'
import useStyles from './styles'
import LockIcon from '@material-ui/icons/Lock';
import Autocomplete from '@material-ui/lab/Autocomplete';

const MainSwitch = withStyles({
    switchBase: {
        color: colors.primary,
        '&$checked': {
            color: colors.primary,
        },
        '&$checked + $track': {
            backgroundColor: colors.primary,
        },
    },
    checked: {},
    track: {},
})(Switch);

const useSelectStyles = makeStyles(selectStyles);
const useStyles2 = makeStyles((theme) => styles(theme))

const FormikField = ({
    intl,
    value,
    formControlProps = null,
    inputProps = null,
    formikProps = null,
    smallDescription = false,
    name = undefined,
    type = 'text',
    options = [],
    labelText = undefined,
    highlightChange = true,
    helperText = undefined,
    disabled = false,
    placeholder = false,
    checkedLabelText = true,
    uncheckedLabelText = false,
    body = null,
    minValueSlider,
    dateFormat = "yyyy/MM/dd",
    maxValueSlider,
    defaultValueSlider,
    validateParam = false,
    marks = null,
    valueLabel,
    readMode = false,
    icon = false,
    link = false,
    success = false,
    mandatory = false,
    inputHeight,
    priceValue,
    editData,
    priceList,
    ...rest
}) => {
    
    const { values, setFieldValue, errors } = formikProps;
    const classes = useStyles();
    const classes2 = useStyles2();
    const selectclasses = useSelectStyles();
    const formikFieldClasses = makeStyles(styles)();

    const originalValue = pathOr('', ['initialValues', ...name.split('.')], formikProps);
    const currentValue = pathOr('', name.split('.'), values);
    const inputStyles = highlightChange && originalValue.toString() !== currentValue.toString() ? { backgroundColor: '#FFF8CC' } : {};
    const inputStyles2 = highlightChange && originalValue.toString() !== currentValue.toString() ? `rgba(${colors.sidebarButtons}, 0.08)` : ' ';
    const inputBorder2 = highlightChange && originalValue.toString() !== currentValue.toString() ? `rgba(${colors.sidebarButtons}, 0.2)` : ' ';
    const inputSelectedStyles = highlightChange && originalValue.toString() !== currentValue.toString() ? { marginTop: "22px", backgroundColor: '#FFF8CC' } : {};

    const helperTextPreferError = pathOr('', name.split('.'), errors) || helperText;

    if (pathOr(null, name.split('.'), values) === '') {
        formikProps.setFieldValue(name, null);
    }

    // console.log(pathOr('', name.split('.'), values))

    const [item, setItem] = React.useState([pathOr('', name, values)]);
    const [disabledField, setDisabledField] = React.useState(disabled)
    const shrink = currentValue.length > 0;
    let mandatoryChart = (mandatory) ? " *" : ""
    React.useEffect(() => {
        setDisabledField(disabled);
    });

    const handleSetItem = (event, n) => {
        let lastItem = n.key
        const index = event.indexOf('AllNotifications');
        if ((event.includes('AllNotifications')) && (lastItem !== 'AllNotifications')) {
            event.splice(index, 1);
        }

        if (lastItem === 'AllNotifications') {
            setItem([])
            setItem(['AllNotifications'])
        } else {
            setItem(event)
        }

        if (event.length === 0)
            setItem(['AllNotifications'])
    }

    const [isMyInputFocused, setIsMyInputFocused] = React.useState(false);
    const [aplicationValidation, setAplicationValidation] = React.useState(validateParam);

    const textField = (
        <>
            <TextField
                onFocus={() => { setIsMyInputFocused(true); setAplicationValidation(true) }}
                onBlur={() => setIsMyInputFocused(false)}
                multiline={(type === "textArea") ? true : false}
                minRows={2}
                style={{
                    backgroundColor: inputStyles2,
                    borderRadius: '6px',
                    // whiteSpace: 'nowrap',
                    //     overflow: 'hidden',
                    //     textOverflow: 'ellipsis',
                }}
                className={`${classes.root} inputTextfieldNoWrap`}
                disabled={disabled}
                size="small"
                fullWidth
                label={labelText + mandatoryChart}
                variant="outlined"
                defaultValue="Success"
                id="validation-outlined-input"
                /*InputLabelProps={{
                    className: (shrink) ? undefined : (isMyInputFocused) ? classes.inputLabelNoShrinkAndFocused : classes.inputLabelNoShrink,
                }}*/
                InputProps={{
                    endAdornment: (disabled) && 
                        <LockIcon position="end" 
                            style={{ color: "rgba(255, 255, 255, 0.3)" }}
                        >
                        </LockIcon>,
                }}
                //className={classes.disabledInput}
                inputProps={{
                    value: pathOr('', name.split('.'), values),
                    type: type,
                    onChange: (e) => setFieldValue(name, e.target.value),
                    disabled: disabled,
                    placeholder: placeholder,
                    style: {
                        height: inputHeight,
                    },

                    classes: {
                        root: classes.label,
                    },
                    ...inputProps
                }}
                name={name}
                formControlProps={{
                    fullWidth: true,
                    ...formControlProps
                }}

                //helperText={helperTextPreferError}
                error={(aplicationValidation || validateParam) && !!pathOr(false, name.split('.'), errors)} //MILAN edited
                {...rest}
            />
            <FormHelperText style={{ color: (pathOr(false, name.split('.'), errors)) ? '#F44336' : 'white' }}>{(aplicationValidation || validateParam) && helperTextPreferError}</FormHelperText>
        </>
    );

    const multiselect = (<>
        <FormControl className={classes.root} fullWidth variant="outlined" >
            <InputLabel htmlFor="outlined-age-native-simple" className={(!shrink) ? classes.inputLabelNoShrink : undefined} style={{ color: (pathOr(false, name.split('.'), errors)) ? (aplicationValidation || validateParam) && '#F44336' : colors.labelAndBorderInputs }}>{labelText + mandatoryChart}</InputLabel>
            <Select
                onFocus={() => { setIsMyInputFocused(true); setAplicationValidation(true) }}
                onBlur={() => setIsMyInputFocused(false)}
                MenuProps={{ classes: { paper: classes.select } }}
                className={classes.selectForm}
                style={{ backgroundColor: inputStyles2, borderRadius: '7px' }}
                size="small"
                labelId="demo-mutiple-chip-label"
                id="demo-mutiple-chip"
                multiple
                disabled={disabled}
                label={labelText + mandatoryChart}
                value={item}
                onChange={(e, name) => { handleSetItem(e.target.value, name) }}
                onClick={(e) => { setFieldValue(name, item) }}
                //input={<Input id="select-multiple-chip" />}
                renderValue={(selected) => (
                    <div className={classes.chips}>
                        {selected.map((value) => (
                            <Chip key={value} label={value} className={classes.chip} />
                        ))}
                    </div>
                )}
                InputProps={{
                    classes: { icon: classes.selectIcon }
                }}
                /*inputlabelprops={{
                    style: {
                        color: 'red'
                    },
                    shrink: (placeholder) && true,
                }}*/
                error={(aplicationValidation || validateParam) && !!pathOr(false, name.split('.'), errors)} //MILAN edited
                {...rest}
            >
                {options?.map((option) => (
                    <MenuItem
                        key={option.value}
                        value={option.value}
                        classes={{
                            root: selectclasses.selectMenuItem,
                            selected: selectclasses.selectMenuItemSelectedMultiple
                        }}
                    >
                        <Typography variant="body2">{option.label}</Typography>
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText style={{ color: (pathOr(false, name.split('.'), errors)) ? '#F44336' : 'white' }}>{(aplicationValidation || validateParam) && helperTextPreferError}</FormHelperText>
        </FormControl>
    </>);


    const select = (
        <FormControl size="small" className={classes.root} fullWidth variant="outlined" >
            <InputLabel htmlFor="outlined-age-native-simple" style={{ color: (pathOr(false, name.split('.'), errors)) ? (aplicationValidation || validateParam) && '#F44336' : colors.labelAndBorderInputs }}>{labelText + mandatoryChart}</InputLabel>
            <Select
                onFocus={() => { setIsMyInputFocused(true); setAplicationValidation(true) }}
                onBlur={() => setIsMyInputFocused(false)}
                MenuProps={{ classes: { paper: selectclasses.selectMenuItem } }}
                className={classes.selectForm + ' ' + classes.root}
                style={{ backgroundColor: inputStyles2, borderRadius: '4px' }}
                disabled={disabled}
                label={labelText + mandatoryChart}
                value={pathOr('', name, values)}
                onChange={(e) => setFieldValue(name, e.target.value)}
                InputProps={{
                    endAdornment: (disabled) && <LockIcon position="end" style={{ color: "rgba(255, 255, 255, 0.3)" }}></LockIcon>,
                }}
                inputProps={{
                    id: 'outlined-age-native-simple',

                }}
                error={(aplicationValidation || validateParam) && !!pathOr(false, name.split('.'), errors)} //MILAN edited
                {...rest}
            >
                {options?.map((option) => (
                    <MenuItem
                        key={option.value}
                        value={option.value}
                        disabled={option?.disabled}
                        classes={{
                            root: selectclasses.selectMenuItem,
                            selected: selectclasses.selectMenuItemSelectedMultiple
                        }}
                    >
                        <Typography variant="body2">{option.label} {option?.disabled ? `(${option?.disabled})` : ''}</Typography>
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText style={{ color: (pathOr(false, name.split('.'), errors)) ? '#F44336' : 'white' }}>{(aplicationValidation || validateParam) && helperTextPreferError}</FormHelperText>
        </FormControl>
    );

    const autocomplete = (
        <FormControl size="small" className={classes.root} fullWidth variant="outlined" >
            <Autocomplete
                onFocus={() => { setIsMyInputFocused(true); setAplicationValidation(true) }}
                onBlur={() => setIsMyInputFocused(false)}
                noOptionsText={'Žiadne možnosti'}
                style={{ backgroundColor: inputStyles2, borderRadius: '6px' }}
                //MenuProps={{ classes: { paper: classes.select } }}
                className={classes.selectForm + ' ' + classes.root}
                classes={{
                    paper: classes.selectAutocomplete,
                    clearIndicatorDirty: classes.selectIcon,
                    popupIndicator: classes.selectIcon
                }}
                MenuProps={{
                    disableScrollLock: true,
                }}
                //popupIcon={<YourCustomIcon className={classes.selectIcon} />}
                //classes={{ paper: selectclasses.selectMenuItem, selected: selectclasses.selectMenuItemSelectedMultiple }}
                disabled={disabled}
                // disableClearable
                size="small"
                id="combo-box-demo"
                options={options ? options : []}
                fullWidth
                getOptionLabel={(option) => option.label || ""}
                renderInput={(params) => <TextField {...params} label={<span style={{ color: (pathOr(false, name.split('.'), errors)) ? (aplicationValidation || validateParam) && '#F44336' : colors.labelAndBorderInputs }}>{labelText + mandatoryChart}</span>} variant="outlined" />}
                //onChange={(e) => setFieldValue(name, e.target.value)}
                onChange={(event, value) => setFieldValue(name, value?.value)}
                //value={pathOr('', name, values)}
                value={{
                    value: pathOr('', name, values),
                    label: (name === "legalForm")
                        ? (!pathOr('', name, values)) ? '' : intl.formatMessage({ id: `legalForm.${pathOr('', name, values)}` })
                        : (name === "clubs" || name === 'clubId')
                            ? options?.filter(item => Number(item.value) === Number(pathOr('', name, values)))?.[0]?.label
                            : pathOr('', name, values)
                }}
                //value={pathOr('', name, values)}
                label={labelText + mandatoryChart}
                variant="outlined"
                /*defaultValue={{
                value: pathOr('', name, values),
                label: (name === "legalForm")
                    ? intl.formatMessage({ id: `legalForm.${pathOr('', name, values)}` })
                    : pathOr('', name, values)
                }}*/

                inputProps={{
                    type: type,
                    disabled: disabled,
                    placeholder: placeholder,
                    disableScrollLock: true,
                    classes: {
                        root: classes.label,
                    },
                }}

                name={name}
                formControlProps={{
                    fullWidth: true,
                    ...formControlProps
                }}

                error={(aplicationValidation || validateParam) && !!pathOr(false, name.split('.'), errors)} //MILAN edited
                {...rest}
            />
            <FormHelperText style={{ color: (pathOr(false, name.split('.'), errors)) ? '#F44336' : 'white' }}>{(aplicationValidation || validateParam) && helperTextPreferError}</FormHelperText>
        </FormControl>
    );


    const datePicker = (
        <FormControl
            fullWidth
            inputVariant="outlined"
            //className={classes.root}
            error={!!pathOr(false, name.split('.'), errors)}
        //size="small"
        >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <ThemeProvider className={classes.root} theme={materialTheme}>
                    {(type === 'dateTimePicker') ?
                        <>
                            <DateTimePicker
                                onFocus={() => { setIsMyInputFocused(true); setAplicationValidation(true) }}
                                onBlur={() => setIsMyInputFocused(false)}
                                className={classes.root}
                                inputVariant="outlined"
                                style={{ backgroundColor: inputStyles2, borderRadius: '4px' }}
                                clearable
                                size="small"
                                value={null}
                                onChange={(date) => { setFieldValue(name, date) }}
                                //helperText={(aplicationValidation || validateParam) && helperTextPreferError}
                                label={labelText + mandatoryChart}
                                error={(aplicationValidation || validateParam) && !!pathOr(false, name.split('.'), errors)}
                                format="dd.MM.yyyy HH:mm"
                                disabled={disabled}
                                {...rest}
                                InputProps={{
                                    endAdornment: (disabled) && <LockIcon position="end" style={{ color: "rgba(255, 255, 255, 0.3)" }}></LockIcon>,
                                }}
                            />
                            <FormHelperText style={{ color: (pathOr(false, name.split('.'), errors)) ? '#F44336' : 'white' }}>{(aplicationValidation || validateParam) && helperTextPreferError}</FormHelperText>
                        </>
                        :
                        <>
                            <DatePicker
                                className={classes.root}
                                onFocus={() => { setIsMyInputFocused(true); setAplicationValidation(true) }}
                                onBlur={() => setIsMyInputFocused(false)}
                                size="small"
                                inputVariant="outlined"
                                style={{ backgroundColor: inputStyles2, borderRadius: '4px' }}
                                clearable
                                value={(pathOr('', name.split('.'), values)) ? (pathOr('', name.split('.'), values)) : null}
                                onChange={(date) => { setFieldValue(name, date) }}
                                // helperText={(aplicationValidation || validateParam) && helperTextPreferError}
                                label={labelText + mandatoryChart}
                                error={(aplicationValidation || validateParam) && !!pathOr(false, name.split('.'), errors)}
                                format={(type === 'datePicker') ? "dd.MM.yyyy" : "yyyy/MM"}
                                views={(type === 'datePicker') ? ["year", "month", "date"] : ["year", "month"]}
                                disabled={disabled}
                                {...rest}

                            />
                            <FormHelperText style={{ color: (pathOr(false, name.split('.'), errors)) ? '#F44336' : 'white' }}>{(aplicationValidation || validateParam) && helperTextPreferError}</FormHelperText>
                        </>
                    }
                </ThemeProvider>
            </MuiPickersUtilsProvider>
        </FormControl>
    );

    const checkbox = (
        <FormControl
            inputVariant="outlined"
            className={classes.root}
            error={(aplicationValidation || validateParam) && !!pathOr(false, name.split('.'), errors)}
            style={{ backgroundColor: inputStyles2, borderRadius: '4px' }}
        >
            <CustomCheckbox
                fullWidth={false}
                className={classes.root}
                checked={pathOr(false, name.split('.'), values)}
                label={<span style={{ color: (pathOr(false, name.split('.'), errors)) ? '#F44336' : 'white' }} >{labelText}</span>}
                error={(aplicationValidation || validateParam) && !!pathOr(false, name.split('.'), errors)}
                disabled={disabled}
                onChange={() => setFieldValue(name, !pathOr(false, name.split('.'), values))}
            />
            <FormHelperText style={{ color: (pathOr(false, name.split('.'), errors)) ? '#F44336' : 'white' }}>{(aplicationValidation || validateParam) && helperTextPreferError}</FormHelperText>

        </FormControl>
    );

    const toggle = (
        <Grid container spacing={1}
            inputVariant="outlined"
            className={classes.root}
            error={(aplicationValidation || validateParam) && !!pathOr(false, name.split('.'), errors)}
            style={{ backgroundColor: inputStyles2, borderRadius: "4px" }}
        >
            <div style={{ marginLeft: '10px' }}>
                <Grid item sm={12}>
                    <Grid component="label" container alignItems="center" spacing={1}>
                        <Typography variant="body1" style={{ color: (pathOr(false, name.split('.'), errors)) ? (aplicationValidation || validateParam) && '#F44336' : colors.labelAndBorderInputs }}>{labelText + mandatoryChart}:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Typography>
                        <Typography variant="body1" style={{ color: (pathOr(false, name.split('.'), errors)) ? (aplicationValidation || validateParam) && '#F44336' : colors.labelAndBorderInputs }}>Nie</Typography>
                        <Grid item>
                            <MainSwitch
                                checked={pathOr(false, name.split('.'), values)}
                                onChange={() => setFieldValue(name, !pathOr(false, name.split('.'), values))}

                                MenuProps={{
                                    className: classes.selectMenu
                                }}
                                classes={{
                                    select: classes.select
                                }}
                                disabled={disabled}
                                value={pathOr('', name, values)}
                                inputProps={{
                                    error: !!pathOr(false, name.split('.'), errors),
                                    placeholder: placeholder,
                                    ...inputProps
                                }}
                                inputlabelprops={{
                                    style: {
                                        color: 'red'
                                    },
                                    shrink: (placeholder) && true,
                                }}
                                {...rest}
                            />
                        </Grid>
                        <Typography variant="body1" style={{ color: (pathOr(false, name.split('.'), errors)) ? (aplicationValidation || validateParam) && '#F44336' : colors.labelAndBorderInputs }}>Áno</Typography>
                    </Grid>
                </Grid>
            </div>
        </Grid>
    );

    const getUrl = (link) => {
        let firstFourChars = "";
        if (link?.length > 4) firstFourChars = link.substring(0, 4);
        else firstFourChars = link;

        if (firstFourChars === "http") return link;
        else return 'https://' + link
    }

    const getReadFieldValue = () => {
        if (pathOr('', name, values)) {
            if (type === 'datePicker' || type === 'monthPicker' || type === 'dateTimePicker') {
                if (type === 'datePicker')
                    return moment(pathOr('', name.split('.'), values)).format('DD.MM.YYYY')
                else
                    return moment(pathOr('', name.split('.'), values)).format('DD.MM.YYYY, HH:mm')
            } else if (type === 'select') {
                if (name === 'country' || name === 'nationality' || name === 'countryOfBirth' || name === 'district' || name === 'correspondenceCountry'
                    || name === 'city' || name === 'correspondenceCity' || name === 'correspondenceDistrict')
                    return pathOr('', name, values)
                else
                    return intl.formatMessage({ id: `select.${pathOr('', name, values)}` })
            } else if (type === 'autocomplete') {
                if (name === 'legalForm')
                    return intl.formatMessage({ id: `select.${pathOr('', name, values)}` })
                else
                    return pathOr('', name, values)

            } else if (type === 'checkbox' || type === 'switch') {
                return (pathOr('', name, values)) ? "Áno" : "Nie"
            }

            else {
                if (link) {
                    return <span
                        style={{ color: colors.primary, cursor: 'pointer' }}
                        onClick={() => window.open(getUrl(pathOr('', name, values)), "_blank")}
                    >
                        {link}
                    </span>
                } else {
                    if (success)
                        return <span style={{ color: colors.success }}>{pathOr('', name, values)} {success}</span>
                    else
                        return pathOr('', name, values)
                }
            }
        } else {
            if (type === 'checkbox' || type === 'switch' || name === 'isYouthWorker') return "Nie"
            else return "-"
        }

    }

    const readOnly = (
        <Grid item sm={12} className={classes.readOnlyGrid} >
            <Typography variant="body2" 
                style={{ fontSize: "0.8em", color: colors.labelAndBorderInputs }}
            >{labelText}</Typography>
            <Typography variant="body2" style={{ fontSize: "1.1em", marginLeft: 2, marginTop: "-2px" }}>{getReadFieldValue()}</Typography>
        </Grid>
    );

    const pathSegments = name.split('.');
    const updatedPath = pathSegments.map(segment => {
    if (segment.includes('[') && segment.includes(']')) {
        const [arrayName, index] = segment.replace(']', '').split('[');
        return [arrayName, Number(index)];
    }
    return segment;
    }).flat();

// const resolvedValue = values ? pathOr('', updatedPath, values) : '';
// console.log('formikPropsPathOr:', resolvedValue);

    // console.log('formikPropsPathOr', pathOr('', updatedPath, values))
    // console.log('updatedPath', updatedPath);
    // console.log('name', name.split('.'))
    // console.log('values', values);
    // console.log('formikProps', formikProps)

    // const isDataOld = name.includes('dataOld');
    // const fieldValue = isDataOld 
    //     ? pathOr('', updatedPath.replace('dataOld.', ''), values.dataOld) 
    //     : pathOr('', updatedPath.replace('dataEdit.', ''), values.dataEdit);

    // console.log('Current Field Value:', fieldValue);
    

    const readOnlyInput = (
        <>
            <TextField
                minRows={2}
                style={{
                    backgroundColor: '#364759',
                    borderRadius: '10px',
                    borderColor: 'rgba(60, 72, 88, 0.4)',
                    color: '#F5F6F6',
                }}
                className={`${classes.root} inputTextfieldNoWrap readOnlyInput`}
                disabled={true}
                size="small"
                fullWidth
                label={labelText + mandatoryChart}
                variant="outlined"
                defaultValue="Success"
                id="validation-outlined-input"
                InputProps={{
                    endAdornment: (
                        <>
                            {disabled && (
                                <LockIcon 
                                    position="end" 
                                    style={{ color: "rgba(255, 255, 255, 0.3)", marginRight: "0.3rem" }}
                                />
                            )}
                            <InputAdornment position="end">
                                <span style={{ color: '#F5F6F6' }}>€</span>
                            </InputAdornment>
                        </>
                    ),

                }}
                inputProps={{
                    value: pathOr('', updatedPath, values),
                    type: type,
                    disabled: true,
                    style: {
                        height: inputHeight,
                    },

                    classes: {
                        root: classes.label,
                        notchedOutline: classes.noBorder
                    },
                    
                    ...inputProps
                }}
                name={name}
                formControlProps={{
                    fullWidth: true,
                    ...formControlProps
                }}
                error={(aplicationValidation || validateParam) && !!pathOr(false, name.split('.'), errors)} //MILAN edited
                {...rest}
            />
            <FormHelperText style={{ color: '#F5F6F6', }}>{(aplicationValidation || validateParam) && helperTextPreferError}</FormHelperText>
        </>
    );

    const textFieldInput = (
        <>
            <TextField
                onFocus={() => { setIsMyInputFocused(true); setAplicationValidation(true) }}
                onBlur={() => setIsMyInputFocused(false)}
                multiline={(type === "textArea") ? true : false}
                minRows={2}
                style={{
                    backgroundColor: inputStyles2,
                    borderRadius: '6px',
                }}
                className={`${classes.root} inputTextfieldNoWrap`}
                disabled={disabled}
                size="small"
                fullWidth
                label={labelText + mandatoryChart}
                variant="outlined"
                defaultValue="Success"
                id="validation-outlined-input"
                InputProps={{
                    endAdornment: (
                        <>
                            {disabled && (
                                <LockIcon 
                                    position="end" 
                                    style={{ color: "rgba(255, 255, 255, 0.3)", marginRight: "0.3rem" }}
                                />
                            )}
                            <InputAdornment position="end">
                                <span style={{ color: '#F5F6F6' }}>€</span>
                            </InputAdornment>
                        </>
                    ),

                }}
                inputProps={{
                    value: pathOr('', updatedPath, values),
                    type: type,
                    onChange: (e) => {
                        const value = e.target.value;
                    
                        // Regular expression to allow only numbers and decimals
                        const isValidInput = /^[0-9]*\.?[0-9]*$/.test(value);
                    
                        // If the input is valid, update the field value
                        if (isValidInput) {
                            setFieldValue(name, value);
                        }
                    },
                    disabled: disabled,
                    placeholder: placeholder,
                    style: {
                        height: inputHeight,
                    },

                    classes: {
                        root: classes.label,
                    },

                    ...inputProps
                }}
                name={name}
                // formControlProps={{
                //     fullWidth: true,
                //     ...formControlProps
                // }}

                error={(aplicationValidation || validateParam) && !!pathOr(false, name.split('.'), errors)} //MILAN edited
                {...rest}
            />
            <FormHelperText style={{ color: (pathOr(false, name.split('.'), errors)) ? '#F44336' : 'white' }}>{(aplicationValidation || validateParam) && helperTextPreferError}</FormHelperText>
        </>
    );

    const getInputByType = () => {
        if (!readMode) {
            if (type === 'select') {
                return select;
            } else if (type === 'multiselect') {
                return multiselect;
            } else if (type === 'checkbox') {
                return checkbox;
            } else if (type === 'switch') {
                return toggle;
            } else if (type === 'datePicker' || type === 'monthPicker' || type === 'dateTimePicker') {
                return datePicker;
            } else if (type === 'autocomplete') {
                return autocomplete;
            } else {
                return textField;
            }
        } else if (readMode === 'readOnlyInput') {
            return readOnlyInput;
        } else if ( readMode === 'textFieldInput') {
            return textFieldInput;
        }
            return readOnly;
    };

    return getInputByType();
};

export default injectIntl(FormikField);

