import React, { useState, useEffect, useRef } from "react";
import { Grid } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import Button from "components/CustomButtons/Button.js";

import paths from "../../paths";
import { Formik } from "formik";
import FormikField from "../../components-lib/FormikField/FormikField";
import { useSnackbar } from "notistack";
import { generatePath } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { colors, widgets } from "common/styles/configLayout.js";
import moment from "moment";
import IconCard from 'components-lib/IconCard/IconCard';
import { useLocation } from 'react-router-dom';
import CLUBS from "queries/ClubsQueries/clubs";
import { pathOr } from 'rambda';
import { useQuery } from '@apollo/client';
import PRICING from 'queries/PricingQueries/pricing';
import Modal from "components-lib/Modal/Modal";
import AddIcon from '@material-ui/icons/Add';
import PageHeader from "components-lib/PageHeader/PageHeader";
import PriceListTable from "./PriceListTable";
import { getApolloClient } from "configFiles/apollo";
import { useLazyQuery } from '@apollo/client';
import { connect } from 'react-redux';
import { getValidationSchemaButton, getValidationSchemaYearAndClub, getValidationSchemaYear } from "./validationSchema";
import RoleEnum from 'common/enums/RoleEnum'
import { changeLicensesNumberAction } from 'redux/actions';
import PROFILE from 'queries/ProfileQueries/profile';
import LICENSING_INFO from "queries/GenerallyQueries/licensingInfo";

import PriceList from './PriceList';
import { AntTabs, AntTab } from 'common/styles/muiDesign';
import Loading from "components-lib/Loading/Loading";
import styles from "common/styles/widgets.js";
import InfoIcon from '@material-ui/icons/Info';
import { makeStyles } from '@material-ui/core/styles';
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import DescriptionIcon from '@material-ui/icons/Description';
import PRICING_UPDATE from 'queries/PricingQueries/pricingUpdate'
import { useMutation } from '@apollo/client';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import GroupIcon from '@material-ui/icons/Group';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles((theme) => styles(theme))

const PriceListPage = (props) => {
  const { intl, history, role, changeLicensesNumberAction } = props;
  let location = useLocation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { data: userProfile } = useQuery(PROFILE);
  
  const currentYear = new Date().getFullYear();
  const [ year, setYear ] = useState(currentYear - 1);
  const [ yearEdit, setYearEdit ] = useState(currentYear);
  const { loading: loadingOld, error: errorOld, data: dataOld, refetch: refetchOld } = useQuery(PRICING, {
    variables: { year },
  });

  const { loading: loadingEdit, error: errorEdit, data: dataEdit, refetch: refetchEdit } = useQuery(PRICING, {
    variables: { year: yearEdit },
  });

  const [initialValues, setInitialValues] = React.useState({
    dataOld: dataOld || {},
    dataEdit: dataEdit || {},
  });
  const [pricingUpdate, { loading, error }] = useMutation(PRICING_UPDATE);


  const [tab, setTab] = useState(0);

  const [modalOpen, setModalOpen] = useState(false);
  const [editPrice, setEditPrice] = useState(false);
  const [expandedGroupEdit, setExpandedGroupEdit] = useState(null);

  const options = Array.from({ length: 3 }, (_, index) => {
    const year = currentYear - index;
    return { label: String(year), value: String(year) };
  });
  React.useEffect(() => {
    if (dataOld && dataEdit) {
      setInitialValues({
        dataOld: dataOld,
        dataEdit: dataEdit,
      });
    }
  }, [dataOld, dataEdit]);

  const handleYearChange = (newYear) => {
    setYear(newYear);
    refetchOld({ year: newYear });
  };

  const handleYearEditChange = (newYearEdit) => {
    setYearEdit(newYearEdit);
    refetchEdit({ year: newYearEdit });
  };

  const handleChangeTab = async (event, newValue, formikProps) => {
    if ( editPrice === true ) {
      await refetchEdit()
      setEditPrice(false)
      formikProps.resetForm();
    }
    setExpandedGroupEdit(null);

    setTab(newValue);
  };

  // const cleanTypename = (obj) => {
  //   if (Array.isArray(obj)) {
  //     return obj.map(item => cleanTypename(item));
  //   } else if (typeof obj === 'object' && obj !== null) {
  //     const newObj = {};
  //     Object.keys(obj).forEach(key => {
  //         if (key !== '__typename') {
  //             newObj[key] = cleanTypename(obj[key]);
  //         }
  //     });
  //     return newObj;
  //   }
  //   return obj;
  // };

  const getChangedOthers = (initialOthers, currentOthers) => {
      // Check if the values differ
      if (initialOthers.licenseCardIssueFee !== currentOthers.licenseCardIssueFee) {
          // Return the changed value if they are different
          return currentOthers.licenseCardIssueFee;
      }
      // Return null if there is no change
      return [];
  };

  const getChangedCategories = (initialCategories, currentCategories) => {

    return currentCategories.filter((currentCategory, index) => {
        const initialCategory = initialCategories[index];

        return (
            currentCategory.license.standardFee !== initialCategory.license.standardFee ||
            currentCategory.license.uciFee !== initialCategory.license.uciFee ||
            currentCategory.license.transferFee !== initialCategory.license.transferFee
        );
    });
  };

  const getChangedFunctions = (initialFunctions, currentFunctions) => {
    return currentFunctions.filter((currentFunction, index) => {
      const initialFunction = initialFunctions[index];
      
      return (
        currentFunction.license.fee !== initialFunction.license.fee ||
        currentFunction.license.expressFee !== initialFunction.license.expressFee
      );
    });
  };
  
  const getChangedClubs = (initialClubs, currentClubs) => {
    return currentClubs.filter((currentClub, index) => {
      const initialClub = initialClubs[index];
      
      return (
        currentClub.license.renewFee !== initialClub.license.renewFee ||
        currentClub.license.fee !== initialClub.license.fee
      );
    });
  };

  const handleUpdate = async (body) => {

    const { year, clubs, functions, categories, others } = body.dataEdit.pricing;

    let input = {};

    if (tab === 3) {
      const changedOthers = getChangedOthers(dataEdit.pricing.others, others);
      input = {
        others: {
          licenseCardIssueFee: changedOthers ? parseFloat(changedOthers) : null,
        },
      };
    } else if (tab === 2) {
      const changedCategories = getChangedCategories(dataEdit.pricing.categories, categories);
      input = {
        categories: changedCategories.map(({ id, license }) => ({
          id,
          license: {
            standardFee: license.standardFee ? parseFloat(license.standardFee) : null,
            uciFee: license.uciFee ? parseFloat(license.uciFee) : null,
            transferFee: license.transferFee ? parseFloat(license.transferFee) : null,
          },
        })),
      };
    } else if (tab === 1) {
      const changedFunctions = getChangedFunctions(dataEdit.pricing.functions, functions);
      input = {
        functions: changedFunctions.map(({ id, license }) => ({
          id,
          license: {
            fee: license.fee ? parseFloat(license.fee) : null,
            expressFee: license.expressFee ? parseFloat(license.expressFee) : null,
          },
        })),
      };
    } else if (tab === 0) {
      const changedClubs = getChangedClubs(dataEdit.pricing.clubs, clubs);
      input = { 
        clubs: changedClubs.map(({ type, license }) => ({
          type,
          license: {
            fee: license.fee ? parseFloat(license.fee) : null,
            renewFee: license.renewFee ? parseFloat(license.renewFee) : null,
          },
        })),
      }
    }

    // console.log(input)

    try {
      const response = await pricingUpdate({
        variables: {
          year: year,
          input: input,
        },
      });

      await refetchEdit()
      setEditPrice(false)

      enqueueSnackbar(intl.formatMessage({ id: "priceList.snackbar.success" }), { variant: 'success' });
    } catch (err) {
      console.error('[Error]:', err);
      enqueueSnackbar(intl.formatMessage({ id: "priceList.snackbar.error" }), { variant: 'error' });
    }        
  }

  const getActions = (formikProps) => {
    let actions = [
      {
        title: "Upraviť poplatky",
        onClick: () => {
          if (editPrice === true) {
            formikProps.handleSubmit();
          } else {
            setEditPrice(true)
          }
        },
      },
    ];

    if (editPrice === true) {
        actions.unshift({
          title: "Zrušiť",
          onClick: () => {
            formikProps.resetForm({
              values: {
                ...formikProps.values,
                dataEdit: dataEdit,
              },
            });
            setEditPrice(false);
          },
        });
    } 
    // else {
    //   actions.unshift({
    //     title: "Vytvoriť",
    //     onClick: () => {
    //       setModalOpen(true);
    //     },
    //   });
    // }

    return actions;
  };

  const renderModalButtons = (formikProps) => {
    return (
      <>
        <Button
          disabled={false}
          onClick={() => handleCreatePriceList()}
          color="primary"
          round
          table
          size="sm">
          Vytvoriť cenník
        </Button>
      </>
    );
  };

  const handleCreatePriceList = () => {
    console.log('ahoj')
  }

  const renderTables = (formikProps) => {
    return (
      <div>
        <PriceList
          formikProps={formikProps}
          editPrice={editPrice}
          currentYear={currentYear}
          tab={tab}
          setEditPrice={setEditPrice}
          intl={intl}
          handleYearChange={handleYearChange}
          handleYearEditChange={handleYearEditChange}
          year={year}
          yearEdit={yearEdit}
          setExpandedGroupEdit={setExpandedGroupEdit}
          expandedGroupEdit={expandedGroupEdit}
        />
      </div>
    );
  };

  // React.useEffect(() => {
  //   if (dataEdit) {
  //     setInitialValues({
  //       dataOld: dataOld || {},
  //       dataEdit: dataEdit || {},
  //     });
  //   }
  // }, [dataEdit, dataOld]);

  if (loadingOld || loadingEdit) return <Loading />;

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true} 
        onSubmit={(values) => {
          handleUpdate(values);
        }}
      >
        {formikProps => {
          return (            
            <>
              <Grid container style={{
                minWidth:'10rem',
                maxWidth:'80rem',
                width:"auto",
               }}>
                <Grid
                  style={{
                    width: "100%",
                  }}
                >
                  <PageHeader
                    actions={getActions(formikProps)}
                    withBackButton={false}
                    tabs={
                      <AntTabs
                        onChange={(event, newValue) => handleChangeTab(event, newValue, formikProps)}
                        value={tab}
                        aria-label="Tabs where each tab needs to be selected manually"
                        variant="scrollable"
                      >
                        <AntTab label={<Typography variant="body1" >
                          <AssignmentIndIcon className={classes.tabsIcon} />
                          {intl.formatMessage({ id: "priceList.clubs" })}</Typography>} 
                        />
                        <AntTab label={<Typography variant="body1" >
                          <DirectionsBikeIcon className={classes.tabsIcon} />
                          {intl.formatMessage({ id: "priceList.functions" })}</Typography>} 
                        />
                        <AntTab label={<Typography variant="body1" >
                          <GroupIcon className={classes.tabsIcon} /> 
                          {intl.formatMessage({ id: "priceList.categories" })}</Typography>} 
                        />
                        <AntTab label={<Typography variant="body1" >
                          <GroupIcon className={classes.tabsIcon} /> 
                          {intl.formatMessage({ id: "priceList.others" })}</Typography>} 
                        />
                      </AntTabs>
                    }
                  />

                </Grid>
                <IconCard
                  // minWidth={'10rem'}
                  // maxWidth={'80rem'}
                  width={"100%"}
                  padding={'16px'}
                  >
                  {renderTables(formikProps)}
                </IconCard>
              </Grid>
            </>
          )
        }}
      </Formik>
      
      <Modal
        title={"Žiadost o vytvorenie tlačenej karty"}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        actions={renderModalButtons()}
        fullWidth
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Select
              MenuProps={{ classes: { paper: classes.select } }}
              style={{ fontSize: "0.8rem" }}
              value={year}
              onChange={(e) => {
              console.log(e.target.value);
              // handleYearChange(e.target.value);
              }}
              inputProps={{
                  classes: { icon: classes.selectIcon },
              }}
          >
            {options.map((option, index) => (
            <MenuItem
                key={`filter.${index}`}
                value={option.value}
                className="inputSelect"
                classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
                }}
            >
                {option.label}
            </MenuItem>
            ))}
          </Select>
        </Grid>
        </Grid>

      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  role: state.role,
});

const mapDispatchToProps = (dispatch) => ({
  changeLicensesNumberAction: (value) => dispatch(changeLicensesNumberAction(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PriceListPage));

