import gql from 'graphql-tag';

const MEMBER_LICENSE_DOWNLOAD = gql`
query memberLicenseDownload($id: ID!) {
  memberLicenseDownload(id: $id){
    url
  }
}
`;

export default MEMBER_LICENSE_DOWNLOAD;

