import IconCard from "components-lib/IconCard/IconCard";
import PageHeader from "components-lib/PageHeader/PageHeader";
import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import NotificationsTable from "./NotificationsTable";
import CLUB_MEMBERS_TABLE from 'queries/MembersQueries/clubMembersTable';
import { useLocation } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import { connect } from 'react-redux';
import { useQuery, useLazyQuery } from '@apollo/client';
import RoleEnum from 'common/enums/RoleEnum';
import LICENSING_INFO from "queries/GenerallyQueries/licensingInfo";
import { pathOr } from "rambda";

const NotificationsListPage = (props) => {
  const { intl, history, role } = props;
  let location = useLocation();
  let defaultFilter = location.state ? location.state : null
 
  const { data: licensingInfoData } = useQuery(LICENSING_INFO);  
  const [loadData, { data }] = useLazyQuery(CLUB_MEMBERS_TABLE);

  const totalCount = pathOr(0, ["clubMembersTable", "totalCount"], data);

  const [ disableButton, setDisableButton ] = useState(false);

  useEffect(() => {
    
    loadData({
      variables: {
        // ...queryVariables,
        offset: 0,
        limit: 5,
        filter: { license: {
          eq: currentYear,
      }, },
        // sort: sorts.length > 0 ? sorts : defaultSort ? defaultSort : [],
      },
    });
  }, []);

  const currentYear = new Date().getFullYear();
  const [ edit, setEdit ] = React.useState(false);

  const getActions = () => {
    let array = [];
    if (role === RoleEnum.ADMIN) {
      array.push({
        title: intl.formatMessage({ id: "notification.addNotification.button" }),
        icon: <AddIcon />,
        onClick: () => {
          setEdit(true);
          setDisableButton(true);
        },
        disabled: disableButton
      })
    }
    return array;
  };

  const renderTables = () => {
    return (
      <div>
        <NotificationsTable
          query={CLUB_MEMBERS_TABLE}
          variables={{
            license: {
              eq: currentYear,
            },
          }}
          defaultFilter={defaultFilter}
          history={history}
          queryDataPath={['clubMembersTable', 'items']}
          currentYear={licensingInfoData?.licensingInfo?.periods?.current?.year}
          nextYear={licensingInfoData?.licensingInfo?.periods?.next?.year}
          edit={edit}
          totalCount={totalCount}
          setEdit={setEdit}
          setDisableButton={setDisableButton}
        />
      </div>
    );
  };
  return (
    <>
      <PageHeader
        title={null}
        actions={getActions()}
        withBackButton={false}
      />
      <IconCard>
        {renderTables()}
      </IconCard>
    </>
  );
};

const mapStateToProps = (state) => ({
  role: state.role,
});

export default connect(mapStateToProps)(injectIntl(NotificationsListPage));