import { makeStyles } from '@material-ui/core/styles';
import QueryTable from 'components-lib/QueryTable/QueryTable';
import Button from "components/CustomButtons/Button.js";
import paths from 'paths';
import React from 'react';
import { injectIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';
import styles from "common/styles/widgets.js";
import Box from "@material-ui/core/Box";
import moment from "moment";
import StatusTextField from "components-lib/StatusTextField/StatusTextField";
import { connect } from 'react-redux';
import RoleEnum from "common/enums/RoleEnum";
import GetAppIcon from '@material-ui/icons/GetApp';
import Tooltip from '@material-ui/core/Tooltip';
import { pathOr } from 'rambda';
import { useQuery } from '@apollo/client';

const useStyles = makeStyles((theme) => styles(theme))

export const PaymentTable = (props) => {
    const { history, queryVariables, role, query, intl, defaultFilter = null, numberOfRows, queryDataPath } = props
    const classes = useStyles();

    const handleInvoiceDownload = (url) => {
        // window.open(url, "_blank").focus()
        console.log(url)
    }

    const onPaymentDetail = (id) => {
        history.push(`/admin${generatePath(paths.payments.detail, { paymentId: id })}`);
    }


    // console.log(role)
    return (
        <QueryTable
            nameTable={paths.payments.list}
            query={query}
            numberOfRows={numberOfRows}
            permanentFilter={defaultFilter}
            queryDataPath={queryDataPath}
            queryVariables={queryVariables}
            // columnsWidth={['140px', undefined, undefined, undefined, undefined, '110px', '155px', '130px', undefined]}
            columns={[
                {
                    Header: intl.formatMessage({ id: 'table.bid' }),
                    accessor: 'bid',
                    sortKey: 'bid',
                    filterKey: 'bid',
                    filterOperator: 'contains',
                    minWidth: '85px',
                    maxWidth: '100%'
                },
                {
                    Header: intl.formatMessage({ id: 'payments.table.createdAt' }),
                    accessor: (rowData) => rowData.createdAt !== null ? moment(rowData.createdAt).format("DD.MM.YYYY") : "-",
                    sortKey: "createdAt",
                    filterKey: "createdAt",
                    filterOperator: 'between',
                    filterDataType: "dateBetween",
                    filterComponent: 'dateRange',
                    minWidth: '94px',
                    maxWidth: '100%'
                },
                {
                    Header: intl.formatMessage({ id: 'payments.table.paidAt' }),
                    accessor: (rowData) => rowData.paidAt !== null ? moment(rowData.paidAt).format("DD.MM.YYYY") : "-",
                    sortKey: "paidAt",
                    filterKey: "paidAt",
                    filterOperator: 'between',
                    filterDataType: "dateBetween",
                    filterComponent: 'dateRange',
                    minWidth: '93px',
                    maxWidth: '100%'
                },
                {
                    Header: intl.formatMessage({ id: 'payments.table.club' }),
                    accessor: "club",
                    sortKey: "club",
                    filterKey: 'club',
                    filterOperator: 'contains',
                    minWidth: '85px',
                    maxWidth: '100%'
                },
                {
                    Header: intl.formatMessage({ id: 'payments.table.description' }),
                    accessor: "description",
                    sortKey: "description",
                    filterKey: "description",
                    filterOperator: 'contains',
                    minWidth: '82px',
                    maxWidth: '100%'
                },
                {
                    Header: intl.formatMessage({ id: 'payments.table.amount' }),
                    accessor: (rowData) => rowData.amount + " €",
                    sortKey: "amount",
                    filterKey: "amount",
                    filterOperator: 'eq',
                    filterDataType: 'number',
                    minWidth: '85px',
                    maxWidth: '100%'

                },
                {
                    Header: intl.formatMessage({ id: 'payments.table.method' }),
                    accessor: (rowData) => (rowData.method) ? intl.formatMessage({ id: `payments.method.${rowData.method}` }) : "-",
                    sortKey: 'method',
                    filterKey: 'method',
                    filterComponent: 'select',
                    filterOperator: 'eq',
                    filterOptions: [
                        {
                            value: "PaymentGateway",
                            label: intl.formatMessage({ id: 'payments.method.PaymentGateway' })
                        },
                        {
                            value: "BankTransfer",
                            label: intl.formatMessage({ id: 'payments.method.BankTransfer' })
                        },
                        {
                            value: "Cash",
                            label: intl.formatMessage({ id: 'payments.method.Cash' })
                        }
                    ],
                    minWidth: '152px',
                    maxWidth: '100%'
                },
                {
                    Header: intl.formatMessage({ id: 'table.status' }),
                    accessor: (rowData) => <StatusTextField intl={intl} status={rowData.status} />,
                    sortKey: 'status',
                    filterKey: 'status',
                    filterComponent: 'select',
                    filterOperator: 'eq',
                    filterOptions: [
                        {
                            value: "Pending",
                            label: intl.formatMessage({ id: `payments.status.Pending` })
                        },
                        {
                            value: "Succeeded",
                            label: intl.formatMessage({ id: `payments.status.Succeeded` })
                        },
                        {
                            value: "Canceled",
                            label: intl.formatMessage({ id: `payments.status.Canceled` })
                        }
                    ],
                    minWidth: '100px',
                    maxWidth: '100%'
                },
                {
                    Header: ' ',
                    filterKey: "actions",
                    accessor: (rowData) => 
                        <Box className={classes.floatRight}>
                            {( role === RoleEnum.ADMIN && rowData.invoiceUrl ) && 
                            <>
                            <Tooltip title={intl.formatMessage({ id: 'payments.action.invoiceDownload' })}>
                                <GetAppIcon 
                                    className={classes.circle} 
                                    onClick={() => handleInvoiceDownload(rowData.invoiceUrl)} />
                            </Tooltip>
                            </>
                            }
                            <Button table round size="sm" color="info"
                                onClick={() => onPaymentDetail(rowData.id)}>{intl.formatMessage({ id: 'table.detail' })}
                            </Button>
                        </Box>,
                    minWidth: '103px',
                    maxWidth: '100%'
                }
            ]}
        />
    )
}

const mapStateToProps = (state) => ({
    role: state.role,
});


export default connect(mapStateToProps)(injectIntl(PaymentTable));

//134