import IconCard from "components-lib/IconCard/IconCard";
import PageHeader from "components-lib/PageHeader/PageHeader";
import React, { useEffect } from "react";
import { injectIntl } from "react-intl";
import { useLocation } from 'react-router-dom';
import Typography from "@material-ui/core/Typography"
import styles from "common/styles/widgets.js";
import { makeStyles } from '@material-ui/core/styles';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import { AntTabs, AntTab } from 'common/styles/muiDesign';
import { connect } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import DocumentTable from './DocumentTable';
import DocumentTableEquipped from './DocumentTableEquipped';
import DOCUMENTS_TABLE from 'queries/DocumentsQueries/documentsTable';
import { changeDocumentsNumberAction } from 'redux/actions';
import { getApolloClient } from "configFiles/apollo";
import { useSnackbar } from 'notistack';
import { pathOr } from 'rambda';

const useStyles = makeStyles((theme) => styles(theme))

const DocumentListPage = (props) => {
  const { intl, history, role, changeDocumentsNumberAction } = props;
  let location = useLocation();
  const classes = useStyles();
  const client = getApolloClient(enqueueSnackbar, history, intl);
  const { enqueueSnackbar } = useSnackbar();

  const [loadDocuments, { data: dataDocuments, refetch: refetchDocuments }] = useLazyQuery(DOCUMENTS_TABLE);


  const getDocumentsNumber = async () => {
    try {
      const result = await client.query({
        query: DOCUMENTS_TABLE,
        variables: {
          "filter": [
            {
              "status": {
                "eq": "Awaiting"
              },
              "relation": {
                "ne": "Request"
              },
            }
          ]
        }
      });
      if (result.error) {
        throw result.error;
      }
      if (result.errors) {
        throw result.errors[0];
      }
      changeDocumentsNumberAction(pathOr('', ['data', 'documentsTable', 'totalCount'], result));
    } catch (error) {
      throw error;
    }
  }

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    getDocumentsNumber()
  }, []);

  const renderTables = () => {
    return (
      <div>
        {value === 0 &&
          <DocumentTable
            data={dataDocuments}
            loadData={loadDocuments}
            refetch={refetchDocuments}
            queryDataPath={['documentsTable', 'items']}
            defaultFilter={{ status: { eq: "Awaiting" }, relation: { ne: "Request" } }}
          />

        }
        {value === 1 &&
          <DocumentTableEquipped
            data={dataDocuments}
            loadData={loadDocuments}
            refetch={refetchDocuments}
            queryDataPath={['documentsTable', 'items']}
            defaultFilter={{ status: { ne: "Awaiting" }, relation: { ne: "Request" } }}
          />
        }
      </div>
    );
  };

  return (
    <>
      <PageHeader
        withBackButton={false}
        tabs={
          <AntTabs
            onChange={handleChange}
            value={value}
            aria-label="Tabs where each tab needs to be selected manually"
            variant="scrollable"
          >
            <AntTab label={<Typography variant="body1" ><AssignmentLateIcon className={classes.tabsIcon} />Nevybavené dokumenty</Typography>} />
            <AntTab label={<Typography variant="body1" ><AssignmentTurnedInIcon className={classes.tabsIcon} />Vybavené dokumenty</Typography>} />
          </AntTabs>
        }
      />
      <IconCard>
        {renderTables()}
      </IconCard>
    </>
  );
};

const mapStateToProps = (state) => ({
  role: state.role,
});

const mapDispatchToProps = (dispatch) => ({
  changeDocumentsNumberAction: (value) => dispatch(changeDocumentsNumberAction(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DocumentListPage));

