import { makeStyles } from '@material-ui/core/styles';
import QueryTableRefresh from 'components-lib/QueryTable/QueryTableRefresh';
import Button from "components/CustomButtons/Button.js";
import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import styles from "common/styles/widgets.js";
import { clubDetail } from 'configFiles/roles'
import Box from "@material-ui/core/Box";
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';
import { colors, } from 'common/styles/configLayout';
import Modal from "components-lib/Modal/Modal";
import DocumentViewer from "./DocumentViewer";
import Grid from '@material-ui/core/Grid';
import moment from "moment";
import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/client';
import SearchIcon from '@material-ui/icons/Search';
import DOCUMENT from 'queries/DocumentsQueries/document';
import DOCUMENT_DELETE from 'queries/DocumentsQueries/documentDelete';
import { getApolloClient } from "configFiles/apollo";
import DOCUMENTS_TABLE from 'queries/DocumentsQueries/documentsTable';
import CLUB from 'queries/ClubsQueries/club';
import PERSON from 'queries/MembersQueries/person';
import PostAddIcon from '@material-ui/icons/PostAdd';
import { connect } from 'react-redux';
import { changeDocumentsNumberAction } from 'redux/actions';
import Tooltip from '@material-ui/core/Tooltip';
import StatusTextField from "components-lib/StatusTextField/StatusTextField";
import { pathOr } from 'rambda';
import BlockIcon from '@material-ui/icons/Block';

const useStyles = makeStyles((theme) => styles(theme))

export const DocumentTable = (props) => {
    const { intl, history, changeDocumentsNumberAction, data, loadData, refetch, numberOfRows = 25, queryDataPath, formikProps, defaultFilter,
        documentDeleteMutation } = props
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const client = getApolloClient(enqueueSnackbar, history, intl);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [documentsDialogModalOpen, setDocumentsDialogModalOpen] = useState(false);
    const [files, setFiles] = useState([]);
    const [actualId, setActualId] = useState(null);
    const [documentDelete] = useMutation(DOCUMENT_DELETE);
    const [actualStatus, setActualStatus] = useState(null);
    const [documentType, setDocumentType] = useState(null);
    const [viewerData, setViewerData] = useState(null);
    const [documentData, setDocumentData] = useState(null);

   const getDocumentsNumber = async () => {
    try {
      const result = await client.query({
        query: DOCUMENTS_TABLE,
        variables: {
          "filter": [
            {
              "status": {
                "eq": "Awaiting"
              },
              "relation": {
                "ne": "Request"
              },
            }
          ]
        }
      });
      if (result.error) {
        throw result.error;
      }
      if (result.errors) {
        throw result.errors[0];
      }
      changeDocumentsNumberAction(pathOr('', ['data', 'documentsTable', 'totalCount'], result));
    } catch (error) {
      throw error;
    }
  }

    function downloadURI(url) {
        var link = document.createElement("a");
        link.download = url;
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    const handleDownloadDocument = (url) => {
        downloadURI(url)
        enqueueSnackbar(intl.formatMessage({ id: "documents.snackbar.download.success" }), { variant: 'success' });
    }

    const handleDeleteDocument = () => {
        documentDelete({
            variables: {
                id: actualId,
            },
        }).then((response) => {
            enqueueSnackbar(intl.formatMessage({ id: "documents.snackbar.delete.success" }), { variant: 'success' });
            refetch();
            getDocumentsNumber();
            setDeleteModalOpen(false);
        }).catch((err) => {
            console.log('[Error]: ', err);
        });
        
    }

    const handleGetClubData = async (id) => {
        try {
            const result = await client.query({
                query: CLUB,
                variables: {
                    id: id
                }
            });
            if (result.error) {
                throw result.error;
            }
            if (result.errors) {
                throw result.errors[0];
            }
            setViewerData(result?.data?.club?.profile)
        } catch (error) {
            throw error;
        }
    }

    const handleGetProfileData = async (id) => {
        try {
            const result = await client.query({
                query: PERSON,
                variables: {
                    id: id
                }
            });
            if (result.error) {
                throw result.error;
            }
            if (result.errors) {
                throw result.errors[0];
            }
            setViewerData(result?.data?.person?.profile)
        } catch (error) {
            throw error;
        }
    }

    const handleGetFiles = async (id, clubId, personId) => {

        try {
            const result = await client.query({
                query: DOCUMENT,
                variables: {
                    id: id
                }
            });
            if (result.error) {
                throw result.error;
            }
            if (result.errors) {
                throw result.errors[0];
            }
            let tempFiles = [];
            let res = result?.data?.document?.files;
            tempFiles = (res)?.map(item => ({
                uri: item?.downloadUrl
            }));
            setFiles(tempFiles);
            let docType = (clubId) ? "Club" : "Profile"

            setDocumentType(docType);
            setDocumentData(result?.data?.document);
            if (docType === "Club") handleGetClubData(clubId)
            else handleGetProfileData(personId)

            setDocumentsDialogModalOpen(true)
        } catch (error) {
            throw error;
        }
    }
    const getStatus = (status) => {
        if (status === "Awaiting")
            return <Box style={{ color: colors.warning }} > {intl.formatMessage({ id: "documents.table.status.awaiting" })}</Box>
        else if (status === "Approved")
            return <Box style={{ color: colors.success }}>{intl.formatMessage({ id: "documents.table.status.approved" })}</Box>
        if (status === "Rejected")
            return <Box style={{ color: colors.error }}>{intl.formatMessage({ id: "documents.table.status.rejected" })}</Box>
        else
            return <Box>{status}</Box>
    }

    const renderDeleteModalButtons = () => {
        return (
            <>
                <Button
                    disabled={false}
                    onClick={() => handleDeleteDocument()}
                    color="danger"
                    round
                    size="sm">
                    <BlockIcon /> {intl.formatMessage({ id: 'button.delete' })}
                </Button>
            </>
        );
    };

    const getRelation = (relation) => {
        switch (relation) {
            case "Request": return "Žiadosť";
            case "Club": return "Klub";
            case "Person": return "Osoba";
            default: return "Žiadosť"
        }
    }

    const renderColumns = () => {
        return ([
            {
                Header: intl.formatMessage({ id: 'table.bid' }),
                accessor: 'bid',
                sortKey: 'bid',
                filterKey: 'bid',
                filterOperator: 'contains',
                minWidth: '85px',
                maxWidth: '100%'
            },
            {
                Header: 'Typ',
                accessor: rowData => getRelation(rowData.relation),
                sortKey: 'relation',
                filterKey: 'relation',
                filterOperator: 'contains',
                minWidth: '79px',
                maxWidth: '100%'
            },
            {
                Header: 'Vlastník',
                accessor: rowData => rowData.owner ? rowData.owner : "-",
                sortKey: 'owner',
                filterKey: 'owner',
                filterOperator: 'contains',
                minWidth: '100px',
                maxWidth: '100%'
            },
            {
                Header: intl.formatMessage({ id: "documents.table.name" }),
                accessor: "name",
                sortKey: 'name',
                filterKey: 'name',
                filterOperator: 'contains',
                minWidth: '100px',
                maxWidth: '100%'
            },
            {
                Header: intl.formatMessage({ id: "documents.table.createdAt" }),
                accessor: (rowData) => rowData.createdAt !== null ? moment(rowData.createdAt).format("DD.MM.YYYY HH:mm") : "-",
                sortKey: 'createdAt',
                filterKey: 'createdAt',
                filterOperator: 'between',
                filterDataType: "dateBetween",
                filterComponent: 'title',
                minWidth: '100px',
                maxWidth: '100%'
            },
            {
                Header: intl.formatMessage({ id: "documents.table.validFrom" }),
                accessor: (rowData) => rowData.validFrom !== null ? moment(rowData.validFrom).format("DD.MM.YYYY") : "-",
                sortKey: 'validFrom',
                filterKey: 'validFrom',
                filterOperator: 'between',
                filterDataType: "dateBetween",
                filterComponent: 'title',
                minWidth: '105px',
                maxWidth: '100%'
            },
            {
                Header: intl.formatMessage({ id: "documents.table.validTo" }),
                accessor: (rowData) => rowData.validTo !== null ? moment(rowData.validTo).format("DD.MM.YYYY") : "-",
                sortKey: 'validTo',
                filterKey: 'validTo',
                filterOperator: 'between',
                filterDataType: "dateBetween",
                filterComponent: 'title',
                minWidth: '105px',
                maxWidth: '100%'
            },
            {
                Header: intl.formatMessage({ id: "documents.table.status" }),
                accessor: (rowData) => <StatusTextField intl={intl} status={rowData.status} type={'documents'} />,
                sortKey: 'status',
                filterKey: 'status',
                filterComponent: 'select',
                filterOperator: 'eq',
                filterOptions: [
                    // {
                    //     value: "Awaiting",
                    //     label: "čaká na schválenie"
                    // },
                    {
                        value: "Approved",
                        label: "schválený"
                    },
                    {
                        value: "Rejected",
                        label: "zamietnutý"
                    }
                ],
                minWidth: '125px',
                maxWidth: '100%'
            },
            (clubDetail) ?
                {
                    Header: ' ',
                    filterKey: "actions",
                    minWidth: '82px',
                    maxWidth: '100px',
                    accessor: (rowData) =>
                        <Box className={classes.floatRight} style={{ marginTop: '0px', marginBottom: '4px' }}>
                            <Tooltip title={"Stiahnuť dokument"}><GetAppIcon className={classes.circle} onClick={() => handleDownloadDocument(rowData.downloadUrl, rowData.name)} /></Tooltip>
                            {(rowData.status === "Awaiting") && <Tooltip title={"Odstrániť dokument"}><DeleteIcon className={classes.circle} onClick={() => { setActualId(rowData.id); setDeleteModalOpen(true) }} /></Tooltip>}
                            <Tooltip title={"Otvoriť dokument"}><SearchIcon className={classes.circle} onClick={() => { setActualId(rowData.id); setActualStatus(rowData.status); console.log(rowData); handleGetFiles(rowData.id, rowData.clubId, rowData.personId) }} /></Tooltip>
                        </Box>
                }
                : { Header: " " }
        ])
    };

    return (
        <>
           <QueryTableRefresh
                data={data}
                loadData={loadData}
                filterView={false}
                permanentFilter={defaultFilter}
                //paginateView={false}
                numberOfRows={numberOfRows}
                queryDataPath={queryDataPath}
                columnsWidth={['140px', '110px', undefined, undefined, undefined, undefined, undefined, '150px', '100px']}
                columns={renderColumns()}
            />
            <Modal
                title={intl.formatMessage({ id: "documents.deleteModal.title" })}
                open={deleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                actions={renderDeleteModalButtons()}
                fullWidth
            >
                <Grid container spacing={1}>
                    {intl.formatMessage({ id: "documents.deleteModal.body" })}
                </Grid>
            </Modal>

            <DocumentViewer
                docs={files}
                open={documentsDialogModalOpen}
                onClose={() => setDocumentsDialogModalOpen(false)}
                data={viewerData}
                documentData={documentData}
                type={documentType}
                documentId={actualId}
                documentStatus={actualStatus}
                textColor={(actualStatus === "Awaiting") ? colors.warning : (actualStatus === "Approved") ? colors.success : colors.error}
                refetch={refetch}
            />
        </>
    )
}

const mapStateToProps = (state) => ({
    role: state.role,
});

const mapDispatchToProps = (dispatch) => ({
    changeDocumentsNumberAction: (value) => dispatch(changeDocumentsNumberAction(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DocumentTable));