
import React from "react";

import { pathOr } from 'rambda';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme) => ({
  inputLabel: {
    display: "flex",
    flexDirection: "row",
    // right: (props) => props.align !== "right" ? "16px" : 0,
    // left: (props) => props.align === "right" ? "-5px" : "unset",
    justifyContent: "space-between",
    
    // width: "100%",
  },
  thBox: {
    padding: theme.spacing(1.5),
    paddingRight: "10px",
    position: "relative",
    textAlign: "left",
    background: "transparent",
    color: "#E1ECFA",
    fontSize: "0.8rem",
    wordWrap: "unset",
    // height: "100%",
    paddingLeft: theme.spacing(0.5),
  },
  filterIcon: {
    paddingLeft: 0,
    paddingRight: "10px",
    color: "#8A9CB3",
    "&:hover": {
      opacity: 1,
      cursor: "pointer",
    },
  },
  calendarIcon: {
    cursor: "pointer",
    padding: 0,
    color: "#8A9CB3",
  },
  iconBox: {
    height: "inherit",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: theme.spacing(0.3),
    opacity: 0.7,
    "&:hover": {
      opacity: 1,
      cursor: "pointer",
    },
  },

}));


const TitleDatum = ({ 
  inputLabel,
  filterKey,
  filterOperator,
  filterDataType,
  filters,

  onOpenDatePicker,
  handleFilterChange,
}) => {

  const classes = useStyles();

  return (
    <div className={classes.inputLabel}>
      <div className={classes.thBox}>
        {inputLabel}
      </div>

      { pathOr('', [filterKey, filterOperator], filters) && 
        <IconButton className={classes.filterIcon} edge="end" 
          onClick={() => (
            handleFilterChange(filterKey, null, null, filterDataType, filterOperator)
          )}
        >
          <ClearIcon>clear_icon_component</ClearIcon>
        </IconButton>
      }

      <div className={classes.iconBox}>
        <IconButton 
          onClick={() => (
            onOpenDatePicker(filterKey)
          )} 
          className={classes.calendarIcon}
        >
          <CalendarTodayIcon />
        </IconButton>
      </div>
    </div>
  )
};

export default TitleDatum;