import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import LANGUAGE_CHANGE from 'queries/ProfileQueries/languageChange';
import CHANGE_PASSWORD from 'queries/ProfileQueries/changePassword';
import Grid from '@material-ui/core/Grid';
import PersonalDataCard from '../Profile/DetailCards/PersonalDataCard';
import DocumentsCard from '../Clubs/ClubCards/DocumentsCard';
import RoleEnum from 'common/enums/RoleEnum';
import { useSnackbar } from 'notistack';
import { pathOr } from 'rambda';
import { injectIntl } from 'react-intl';
import PageHeader from 'components-lib/PageHeader/PageHeader';
import IconCard from 'components-lib/IconCard/IconCard';
import LanguageIcon from '@material-ui/icons/Language';
import CustomSelect from 'components-lib/CustomSelect/CustomSelect';
import { IntlContext } from "index";
import { setPreferedLanguage } from 'helpers/helpers';
import Loading from "components-lib/Loading/Loading";
import { Formik } from "formik";
import Button from "components/CustomButtons/Button.js";
import Typography from "@material-ui/core/Typography"
import { colors } from 'common/styles/configLayout';
import styles from "common/styles/widgets.js";
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import LicenseNewTable from './LicenseCards/LicenseNewTable';
import LicenseNewCardTable from './LicenseCards/LicenseNewCardTable';
import LicensePaymentTable from './LicenseCards/LicensePaymentTable';
import CLUB_MEMBERS_BY_CLUB from "queries/MembersQueries/clubMembersByClub";
import CLUB_NAME from "queries/LicensesQueries/clubName";
import MEMBER_LICENSE_OPTIONS from "queries/LicensesQueries/memberLicenseOptions";
import MEMBER_LICENSE_REQUEST_CREATE from "queries/LicensesQueries/memberLicenseRequestCreate";
import CheckIcon from '@material-ui/icons/Check';
import moment from "moment";
import { generatePath } from "react-router-dom";
import paths from "paths";
import { connect } from 'react-redux';
import Modal from "components-lib/Modal/Modal";
import { getApolloClient } from "configFiles/apollo";
import { changeLicensesNumberAction } from 'redux/actions';
import MEMBER_LICENSE_REQUESTS_TABLE from 'queries/LicensesQueries/memberLicenseRequestsTable';


const useStyles = makeStyles((theme) => styles(theme))


const LicenseNewPage = (props) => {
  const { intl, history, role, changeLicensesNumberAction } = props;
  const classes = useStyles();
  let location = useLocation();
  let membersIds = location.state?.membersIds ? location?.state?.membersIds : null
  const search = useLocation().search;

  const [clubId, setClubId] = useState(undefined)
  const [year, setYear] = useState(undefined)

  useEffect(() => {
    new URLSearchParams(search).get('clubId') && localStorage.setItem("clubId", new URLSearchParams(search).get('clubId'))
    new URLSearchParams(search).get('year') && localStorage.setItem("year", new URLSearchParams(search).get('year'))
  }, [])

  useEffect(() => {
    setClubId(localStorage.getItem("clubId"))
    setYear(localStorage.getItem("year"))
  }, [])

  const { data: dataClub } = useQuery(CLUB_NAME, { variables: { id: new URLSearchParams(search).get('clubId') ? new URLSearchParams(search).get('clubId') : localStorage.getItem("clubId") } });

  const [disableButton, setDisableButton] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = useState([{}]);
  const { data: clubMembersByClubData, loading: clubMembersByClubLoading } = useQuery(CLUB_MEMBERS_BY_CLUB, { variables: { clubId: clubId }, skip: !clubId });
  const { data: clubMembersAutofillData, loading: clubMembersAutofillLoading } = useQuery(CLUB_MEMBERS_BY_CLUB, {
    variables: {
      clubId: clubId,
      filter: {
        id: {
          in: membersIds
        }
      },
    }, skip: !membersIds || !clubId
  });

  const [memberLicenseRequestCreate] = useMutation(MEMBER_LICENSE_REQUEST_CREATE)
  const client = getApolloClient(enqueueSnackbar, history, intl);

  const [functions, setFunctions] = useState([]);
  const [initialFunctions, setInitialFunctions] = useState([]);
  const [clubMembers, setClubMembers] = useState([]);
  const [licenseSummary, setLicenseSummary] = useState([]);
  const [licenseFinalRows, setLicenseFinalRows] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  const getLicensesNumber = async () => {
    try {
      const result = await client.query({
        query: MEMBER_LICENSE_REQUESTS_TABLE,
        variables: {
          "filter": [
            {
              "status": {
                "in": (role === RoleEnum.ADMIN) ? ["Ready", "Downloaded"] : ["Pending"]
              }
            }
          ]
        }
      });
      if (result.error) {
        throw result.error;
      }
      if (result.errors) {
        throw result.errors[0];
      }
      changeLicensesNumberAction(pathOr('', ['data', 'memberLicenseRequestsTable', 'totalCount'], result));
    } catch (error) {
      throw error;
    }
  }

  const getDisciplines = (disciplines) => {
    let arr = []
    let arrPara = []
    arr = (disciplines).filter(d => d?.id !== '1')?.map(disc => {
      return {
        value: disc.id,
        label: disc?.name,
        categories: (disc?.categories)?.map(cat => {
          return {
            value: cat.id,
            label: cat?.name,
            disabled: cat?.missingRequirements,
          }
        })
      }
    })
    arrPara = (disciplines).find(d => d?.id !== '1') && [(disciplines).filter(d => d?.id === '1')?.map(disc => {
      return {
        value: disc.id,
        label: disc?.name,
        categories: (disc?.categories)?.map(cat => {
          return {
            value: cat.id,
            label: cat?.name,
            disabled: cat?.missingRequirements,
          }
        })
      }
    })]
    return (disciplines?.length > 0) ? [...arr, ...arrPara?.[0]] : [];
  }

  const getFunctions = async () => {
    try {
      const result = await client.query({
        query: MEMBER_LICENSE_OPTIONS,
        variables: {
          input: {
            year: year,
            clubId: clubId,
            memberIds: membersIds
          }
        }
      });
      if (result.error) {
        throw result.error;
      }
      if (result.errors) {
        throw result.errors[0];
      }
      let selectCategories = []
      let selectFunctions = (result?.data?.memberLicenseOptions)?.map((member => {
        return {
          value: member?.member?.id,
          birth: (member?.member?.person?.profile?.birth) ? (member?.member?.person?.profile?.birth) : undefined,
          uciId: (member?.member?.uciId) ? (member?.member?.uciId) : undefined,
          disabled: member?.missingRequirements,
          functions: (member?.functions)?.map((func) => {
            return {
              value: func.id,
              label: func?.name,
              disabled: func?.missingRequirements,
              disciplines: getDisciplines(func?.disciplines)
            }
          })
        }
      }))

      const row = [...functions];
      row[functions?.length] = {
        ...selectFunctions?.[0]
      };
      //setDisabledFunctions([...disabledFunctions, { functionIds: [], categoryIds: [] }])
      if (selectFunctions?.length > 0)
        setInitialFunctions(selectFunctions);
    } catch (error) {
      throw error;
    }
  }

  const getDisabled = (photo, suspended) => {
    if (suspended)
      return "suspended"
    else {
      if (!photo)
        return "withoutPhoto"
      else
        return false
    }
  }

  useEffect(() => {
    let clubMembersSelectItems = pathOr([], "clubMembersByClub.items", clubMembersAutofillData).map(
      (item) => {
        return {
          clubMember: {
            value: item.id,
            label: `${item.person.profile.firstName} ${item.person.profile.lastName} (${(item?.person?.profile?.birth) ? moment(item?.person?.profile?.birth).format("DD.MM.YYYY") : 'Neuvedené'})`,
            disabled: getDisabled(item?.person?.profile?.photoUrl, item?.isSuspended)
          },
          birth: (item?.person?.profile?.birth) ? moment(item?.person?.profile?.birth).format("DD.MM.YYYY") : undefined,
          uciId: item?.uciId,
          functions: [{ value: "", label: "" }]
        };
      }
    );

    if (clubMembersSelectItems.length < 1)
      setRows([])
    else {
      setRows(clubMembersSelectItems);
      setFunctions(getFunctions())
    }
  }, [clubMembersAutofillData]);

  useEffect(() => {
    let clubMembersByClubSelectItems = pathOr([], "clubMembersByClub.items", clubMembersByClubData).map(
      (item) => {
        return {
          value: item.id,
          label: `${item.person.profile.firstName} ${item.person.profile.lastName} (${(item?.person?.profile?.birth) ? moment(item?.person?.profile?.birth).format("DD.MM.YYYY") : 'Neuvedené'})`,
          disabled: getDisabled(item?.person?.profile?.photoUrl, item?.isSuspended)
        };
      });
    setClubMembers(clubMembersByClubSelectItems);
    console.log('clubMembersByClubData', clubMembersByClubData)

  }, [clubMembersByClubData]);

  const renderModalButtons = () => {
    return (
      <>
        <Button
          disabled={false}
          onClick={() => handleMemberLicenseRequestCreate()}
          color="primary"
          round
          table
          size="sm">
          <CheckIcon /> Odoslať žiadosť
        </Button>
      </>
    );
  };

  const handleMemberLicenseRequestCreate = (values) => {
    //let licenses = getLicenses();
    if (licenseFinalRows.length > 0) {
      setDisableButton(true);
      memberLicenseRequestCreate({
        variables: {
          input: {
            clubId: clubId,
            year: year,
            licenses: licenseFinalRows,
          }
        }
      }).then((response) => {
        enqueueSnackbar("Žiadosť o licencie členov bola odoslaná", { variant: 'success' });
        getLicensesNumber();
        history.push(`/admin${generatePath(paths.licenses.list)}`,);
        setDisableButton(false);
      }).catch((err) => {
        console.log('[Error]: ', err);
        setDisableButton(false);
      });
    } else {
      enqueueSnackbar("Žiadosť neobsahuje žiadne záznamy", { variant: 'warning' });
    }
  }

  const getLicenseCreateProblem = () => {
    let secProblem = [];
    let problem = [];
    let thirdProblem = [];
    problem = rows?.map(item => {
      if (item?.clubMember?.disabled) return problem.push("ClubMemberProblem");
      secProblem = item?.functions?.map(func => {
        if (func?.disabled?.length > 0) return problem.push("FunctionsProblem");
        if (func?.value === '') return problem.push("ClubMemberProblem");
      })
    })
    if (problem.find(item => item) || secProblem?.find(item => item))
      return true
    else
      return false
  }

  const handleBackAction = () => {
    history.goBack();
  };

  if (clubMembersByClubLoading || clubMembersAutofillLoading) return <Loading />;
  return (<>
    <PageHeader
      title={`Nová žiadosť o licencie`}
      withBackButton={true}
      handleBackAction={handleBackAction}
      //subTitle={`Rok: ${year}`}
      subTitle={`Klub: ${dataClub?.club?.profile?.name}, Rok: ${year}`
      }
    />
    <Formik
      validateOnChange={true}
      validateOnBlur={true}
      enableReinitialize
      //validationSchema={getValidationSchema(intl)}
      onSubmit={(values) => handleMemberLicenseRequestCreate()}
      initialValues={{ country: "Slovensko" }}
    >
      {(formikProps) => (<>
        <Grid container spacing={1}>
          <Grid item xs={12} lg={9}>
            <Grid item xs={12}>
              <IconCard inside>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    { location.pathname === `/admin${generatePath(paths.licenses.new)}` && 
                      <LicenseNewTable
                        callbackRows={(newValue) => setRows(newValue)}
                        rows={rows}
                        closed={false}
                        role={role}
                        requestStatus={"Approved"}
                        functions={functions}
                        clubMembers={clubMembers}
                        setClubMembers={(newValue) => setClubMembers(newValue)}
                        //setFunctions={(newValue) => setFunctions(newValue)}
                        initialFunctions={initialFunctions}
                        clubId={clubId}
                        year={year}
                        setLicenseSummary={(newValue) => setLicenseSummary(newValue)}
                        licenseSummary={licenseSummary}
                        setLicenseFinalRows={(newValue) => setLicenseFinalRows(newValue)}
                      />
                    }
                    { location.pathname === `/admin${generatePath(paths.licenses.newCard)}` && 
                      <LicenseNewCardTable
                        callbackRows={(newValue) => setRows(newValue)}
                        rows={rows}
                        closed={false}
                        role={role}
                        requestStatus={"Approved"}
                        functions={functions}
                        clubMembers={clubMembers}
                        setClubMembers={(newValue) => setClubMembers(newValue)}
                        //setFunctions={(newValue) => setFunctions(newValue)}
                        initialFunctions={initialFunctions}
                        clubId={clubId}
                        year={year}
                        setLicenseSummary={(newValue) => setLicenseSummary(newValue)}
                        licenseSummary={licenseSummary}
                        setLicenseFinalRows={(newValue) => setLicenseFinalRows(newValue)}
                      />
                    }
                  </Grid>
                  {clubMembers.length > 0 && <>
                    <Grid item xs={12} align="right">
                      {rows?.length > 0 && <>
                        <br />
                        <Button
                          disabled={getLicenseCreateProblem() || licenseFinalRows?.length < 1 || disableButton}
                          align="center"
                          color="primary"
                          size="sm"
                          round
                          onClick={() => { formikProps.handleSubmit() }}
                        //onClick={() => { history.push(`/auth${paths.auth.login}`) }}
                        >
                          <CheckIcon /> Odoslať žiadosť
                        </Button>
                      </>
                      }
                    </Grid>
                  </>
                  }
                  <Grid item xs={12} align="right">
                    {getLicenseCreateProblem() &&
                      <Typography variant="body2" style={{ color: colors.warning, fontSize: "0.8em" }}>
                        * Prosím odstránťe zo zoznamu osoby ktoré nespĺňajú podmienky na vystavenie licencie
                      </Typography>
                    }
                  </Grid>

                </Grid>
              </IconCard>

            </Grid>
          </Grid>
          <Grid item xs={12} lg={3}>
            <IconCard title={"Licenčné poplatky"} inside>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <LicensePaymentTable
                    licenseSummary={licenseSummary}
                  />
                </Grid>
              </Grid>
            </IconCard>
          </Grid>
        </Grid>
      </>)
      }
    </Formik >

    <Modal
      title={"Žiadosti o licencie"}
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      actions={renderModalButtons()}
      fullWidth
    >
      Naozaj chcete odoslať žiadosť na vydanie licencií?
    </Modal>
  </>
  );
};

const mapStateToProps = (state) => ({
  role: state.role,
});

const mapDispatchToProps = (dispatch) => ({
  changeLicensesNumberAction: (value) => dispatch(changeLicensesNumberAction(value)),
});


export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(LicenseNewPage));

//430