import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import PROFILE from 'queries/ProfileQueries/profile';
import Grid from '@material-ui/core/Grid';
import PersonalDataCard from './DetailCards/PersonalDataCard';
import DocumentsCard from './DetailCards/DocumentsCard';
import { pathOr } from 'rambda';
import { injectIntl } from 'react-intl';
import IconCard from 'components-lib/IconCard/IconCard';
import Loading from "components-lib/Loading/Loading";
import Typography from "@material-ui/core/Typography"
import AvatarCard from './DetailCards/AvatarCard';
import ClubCard from './DetailCards/ClubCard';
import ChangePasswordCard from './DetailCards/ChangePasswordCard';
import styles from "common/styles/widgets.js";
import { makeStyles } from '@material-ui/core/styles';
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import DescriptionIcon from '@material-ui/icons/Description';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { AntTabs, AntTab } from 'common/styles/muiDesign';
import PageHeader from "components-lib/PageHeader/PageHeader";
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import LicenseCard from './DetailCards/LicenseCard'
import PROFILE_UPDATE from 'queries/ProfileQueries/profileUpdate'
import { Formik } from "formik";
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { getValidationSchemaMemberDetail, getValidationSchemaMemberDetailForeign } from "../Members/validationSchema";
import { connect } from 'react-redux';
import InfoIcon from '@material-ui/icons/Info';
import { changeUserProfileAction } from 'redux/actions';
import { getApolloClient } from "configFiles/apollo";
import RoleEnum from 'common/enums/RoleEnum';
import { variables } from 'common/styles/configLayout';
import LICENSING_INFO from "queries/GenerallyQueries/licensingInfo";

const useStyles = makeStyles((theme) => styles(theme))

const ProfilePage = (props) => {
    const { intl, history, role, changeUserProfileAction } = props;
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    var url_string = window.location.href
    var url = new URL(url_string);
    var tabNumber = Number(url.searchParams.get("tab"));
    const client = getApolloClient(enqueueSnackbar, history, intl);
    const { data: licensingInfoData } = useQuery(LICENSING_INFO);

    const [tab, setTab] = useState(0);
    const [userData, setUserData] = useState({});
    const { loading, data, refetch: refetchData } = useQuery(PROFILE);
    const [readMode, setReadMode] = useState(true)
    const [photo, setPhoto] = useState(null)
    const [validateParam, setValidateParam] = useState(false)
    const [licenses, setLicenses] = useState([]);
    const [membersCount, setMembersCount] = useState(0);
    const [memberFunctions, setMemberFunctions] = useState([]);
    const [memberDisciplines, setMemberDisciplines] = useState([]);
    const [countryOfBirth, setCountryOfBirth] = useState(null)
    const [disableButton, setDisableButton] = useState(false);

    const [profileUpdate] = useMutation(PROFILE_UPDATE);

    useEffect(() => {
        setTab(Number(tabNumber))
    }, []);

    const handleProfileUpdate = (values) => {
        setDisableButton(true)
        const result = { ...values };
        delete result.__typename;
        delete result.email;
        delete result.birth
        delete result.photoUrl;
        delete result.isYouthWorker;
        let tempDate = moment(values?.birth).format('YYYY-MM-DD');


        const getUserProfileAction = async () => {
            try {
                const result = await client.query({
                    query: PROFILE,
                });
                if (result.error) {
                    throw result.error;
                }
                if (result.errors) {
                    throw result.errors[0];
                }
                changeUserProfileAction(pathOr('', ['data'], result));
            } catch (error) {
                throw error;
            }
        };

        profileUpdate({
            variables: {
                id: values.id,
                input: {
                    ...result,
                    birth: tempDate,
                    photo: (photo) ? photo : undefined
                }
            }
        }).then((response) => {
            enqueueSnackbar(intl.formatMessage({ id: "profile.snackbar.success" }), { variant: 'success' });
            getUserProfileAction();
            setDisableButton(false);
            setReadMode(true);
            refetchData();
        }).catch((err) => {
            console.log('[Error]: ', err);
            setDisableButton(false);
        });
    }

    useEffect(() => {
        setUserData(pathOr('', ['me', 'person', 'profile'], data));
        setLicenses(pathOr('', ['me', 'member', 'licenses'], data));
        setMembersCount(Object.values(pathOr('', ['me', 'member', 'club', 'members'], data))?.map((x, index) => { return index })?.length);
    }, [data]);

    const handleChangeTab = (event, newValue) => {
        setTab(newValue);
    };
    if (loading) return <Loading />;
    return (<Formik
        onSubmit={(values) => handleProfileUpdate(values)}
        initialValues={{ ...userData, isYouthWorker: pathOr('', ['me', 'member', 'isYouthWorker'], data) }}
        validationSchema={countryOfBirth === variables.defaultCountry
            ? getValidationSchemaMemberDetail(intl)
            : getValidationSchemaMemberDetailForeign(intl)
        }
        enableReinitialize
    >
        {(formikProps) => (
            <>
                {setCountryOfBirth(formikProps?.values?.countryOfBirth)}
                <PageHeader
                    headerWidth= "100%"
                    withBackButton={false}
                    tabs={
                        <AntTabs
                            onChange={handleChangeTab}
                            value={tab}
                            aria-label="Tabs where each tab needs to be selected manually"
                            variant="scrollable"
                        >
                            <AntTab label={<Typography variant="body1" ><InfoIcon className={classes.tabsIcon} />{intl.formatMessage({ id: "profile.tab.personalInformation" })}</Typography>} />
                            {(role === RoleEnum.STATUTORY || role === RoleEnum.PRESIDENT || role === RoleEnum.MEMBER)
                                ? <AntTab label={<Typography variant="body1" ><DirectionsBikeIcon className={classes.tabsIcon} />{intl.formatMessage({ id: "profile.tab.club" })}</Typography>} />
                                : <AntTab label={<Typography variant="body1" ><VpnKeyIcon className={classes.tabsIcon} />{intl.formatMessage({ id: "profile.tab.changePassword" })}</Typography>} />}
                            {(role === RoleEnum.STATUTORY || role === RoleEnum.PRESIDENT || role === RoleEnum.MEMBER) && <AntTab label={<Typography variant="body1" ><DescriptionIcon className={classes.tabsIcon} /> {intl.formatMessage({ id: "profile.tab.documents" })}</Typography>} />}
                            {(role === RoleEnum.STATUTORY || role === RoleEnum.PRESIDENT || role === RoleEnum.MEMBER) && <AntTab label={<Typography variant="body1" ><AssignmentIndIcon className={classes.tabsIcon} />{intl.formatMessage({ id: "profile.tab.licenses" })}</Typography>} />}
                            {(role !== RoleEnum.ADMIN && role !== RoleEnum.USER) && <AntTab label={<Typography variant="body1" ><VpnKeyIcon className={classes.tabsIcon} />{intl.formatMessage({ id: "profile.tab.changePassword" })}</Typography>} />}
                        </AntTabs>

                    }
                />
                <Grid container spacing={1}>
                    {tab === 0 &&
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={4} lg={(pathOr('', ['me', 'person', 'pendingProfileRequest', 'data', 'profile', 'photoUrl'], data) && pathOr('', ['me', 'person', 'profile', 'photoUrl'], data)) ? 4 : 2}>
                                <AvatarCard
                                    formikProps={formikProps}
                                    photo={pathOr('', ['me', 'person', 'profile', 'photoUrl'], data)}
                                    photoRequest={pathOr('', ['me', 'person', 'pendingProfileRequest', 'data', 'profile', 'photoUrl'], data)}
                                    requestId={pathOr('', ['me', 'person', 'pendingProfileRequest', 'id'], data)}
                                    setPhoto={(newValue) => setPhoto(newValue)}
                                    refetch={refetchData}
                                    history={history}
                                />
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <IconCard>
                                    <PersonalDataCard
                                        formikProps={formikProps}
                                        readMode={readMode}
                                        setReadMode={(newValue) => setReadMode(newValue)}
                                        validateParam={validateParam}
                                        setValidateParam={(newValue) => setValidateParam(newValue)}
                                        identifierData={pathOr('', ['me'], data)}
                                        isMyProfile={true}
                                        disableButton={disableButton}
                                    />
                                </IconCard>
                            </Grid>

                        </Grid>
                    }
                    {tab === 1 && <>
                        {(role !== RoleEnum.ADMIN && role !== RoleEnum.USER) ?
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={12}>
                                    <ClubCard
                                        hosting={pathOr('', ['me', 'member', 'hosting', 'club', 'profile', 'name'], data)}
                                        userData={pathOr('', ['me', 'member', 'club'], data)}
                                        functionData={pathOr('', ['me', 'member', 'functions'], data)}
                                        isSuspended={pathOr('', ['me', 'member', 'isSuspended'], data)}
                                        federationId={pathOr('', ['me', 'member', 'federationId'], data)}
                                        setMemberFunctions={(newValue) => setMemberFunctions(newValue)}
                                        setMemberDisciplines={(newValue) => setMemberDisciplines(newValue)}
                                        memberFunctions={memberFunctions}
                                        memberDisciplines={memberDisciplines}
                                        formikProps={formikProps}
                                        membersCount={membersCount}
                                        readMode={readMode}
                                        setReadMode={(newValue) => setReadMode(newValue)}
                                        profile={true}
                                        history={history}
                                        disableButton={disableButton}
                                    />
                                </Grid>
                            </Grid>
                            :
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={4}>
                                    <ChangePasswordCard formikProps={formikProps} />
                                </Grid>
                            </Grid>
                        }
                    </>
                    }
                    {tab === 2 &&
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={10} xl={8}>
                                <DocumentsCard formikProps={formikProps} />
                            </Grid>
                        </Grid>
                    }
                    {tab === 3 &&
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={10} xl={8}>
                                <LicenseCard
                                    formikProps={formikProps}
                                    licenses={licenses}
                                    currentYear={licensingInfoData?.licensingInfo?.periods?.current?.year}
                                    role={role}
                                />
                            </Grid>
                        </Grid>
                    }
                    {tab === 4 &&
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={4}>
                                <ChangePasswordCard formikProps={formikProps} />
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </>
        )
        }
    </Formik >
    );
};

const mapStateToProps = (state) => ({
    role: state.role,
});

const mapDispatchToProps = (dispatch) => ({
    changeUserProfileAction: (value) => dispatch(changeUserProfileAction(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ProfilePage));
