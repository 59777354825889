import { makeStyles } from '@material-ui/core/styles';
import QueryTableRefresh from 'components-lib/QueryTable/QueryTableRefresh';
import Button from "components/CustomButtons/Button.js";
import paths from 'paths';
import React from 'react';
import { injectIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';
import styles from "common/styles/widgets.js";
import { clubDetail } from 'configFiles/roles'
import Box from "@material-ui/core/Box";
import CheckIcon from '@material-ui/icons/Check';
import GetAppIcon from '@material-ui/icons/GetApp';
import moment from "moment";
import { widgets, colors, } from 'common/styles/configLayout';
import MEMBER_LICENSE_REQUEST_DOWNLOAD from 'queries/LicensesQueries/memberLicenseRequestDownload'
import MEMBER_LICENSE_REQUEST_CONFIRM from 'queries/LicensesQueries/memberLicenseRequestConfirm'
import { useQuery, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { connect } from 'react-redux';
import { changeLicensesNumberAction } from 'redux/actions';
import MEMBER_LICENSE_REQUESTS_TABLE from 'queries/LicensesQueries/memberLicenseRequestsTable';
import { getApolloClient } from "configFiles/apollo";
import Tooltip from '@material-ui/core/Tooltip';
import StatusTextField from "components-lib/StatusTextField/StatusTextField";
import RoleEnum from "common/enums/RoleEnum";
import { pathOr } from 'rambda';

const useStyles = makeStyles((theme) => styles(theme))

export const PriceListTable = (props) => {
    const { history, queryVariables, role, data, loadData, refetch, intl, defaultFilter = null, numberOfRows, queryDataPath, changeLicensesNumberAction, currentYear } = props
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [memberLicenseRequestDownload] = useMutation(MEMBER_LICENSE_REQUEST_DOWNLOAD)
    const [memberLicenseRequestConfirm] = useMutation(MEMBER_LICENSE_REQUEST_CONFIRM)
    const client = getApolloClient(enqueueSnackbar, history, intl);

    const getLicensesNumber = async () => {
        try {
          const result = await client.query({
            query: MEMBER_LICENSE_REQUESTS_TABLE,
            variables: {
              "filter": [
                {
                  "status": {
                    "in": (role === RoleEnum.ADMIN) ? ["Ready", "Downloaded"] : ["Pending"]
                  }
                }
              ]
            }
          });
          if (result.error) {
            throw result.error;
          }
          if (result.errors) {
            throw result.errors[0];
          }
          changeLicensesNumberAction(pathOr('', ['data', 'memberLicenseRequestsTable', 'totalCount'], result));
        } catch (error) {
          throw error;
        }
      }

    const onLicenseDetail = (id) => {
        history.push(`/admin${generatePath(paths.licenses.detail, { licenseId: id })}`);
    }

    function downloadURI(url) {
        var link = document.createElement("a");
        link.download = url;
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const handleConfirmLicense = (id) => {
        memberLicenseRequestConfirm({
            variables: {
                id: id
            }
        }).then((response) => {
            enqueueSnackbar("Žiadosť o licencie bola potvrdená", { variant: 'success' });
            refetch();
            getLicensesNumber();
        }).catch((err) => {
            console.log('[Error]: ', err);
        });
    }

    const handleDownloadLicense = (id) => {
        memberLicenseRequestDownload({
            variables: {
                id: id
            }
        }).then((response) => {
            enqueueSnackbar("Žiadosť o licencie vo formáte PDF bola stiahnutá", { variant: 'success' });
            refetch();
            //window.location.open(response?.data?.memberLicenseRequestDownload?.url)
            window.open(response?.data?.memberLicenseRequestDownload?.url)
            //downloadURI(response?.data?.memberLicenseRequestDownload?.url);
        }).catch((err) => {
            console.log('[Error]: ', err);
        });
    }

    const getStatus = (status) => {
        if (status === "Pending") return <Box style={{ color: colors.warning }} >čaká na platbu</Box>
        else if (status === "Paid") return <Box>zaplatená</Box>
        else if (status === "Ready") return <Box style={{ color: colors.warning }}>čaká na výrobu</Box>
        else if (status === "Downloaded") return <Box>stiahnuté PDF</Box>
        else if (status === "Completed") return <Box style={{ color: colors.success }}>dokončená</Box>
        else if (status === "Canceled") return <Box style={{ color: colors.error }}>zrušená</Box>
        else return <Box>{status}</Box>
    }

    return (
        <QueryTableRefresh
            data={data}
            loadData={loadData}
            numberOfRows={numberOfRows}
            permanentFilter={defaultFilter}
            queryDataPath={queryDataPath}
            queryVariables={queryVariables}
            columnsWidth={['150px', undefined, undefined, undefined, '130px', undefined, '160px', '150px']}
            columns={[
                {
                    Header: intl.formatMessage({ id: 'table.bid' }),
                    accessor: 'bid',
                    sortKey: 'bid',
                    filterKey: 'bid',
                    filterOperator: 'contains',
                    minWidth: '100px',
                    maxWidth: '100%'
                },
                {
                    Header: "Podané",
                    accessor: (rowData) => rowData.createdAt !== null ? moment(rowData.createdAt).format("DD.MM.YYYY") : "-",
                    sortKey: 'createdAt',
                    filterKey: 'createdAt',
                    filterOperator: 'between',
                    filterDataType: "dateBetween",
                    filterComponent: 'title',
                    minWidth: '100px',
                    maxWidth: '100%'
                },
                {
                    Header: "Klub",
                    accessor: "club",
                    sortKey: 'club',
                    filterKey: 'club',
                    filterOperator: 'contains',
                    minWidth: '100px',
                    maxWidth: '100%'
                },
                {
                    Header: "Žiadateľ",
                    accessor: "createdBy",
                    sortKey: "createdBy",
                    filterKey: 'createdBy',
                    filterOperator: 'contains',
                    minWidth: '100px',
                    maxWidth: '100%'
                },
                {
                    Header: "Licencií",
                    accessor: "licenses",
                    sortKey: "licenses",
                    filterKey: 'licenses',
                    filterOperator: 'eq',
                    filterDataType: 'number',
                    minWidth: '100px',
                    maxWidth: '100%'
                },
                {
                    Header: "Ročník",
                    accessor: (rowData) => (rowData.year)
                        ? <Box style={{ color: (rowData.year >= currentYear) ? colors.success : '' }} > {rowData.year} </Box>
                        : "-",
                    sortKey: "year",
                    filterKey: "year",
                    filterOperator: 'eq',
                    filterDataType: 'number',
                    minWidth: '96px',
                    maxWidth: '100%'
                },
                {
                    Header: 'Stav',
                    accessor: (rowData) => <StatusTextField intl={intl} status={rowData.status} />,
                    sortKey: 'status',
                    filterKey: 'status',
                    filterComponent: 'select',
                    filterOperator: 'eq',
                    filterOptions: [
                        {
                            value: "Pending",
                            label: "čaká na platbu"
                        },
                        {
                            value: "Ready",
                            label: "čaká na výrobu"
                        },
                        {
                            value: "Downloaded",
                            label: "stiahnuté PDF"
                        },
                        {
                            value: "Completed",
                            label: "dokončená"
                        },
                        {
                            value: "Canceled",
                            label: "zrušená"
                        }
                    ],
                    minWidth: '110px',
                    maxWidth: '100%'
                },
                {
                    Header: ' ',
                    filterKey: "actions",
                    accessor: (rowData) =>
                        <Box className={classes.floatRight}>
                            {role === RoleEnum.ADMIN && <>
                                {(rowData.status === "Downloaded") && <Tooltip title={"Označiť ako vytlačené"}><CheckIcon className={classes.circle} onClick={() => handleConfirmLicense(rowData.id)} /></Tooltip>}
                                {(rowData.status === "Ready" || rowData.status === "Downloaded" || rowData.status === "Completed" || rowData.status === "PartialReady") && <Tooltip title={"Stiahnuť PDF na tlač"}><GetAppIcon className={classes.circle} onClick={() => handleDownloadLicense(rowData.id)} /></Tooltip>}
                            </>}
                            <Button round size="sm" color="info" table onClick={() => onLicenseDetail(rowData.id)}>Detail</Button>
                        </Box>,
                    minWidth: '135px',
                    maxWidth: '150px'
                }
            ]}
        />
    )
}

const mapStateToProps = (state) => ({
    role: state.role,
});


const mapDispatchToProps = (dispatch) => ({
    changeLicensesNumberAction: (value) => dispatch(changeLicensesNumberAction(value)),
});


export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PriceListTable));
