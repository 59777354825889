import {
  startOfWeek,
  startOfMonth,
  endOfWeek,
  endOfMonth,
  isBefore,
  addDays,
  isSameDay,
  isWithinInterval,
  toDate,
  parseISO,
  isValid,
  isSameMonth,
  addMonths,
  parse,
  min,
  max,
} from 'date-fns';

export const identity = (x) => x;

export const chunks = (array, size) => {
  return Array.from({ length: Math.ceil(array.length / size) }, (v, i) => array.slice(i * size, i * size + size));
};

export const combine = (...args) => args.filter(identity).join(' ');

// Date
export const getDaysInMonth = (date) => {
  const startWeek = startOfWeek(startOfMonth(date));
  const endWeek = endOfWeek(endOfMonth(date));
  const days = [];
  for (let curr = startWeek; isBefore(curr, endWeek); ) {
    days.push(curr);
    curr = addDays(curr, 1);
  }
  return days;
};

export const isStartOfRange = ({ startDate }, day) => startDate && isSameDay(day, startDate);

export const isEndOfRange = ({ endDate }, day) => endDate && isSameDay(day, endDate);

export const inDateRange = ({ startDate, endDate }, day) =>
  startDate &&
  endDate &&
  (isWithinInterval(day, {
    start: startDate,
    end: endDate,
  }) ||
    isSameDay(day, startDate) ||
    isSameDay(day, endDate));

export const isRangeSameDay = ({ startDate, endDate }) => {
  if (startDate && endDate) {
    return isSameDay(startDate, endDate);
  }
  return false;
};

export const parseOptionalDate = (date, defaultValue) => {
  if (date instanceof Date) {
    const parsed = toDate(date);
    if (isValid(parsed)) return parsed;
  }

  if (typeof date === 'string') {
    const parsed = parseISO(date);
    if (isValid(parsed)) return parsed;
  }

  return defaultValue;
};

export const getValidatedMonths = (range, minDate, maxDate) => {
  const { startDate, endDate } = range;
  if (startDate && endDate) {
    const newStart = max([startDate, minDate]);
    const newEnd = min([endDate, maxDate]);

    return [newStart, isSameMonth(newStart, newEnd) ? addMonths(newStart, 1) : newEnd];
  }
  return [startDate, endDate];
};

export const FormatDateForInput = (date, format = 'mm/dd/yyyy') => {
  // Date -> mm/dd/yyyy
  if (date) {
    const dd = date.getDate();
    const mm = date.getMonth() + 1;
    return `${(mm < 10 ? '0' : '') + mm}/${(dd < 10 ? '0' : '') + dd}/${date.getFullYear()}`;
  }
  return '';
};

export const maskDateFormatter = (value, mask, maskInputChar = '_') => {
  const acceptRegexp = /[\d]/gi;
  return value
    .split('')
    .map((char, i) => {
      acceptRegexp.lastIndex = 0;

      if (i > mask.length - 1) {
        return '';
      }

      const maskChar = mask[i];
      const nextMaskChar = mask[i + 1];

      const acceptedChar = acceptRegexp.test(char) ? char : '';
      const formattedChar = maskChar === maskInputChar ? acceptedChar : maskChar + acceptedChar;

      if (i === value.length - 1 && nextMaskChar && nextMaskChar !== maskInputChar) {
        // add / after num input
        return formattedChar ? formattedChar + nextMaskChar : '';
      }

      return formattedChar;
    })
    .join('');
};
