import gql from 'graphql-tag';

const PROFILE = gql`
query user{
  me{
    isAdmin
		person {
      id
      bid
      profile {
        email
        firstName
        lastName
        countryOfBirth
        preNominals
        postNominals
        gender
        birth
        phone
        personalId
        address1
        address2
        postal
        city
        country
        district
        emergencyFirstName
        emergencyLastName
        emergencyPhone
        photoUrl
        nationality
        gender
      }
      pendingProfileRequest{
        id
        data{
          ... on PersonProfileUpdateRequest {
            profile{
              photoUrl
            }
          }
        }
      }
    }
    member {
      id
      isYouthWorker
      hosting{
        club{
          profile{
            name
          }
        }
      }
      person{
      id
      bid
      profile{
        email
        countryOfBirth
        preNominals
        postNominals
        firstName
        lastName
        gender
        birth
        nationality
        phone
        personalId
        address1
        address2
        postal
        city
        country
        district
        emergencyFirstName
        emergencyLastName
        emergencyPhone
        photoUrl
      }
    }
    isYouthWorker
    functions{
      function{
        id
        name
        ISSName
        UCIRole
        UCIFunction
        isCompetitionFunction
        isLicensedFunction
        requiredDocuments
      }
      disciplines{
        id
        name
      }
    }
    club{
      id
      bid
      type
      chairman{
        id
        bid
        person{
          profile{
            firstName
            lastName
            phone
            email
          }
        }
      }
      statutory{
        id
        bid
        person{
          profile{
            firstName
            lastName
            phone
            email
          }
        }
      }
      profile{
        name
        logoUrl
        businessName
        address1
        address2
        postal
        city
        district
        country
      }
      licenses {
        current {
          isLicensed
        }
        next {
          isLicensed
        }
      }
    }
    licenses {
      year
      revokedAt
      wallet {
        googleUrl
        appleUrl
      }
      frontImageUrl
      backImageUrl
      function {
        id
        name
      }
      disciplines {
        discipline {
          id
          name
        }
        category {
          id
          name
        }
      } 
    }
  }
}
}
`;

export default PROFILE;