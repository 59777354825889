import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import Button from "components/CustomButtons/Button.js";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from "@material-ui/core/styles";
import styles from './styles';
import { injectIntl } from 'react-intl';

const PageHeader = ({ headerWidth, withBackButton = true, disabled, title = '', actions = [], handleBackAction, subTitle = '', intl, tabs }) => {
    const useStyles = makeStyles(styles);
    const classes = useStyles();

    const actionsArray = (actions) ? actions.length : 0

    const baseFreeCols = withBackButton ? 11 : 12;
    const baseFreeColsLg = withBackButton ? 11 : 12;

    const actionsGridSizeXl = actionsArray;
    const titleGridSizeXl = baseFreeCols - actionsArray;

    const actionsGridSizeLg = actionsArray + 2;
    const titleGridSizeLg = baseFreeColsLg - (actionsArray + 2);

    const actionsGridSizeMd = actionsArray + 3;
    const titleGridSizeMd = baseFreeCols - (actionsArray + 3);

    return (
        <Grid container spacing={3} 
            style={{ marginBottom: "-26px", display: "flex",
                justifyContent: "space-between", 
                width: headerWidth,
            }}
        >
            {!title ? <>
                {/* {withBackButton && (
                    <Grid item lg={1} md={2} sm={2} xs={4} style={{ marginTop: "-13px", marginLeft: "-10px" }}>
                        <Button size="sm" color="facebook" round onClick={(e) => handleBackAction()}>
                            <ArrowBackIcon className={classes.backIcon} />
                            {intl.formatMessage({ id: 'pageHeader.back' })}
                        </Button>
                    </Grid>
                )} */}
                <Grid
                    style={{
                        display: 'flex', justifyContent: 'flex-end',
                        width: "auto", maxWidth: "100%",
                        marginRight: "1rem", marginTop: "1.2rem",
                        marginBottom: "12px"
                    }}
                >
                    <Grid item 
                        style={{
                            position: "relative",
                            // display: 'flex',
                            display: "inline-block",
                            // justifyContent: 'flex-end',
                            flexWrap: 'nowrap',
                            gap: '5px',
                            width: 'auto',
                            overflowX: 'scroll',
                            whiteSpace: 'nowrap',
                            scrollbarWidth: 'none',
                        }}
                    >{tabs}</Grid>
                </Grid>
                
                {actions.length > 0 && (
                    <Grid item 
                        style={{
                            display: 'flex', justifyContent: 'flex-end',
                            width: "auto", maxWidth: "100%",
                            marginRight: "1rem",
                        }}
                    >
                        <div 
                            // style={{ 
                            //     display: 'flex', justifyContent: 'flex-end', 
                            //     flexWrap: 'wrap', gap: '5px',
                            //     width: '100%',    
                            // }}
                            style={{
                                position: "relative",
                                // display: 'flex',
                                display: "inline-block",
                                // justifyContent: 'flex-end',
                                flexWrap: 'nowrap',
                                gap: '5px',
                                width: 'auto',
                                overflowX: 'scroll',
                                whiteSpace: 'nowrap',
                                scrollbarWidth: 'none',
                            }}
                        >
                            {withBackButton && (
                                <Button 
                                    size="lg" 
                                    color="info" 
                                    round 
                                    onClick={(e) => handleBackAction()}
                                    width= '9rem'
                                >
                                    <ArrowBackIcon className={classes.backIcon} />
                                    {intl.formatMessage({ id: 'pageHeader.back' })}
                                </Button>
                            )}
                            {actions.map((action, index) => action.component
                                ?
                                action.component
                                :
                                <Button
                                    disabled={action.disabled}
                                    key={index}
                                    size="sm"
                                    width= '11rem'
                                    round
                                    color={action.color || 'info'}
                                    onClick={action.onClick}
                                    style={{ flexGrow: 0, flexShrink: 1, flexBasis: 'auto' }}
                                >
                                    <span className={classes.backIcon}>{action.icon}</span>{action.title}
                                </Button>
                            )}
                        </div>
                    </Grid>
                )}
            </>
                : <>
                <Grid
                    style={{
                        display: 'flex', flexWrap: 'wrap',
                        gap: '5px', width: '100%', justifyContent: "space-between",
                        padding: "0.75rem",
                    }}
                >
                    <Grid item style={{ paddingRight: "0.5rem", width: "auto"}} >
                        <Typography variant="h5" className={classes.title} >{title}</Typography>
                        {subTitle && (
                            <Typography variant="body2" className={classes.subTitle}>{subTitle}</Typography>
                        )}
                    </Grid>
                    <Grid item
                        style={{ 
                            // paddingRight: '2rem', 
                            paddingTop: "0.2rem", marginRight: "1rem",
                            display: 'flex', justifyContent: 'flex-end',   
                            width: "auto", maxWidth: "100%",   
                        }}
                    >
                        {(actions.length === 0 && withBackButton) && (
                            // <div 
                            //     style={{
                            //         display: 'flex', justifyContent: 'flex-end', 
                            //         width: '100%',
                            //     }}
                            // >
                                <Button
                                    color="info" 
                                    round 
                                    onClick={(e) => handleBackAction()}
                                    size="sm"
                                    width= '9rem'
                                    height= "2.35rem"
                                >
                                    <ArrowBackIcon className={classes.backIcon} />
                                    {intl.formatMessage({ id: 'pageHeader.back' })}
                                </Button>
                            // </div>
                        )}
                        {actions.length > 0 && (
                            <div 
                                style={{ 
                                    // display: 'flex', justifyContent: 'flex-end', width: '100%', 
                                    position: "relative",
                                    display: "inline-block",
                                    // justifyContent: 'flex-end',
                                    flexWrap: 'nowrap',
                                    gap: '5px',
                                    width: 'auto',
                                    overflowX: 'scroll',
                                    whiteSpace: 'nowrap',
                                    scrollbarWidth: 'none',
                                }}
                            >
                                {withBackButton && (
                                    <Button 
                                        size="lg" 
                                        color="info" 
                                        round 
                                        onClick={(e) => handleBackAction()}
                                        width= '9rem'
                                    >
                                        <ArrowBackIcon className={classes.backIcon} />
                                        {intl.formatMessage({ id: 'pageHeader.back' })}
                                    </Button>
                                )}
                                {actions.map((action, index) => action.component
                                    ?
                                    action.component
                                    :
                                    <Button
                                        disabled={action.disabled}
                                        key={index}
                                        size="sm"
                                        width= '11rem'
                                        round
                                        color={action.color || 'info'}
                                        onClick={action.onClick}
                                    >
                                        <span className={classes.backIcon}>{action.icon}</span>{action.title}
                                    </Button>
                                )}
                            </div>
                        )}
                    </Grid>
                </Grid>
                <Grid item xs={12} >{tabs}</Grid>
                </>
            }
        </Grid>


    )


    /*return (
        <div className={classes.pageHeaderWrapper} style={{zIndex: 10000}}>
            <Grid container spacing={3}>
                {withBackButton && (
                    <Grid item lg={1} md={2} sm={2} xs={4} style={{marginTop: "-13px", marginLeft: "-10px"}}>
                        <Button size="sm" color="facebook" round onClick={(e) => handleBackAction()}>
                            <ArrowBackIcon className={classes.backIcon} />
                            {intl.formatMessage({ id: 'pageHeader.back' })}
                        </Button>
                    </Grid>
                )}
                <Grid
                    item
                    xs={titleGridSizeXl}
                    lg={titleGridSizeLg}
                    md={titleGridSizeMd}
                    sm={9}
                >
                    <Typography variant="h5" className={classes.title}>{title}</Typography>
                    {subTitle && (
                        <p className={classes.subTitle}>{subTitle}</p>
                    )}
                </Grid>
                {actions.length > 0 && (
                    <Grid
                        item
                        xs={actionsGridSizeXl}
                        lg={actionsGridSizeLg}
                        md={actionsGridSizeMd}
                        sm={12}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            {actions.map((action, index) => action.component
                                ?
                                action.component
                                :
                                <Button
                                    key={index}
                                    size="sm"
                                    round
                                    color={action.color || 'info'}
                                    onClick={action.onClick}
                                >
                                    <span className={classes.backIcon}>{action.icon}</span>{action.title}
                                </Button>
                            )}
                        </div>
                    </Grid>
                )}
            </Grid>
        </div>
    )*/
};

export default injectIntl(PageHeader);