import QueryTable from 'components-lib/QueryTable/QueryTable';
import React from 'react';
import { injectIntl } from 'react-intl';

export const PublicClubsTable = (props) => {
    const { queryVariables, query, intl, defaultFilter = null, queryDataPath } = props

    return (
            <QueryTable
                query={query}
                permanentFilter={defaultFilter}
                queryDataPath={queryDataPath}
                queryVariables={queryVariables}
                columns={[
                    {
                        Header: intl.formatMessage({ id: 'publicPages.clubs.table.team' }),
                        accessor: "team",
                        sortKey: "team",
                        filterKey: 'team',
                        filterOperator: 'contains',
                        minWidth: '135px',
                        maxWidth: '100%'
                    },
                    {
                        Header:  intl.formatMessage({ id: 'publicPages.clubs.table.organization' }),
                        accessor: 'organization',
                        sortKey: 'organization',
                        filterKey: 'organization',
                        filterOperator: 'contains',
                        minWidth: '140px',
                        maxWidth: '100%'
                    },

                    {
                        Header:  intl.formatMessage({ id: 'publicPages.clubs.table.ico' }),
                        accessor: "ico",
                        sortKey: "ico",
                        filterKey: 'ico',
                        filterOperator: 'contains',
                        minWidth: '85px',
                        maxWidth: '100%'
                    },
                    {
                        Header:  intl.formatMessage({ id: 'publicPages.clubs.table.dic' }),
                        accessor: "dic",
                        sortKey: "dic",
                        filterKey: "dic",
                        filterOperator: 'contains',
                        minWidth: '85px',
                        maxWidth: '100%'
                    },
                    {
                        Header:  intl.formatMessage({ id: 'publicPages.clubs.table.address' }),
                        accessor: "address",
                        sortKey: "address",
                        filterKey: "address",
                        filterOperator: 'contains',
                        minWidth: '100px',
                        maxWidth: '100%'
                    },
                    {
                        Header:  intl.formatMessage({ id: 'publicPages.clubs.table.city' }),
                        accessor: "city",
                        sortKey: "city",
                        filterKey: "city",
                        filterOperator: 'contains',
                        minWidth: '91px',
                        maxWidth: '100%'
                    },
                    {
                        Header:  intl.formatMessage({ id: 'publicPages.clubs.table.postal' }),
                        accessor: "postal",
                        sortKey: "postal",
                        filterKey: "postal",
                        filterOperator: 'contains',
                        minWidth: '81px',
                        maxWidth: '100%'
                    },
                    {
                        Header:  intl.formatMessage({ id: 'publicPages.clubs.table.statutory' }),
                        accessor: "statutory",
                        sortKey: "statutory",
                        filterKey: "statutory",
                        filterOperator: 'contains',
                        minWidth: '100px',
                        maxWidth: '100%',
                    },
                    {
                        Header:  intl.formatMessage({ id: 'publicPages.clubs.table.chairman' }),
                        accessor: "chairman",
                        sortKey: "chairman",
                        filterKey: "chairman",
                        filterOperator: 'contains',
                        minWidth: '108px',
                        maxWidth: '100%'
                    },

                ]}
            />
    )}

export default injectIntl(PublicClubsTable);
