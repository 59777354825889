import { makeStyles } from '@material-ui/core/styles';
import QueryTable from 'components-lib/QueryTable/QueryTable';
import Button from "components/CustomButtons/Button.js";
import paths from 'paths';
import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';
import styles from "common/styles/widgets.js";
import Box from "@material-ui/core/Box";
import { colors, parameters } from 'common/styles/configLayout';
import moment from "moment";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import { Grid, Menu } from "@material-ui/core";
import CLUB_MEMBERS from "queries/MembersQueries/clubMembers";
import CLUB_MEMBER from 'queries/MembersQueries/clubMember';
import { getApolloClient } from "configFiles/apollo";
import { useSnackbar } from 'notistack';
import FormikField from "../../components-lib/FormikField/FormikField";
import { Formik } from "formik";
import Modal from "components-lib/Modal/Modal";
import checkboxStyles from "assets/jss/material-dashboard-pro-react/customTableCheckboxRadioSwitch.js";
import { connect } from 'react-redux';
import RoleEnum from 'common/enums/RoleEnum';
import CLUB_IS_LICENSED from 'queries/LicensesQueries/clubIsLicensed';
import LICENSING_INFO from "queries/GenerallyQueries/licensingInfo";
import { useQuery, useLazyQuery } from '@apollo/client';
import { getValidationSchemaYear } from 'pages/Licenses/validationSchema';
import { pathOr, map } from 'rambda';
import Tooltip from '@material-ui/core/Tooltip';
import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles((theme) => styles(theme))
const useCheckboxStyles = makeStyles(checkboxStyles);

export const MemberTable = (props) => {
    const { history, role, queryVariables, query, refetch, intl, defaultFilter = null, numberOfRows, queryDataPath, nextYear, currentYear } = props
    const classes = useStyles();
    const checkboxClasses = useCheckboxStyles();

    // const [getLicense, { data: clubMember, loading, error }] = useLazyQuery(CLUB_MEMBER);
    const { data: licensingInfoData } = useQuery(LICENSING_INFO);
    const { enqueueSnackbar } = useSnackbar();
    const client = getApolloClient(enqueueSnackbar, history, intl);
    const [selectedIds, setSelectedIds] = useState([])
    const [modalOpen, setModalOpen] = useState(false);
    const [checkedClub, setCheckedClub] = useState(undefined);
    const [currentLicensed, setCurrentLicensed] = useState(false);
    const [nextLicensed, setNextLicensed] = useState(false);
    const [isNextYearAvailable, setIsNextYearAvailable] = useState(false)

    useEffect(() => {
        setIsNextYearAvailable(licensingInfoData?.licensingInfo?.periods?.next?.isPurchasable)
    }, [licensingInfoData]);

    const [getLicense, { data: clubMember, loading, error }] = useLazyQuery(CLUB_MEMBER, {  
        onCompleted: (data) => {  
            // console.log('Data:', data);  
            
            // Extrakcia licencií z načítaných dát  
            const licenses = pathOr([], ['clubMember', 'licenses'], data);  
            
            // Filtrovanie licencií pre aktuálny a nasledujúci rok  
            const filteredLicenses = licenses.filter((license) =>   
                license.year === currentYear || license.year === currentYear + 1
            );  
    
            filteredLicenses.map((licenseUrl) => window.open(licenseUrl.downloadUrl, "_blank"));  
        },  
        onError: (error) => {  
            console.error('Error fetching license:', error.message || error);
        }  
    });

    const handleLicense = (id) => {  
        getLicense({ variables: { id } });  
    }; 
      

    const renderModalButtons = (formikProps) => {
        return (
            <>
                <Button
                    disabled={false}
                    onClick={() => formikProps.handleSubmit()}
                    color="primary"
                    round
                    table
                    size="sm">
                    Vytvoriť žiadosť
                </Button>
            </>
        );
    };

    const getClubMembersIds = async () => {
        try {
            const result = await client.query({
                query: CLUB_MEMBERS,
                variables: {
                    filter: {
                        clubId: {
                            eq: checkedClub
                        }
                    },
                }
            });
            // console.log(result)
            if (result.error) {
                throw result.error;
            }
            if (result.errors) {
                throw result.errors[0];
            }
            let ids = [];
            (result?.data?.clubMembers?.items).map(item =>
                ids.push(item?.id))
            setSelectedIds(ids);

        } catch (error) {
            throw error;
        }
    }

    const handleCheckClubLicensed = async () => {
        try {
            const result = await client.query({
                query: CLUB_IS_LICENSED,
                variables: {
                    id: checkedClub
                }
            });
            if (result.error) {
                throw result.error;
            }
            if (result.errors) {
                throw result.errors[0];
            }
            setCurrentLicensed(result?.data?.club?.licenses?.current?.isLicensed);
            setNextLicensed(result?.data?.club?.licenses?.next?.isLicensed);

        } catch (error) {
            throw error;
        }
    }

    const setChecked = (id, clubId) => {
        let currentIndex = selectedIds.indexOf(id);
        let newChecked = [...selectedIds]
        if (currentIndex === -1) {
            newChecked.push(id);
            if (selectedIds.length === 0) setCheckedClub(clubId);
        } else {
            newChecked.splice(currentIndex, 1);
            if (selectedIds.length === 1) setCheckedClub(undefined);
        }
        setSelectedIds(newChecked);
        //getMembersClubsCount(newChecked);
    }

    const isChecked = (id) => {
        let help = null
        selectedIds.filter(buttonId => Number(buttonId) === Number(id)).map(item => (
            help = 1
        ))
        if (help === 1) return true
        else return false
    }

    const onCompanyDetail = (id) => {
        history.push(`/admin${generatePath(paths.members.detail, { memberId: id })}`);
    }

    const onNewLicense = (year) => {
        history.push({
            pathname: `/admin${generatePath(paths.licenses.new)}`,
            search: `?clubId=${checkedClub}&year=${year}`,

            state: {
                membersIds: selectedIds,
            }
        })

    }

    const handleSetAllSelectedIds = () => {
        getClubMembersIds();
    }

    // console.log(RoleEnum.MEMBER)
    // console.log(role)

    return (<>
        <QueryTable
            nameTable={paths.members.list}
            query={query}
            numberOfRows={numberOfRows}
            permanentFilter={defaultFilter}
            queryDataPath={queryDataPath}
            queryVariables={queryVariables}
            checkboxes={true}
            // columnsWidth={[undefined, '150px', '150px', '130px', undefined, undefined, '130px', '130px', '130px', '130px', undefined]}
            columnsWidth={['auto', 'auto', 'auto', 'auto', undefined, undefined, 'auto', 'auto', 'auto', 'auto', undefined]}
            columns={[
                (role !== RoleEnum.MEMBER) ? {
                    Header: '.',
                    accessor: (rowData) => <div style={{ display: "inline" }}>
                        {((!checkedClub || checkedClub === rowData.clubId)) &&
                            <>
                                {(rowData.isLicensePurchasable) &&
                                    <Checkbox
                                        key="key"
                                        checked={isChecked(rowData.id)}
                                        tabIndex={-1}
                                        onClick={() => { setChecked(rowData.id, rowData.clubId) }}
                                        checkedIcon={<Check className={checkboxClasses.checkedIcon} />}
                                        icon={<Check className={checkboxClasses.uncheckedIcon} />}
                                        classes={{
                                            checked: checkboxClasses.checked,
                                            root: checkboxClasses.checkRoot
                                        }}
                                    />
                                }
                            </>
                        }
                    </div>,
                    checkbox: true,
                } : { Header: "." },
                {
                    Header: intl.formatMessage({ id: 'table.bid' }),
                    accessor: 'bid',
                    sortKey: 'bid',
                    filterKey: 'bid',
                    filterOperator: 'contains',
                    minWidth: '100px',
                    maxWidth: '100%'
                },
                {
                    Header: intl.formatMessage({ id: 'member.table.firstName' }),
                    accessor: 'firstName',
                    sortKey: "firstName",
                    filterKey: 'firstName',
                    filterOperator: 'contains',
                    minWidth: '89px',
                    maxWidth: '100%'
                },
                {
                    Header: intl.formatMessage({ id: 'member.table.lastName' }),
                    accessor: "lastName",
                    sortKey: "lastName",
                    filterKey: 'lastName',
                    filterOperator: 'contains',
                    minWidth: '113px',
                    maxWidth: '100%'
                },
                {
                    Header: intl.formatMessage({ id: 'member.table.club' }),
                    accessor: "club",
                    sortKey: "club",
                    filterKey: 'club',
                    filterOperator: 'contains',
                    minWidth: '100px',
                    maxWidth: '100%'
                },
                {
                    Header: intl.formatMessage({ id: 'member.table.functions' }),
                    accessor: "functions",
                    sortKey: "functions",
                    filterKey: "functions",
                    filterOperator: 'contains',
                    minWidth: '100px',
                    maxWidth: '100%'
                },
                {
                    Header: intl.formatMessage({ id: 'member.table.birth' }),
                    accessor: (rowData) => rowData.birth !== null ? moment(rowData.birth).format("DD.MM.YYYY") : "-",
                    sortKey: "birth",
                    filterKey: "birth",
                    filterOperator: 'between',
                    filterDataType: "dateBetween",
                    filterComponent: 'dateRange',
                },
                {
                    Header: intl.formatMessage({ id: 'member.table.license' }),
                    accessor: (rowData) => (rowData.license)
                        ? <Box style={{ color: (rowData.license >= currentYear) ? colors.success : '' }} > {rowData.license} </Box>
                        : "-",
                    sortKey: "license",
                    filterKey: "license",
                    filterOperator: 'eq',
                    filterDataType: 'number',
                    minWidth: '100px',
                    maxWidth: '100%'
                },
                {
                    Header: intl.formatMessage({ id: 'member.table.disciplines' }),
                    accessor: (rowData) => rowData.disciplines ? rowData.disciplines : "-",
                    sortKey: "disciplines",
                    filterKey: "disciplines",
                    filterOperator: 'contains',
                    minWidth: '106px',
                    maxWidth: '100%'
                },
                {
                    Header: intl.formatMessage({ id: 'member.table.registeredAt' }),
                    accessor: (rowData) => rowData.registeredAt !== null ? moment(rowData.registeredAt).format("DD.MM.YYYY") : "-",
                    sortKey: "registeredAt",
                    filterKey: "registeredAt",
                    filterOperator: 'between',
                    filterDataType: "dateBetween",
                    filterComponent: 'dateRange',
                },
                (role !== RoleEnum.MEMBER) ?
                    {
                        Header: ' ',
                        filterKey: "actions",
                        accessor: (rowData) => 
                        <>
                            <Box className={classes.floatRight}>
                                {/* {role === RoleEnum.ADMIN && <>
                                {(rowData.license === currentYear || rowData.year === currentYear + 1) && 
                                    <Tooltip title={"Stiahnuť PDF na tlač"}>
                                        <GetAppIcon className={classes.circle} onClick={() => handleLicense(rowData.id)} />
                                    </Tooltip>}
                            </>} */}
                                <Button table round size="sm" color="info"
                                onClick={() => onCompanyDetail(rowData.id)}>{intl.formatMessage({ id: 'table.detail' })}
                            {/* // onClick={() => console.log( useQuery(CLUB_MEMBER, { variables: { id: rowData.id } }) )}>{intl.formatMessage({ id: 'table.detail' })}</Button></Box> */}
                                    {/* // onClick={() => handleLicense(rowData.id)}>{intl.formatMessage({ id: 'table.detail' })} */}
                                </Button>
                            </Box>
                        </>,
                        minWidth: '103px',
                        maxWidth: '100%'
                    }
                    : { Header: " " }
            ]}
        />
        {((selectedIds.length !== 0)) &&
            <Box
                style={{ zIndex: 999, padding: "5px", position: "fixed", bottom: "5px", borderRadius: parameters.buttonsRounded }}
                display="flex"
                alignItems="center"
            >
                <Button
                    color="primary"
                    round
                    table
                    onClick={() => { (isNextYearAvailable) ? setModalOpen(true) : onNewLicense(currentYear); handleCheckClubLicensed() }}
                    size="sm"
                >
                    {intl.formatMessage({ id: 'member.button.requestLicense' })}
                    {<>
                        ({selectedIds.length} {
                            (selectedIds.length > 4)
                                ? intl.formatMessage({ id: "member.store3" })
                                : (selectedIds.length === 1)
                                    ? intl.formatMessage({ id: "member.store1" })
                                    : intl.formatMessage({ id: "member.store2" })

                        })</>
                    }
                </Button>

                <Button
                    //disabled={allMembersIds?.length === selectedIds?.length}
                    color="primary"
                    round
                    table
                    size="sm"
                    onClick={() => handleSetAllSelectedIds()}
                >
                    {intl.formatMessage({ id: "member.button.checkAll" })}
                </Button>

                <Button
                    color="danger"
                    size="sm"
                    round
                    table
                    onClick={() => { setSelectedIds([]); setCheckedClub(undefined) }}
                >
                    {intl.formatMessage({ id: "member.button.uncheckAll" })} ({selectedIds.length})
                </Button>
            </Box>
        }
        <Formik
            onSubmit={(values) => onNewLicense(values.year)}
            validationSchema={getValidationSchemaYear(intl)}
            initialValues={{}}
            enableReinitialize
        >
            {(formikProps) => (
                <>
                    <Modal
                        title={intl.formatMessage({ id: "member.table.modal.title" })}
                        open={modalOpen}
                        onClose={() => { setModalOpen(false); formikProps.resetForm() }}
                        actions={renderModalButtons(formikProps)}
                        fullWidth
                    >
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <FormikField
                                    name="year"
                                    labelText={intl.formatMessage({ id: "member.table.modal.year" })}
                                    mandatory={true}
                                    formikProps={formikProps}
                                    validateParam={true}
                                    highlightChange={false}
                                    type="select"
                                    options={[
                                        {
                                            value: currentYear,
                                            label: currentYear,
                                            disabled: (!currentLicensed) && `Klub nemá licenciu na rok ${currentYear}`
                                        },
                                        {
                                            value: nextYear,
                                            label: nextYear,
                                            disabled: (!nextLicensed) && `Klub nemá licenciu na rok ${nextYear}`

                                        }
                                    ]}
                                />
                            </Grid>
                        </Grid>
                    </Modal>
                </>
            )}
        </Formik >
    </>
    )
}

const mapStateToProps = (state) => ({
    role: state.role,
});

export default connect(mapStateToProps)(injectIntl(MemberTable));

//423