import React, { useState, useRef } from "react";

import { pathOr } from 'rambda';

// components
import Grid from '@material-ui/core/Grid';
import { Divider, Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import FormikField from "components-lib/FormikField/FormikField";
import Button from "components/CustomButtons/Button";

// css
import { colors } from "common/styles/configLayout.js";

import TextField from '@material-ui/core/TextField';
import useStyles from '../../components-lib/FormikField/styles';


const PriceListCategory = ({ 
    formikProps, 
    readMode, 
    table,
    bigScreen, 
    width, 
    editData, 
    setExpandedGroupEdit,
    expandedGroupEdit,
    animationClass,
    expandedGroup,
    toggleGroup,
}) => {

    const categories = editData ? pathOr([], ['pricing', 'categories'], formikProps.values.dataEdit) : pathOr([], ['pricing', 'categories'], formikProps.values.dataOld);

    // const [animationClass, setAnimationClass] = useState('');
    // const [expandedGroup, setExpandedGroup] = useState(null);

    // Group categories by the "group" field
    const groupedCategories = categories.reduce((acc, category) => {
        const { group } = category;
        if (!acc[group]) {
            acc[group] = [];
        }
        acc[group].push(category);
        return acc;
    }, {});

    // const toggleGroup = (group, table) => {

    //     if (bigScreen === false) {
    //         // Check if we're toggling for the "Old" or "Edit" table
    //         if (table === "old") {
    //             if (expandedGroup === group) {
    //                 setAnimationClass('slide-up');
    //                 setTimeout(() => {
    //                     setExpandedGroup(null);
    //                 }, 300);
    //             } else {
    //                 setExpandedGroup(group);
    //                 setAnimationClass('slide-down');
    //             }
    //         } else if (table === "edit") {
    //             if (expandedGroupEdit === group) {
    //                 setAnimationClass('slide-up');
    //                 setTimeout(() => {
    //                     setExpandedGroupEdit && setExpandedGroupEdit(null);
    //                 }, 300);
    //             } else {
    //                 setExpandedGroupEdit && setExpandedGroupEdit(group);
    //                 setAnimationClass('slide-down');
    //             }
    //         }
    //     } else {
    //         // Default behavior for smaller screens
    //         if (expandedGroup === group || expandedGroupEdit === group) {
    //             setAnimationClass('slide-up');
    //             setTimeout(() => {
    //                 setExpandedGroup(null);
    //                 setExpandedGroupEdit(null);
    //             }, 300);
    //         } else {
    //             setExpandedGroup(group);
    //             setExpandedGroupEdit(group);
    //             setAnimationClass('slide-down');
    //         }
    //     }
    // };
    
    
    

    // console.log(groupedCategories)
    const idToIndexMap = {};
        categories.forEach((category, idx) => {
            idToIndexMap[category.id] = idx;
        });

    return (
        <Box 
            display="flex" flexDirection="column" 
            width={width ? width :"100%"} 
            mt= "1rem"
        >
            {/* Poplatky za kategórie */}
            {/* {(bigScreen && !editData) || !bigScreen ? ( */}
                <Box p="0rem 0 1rem 1rem" 
                    style={{ visibility: (bigScreen && !editData) || !bigScreen ? 'visible' : 'hidden' }}
                >
                    <Typography 
                        variant="body1" 
                        style={{ color: colors.primary }}
                    >
                    Poplatky za kategórie
                    </Typography>
                </Box>
            {/* ) : null } */}

            {/* Štandardný poplatok, UCI poplatok */}
            <Box display="flex" justifyContent="space-between" p="1rem 0 1rem 0rem"
                style={{ boxSizing: 'unset', }}
            >
                {(bigScreen && !editData) || !bigScreen ? (
                <Box style={{ width: '33.33%' }}>
                    <Typography 
                        variant="body2" 
                        style={{ color: colors.primary, visibility: 'hidden' }}
                    >
                    hidden
                    </Typography>
                </Box>
                ) : null}

                <Box 
                    style={{ 
                        width: bigScreen ? '100%' : '67.77%',
                        display: 'flex', flexDirection: 'row',
                        boxSizing: 'inherit',
                    }}
                >

                    <Box 
                        style={{ 
                            width: bigScreen ? '33.33%' : '100%',
                            padding: bigScreen ? "0rem 0rem 0rem 0.5rem" : "0rem",
                            boxSizing: 'inherit',
                        }}>
                        <Typography 
                            variant="body2" 
                            style={{ color: colors.primary }}
                        >
                            Štandardný poplatok
                        </Typography>
                        {/* <Divider
                            orientation="horizontal"
                            style={{ margin: '0', backgroundColor: colors.primary, height: '1px', width: '100%' }}
                        /> */}
                    </Box>

                    <Box 
                        style={{
                            width: bigScreen ? '33.33%' : '100%',
                            padding: "0 0rem 0 1rem",
                            boxSizing: 'inherit',
                        }}>
                        <Typography 
                            variant="body2"
                            style={{ color: colors.primary }}
                        >
                            UCI poplatok
                        </Typography>
                    </Box>
                    
                    <Box 
                        style={{ 
                            width: bigScreen ? '33.33%' : '100%',
                            padding: "0 0rem 0 1rem",
                            boxSizing: 'inherit',
                        }}>
                        <Typography 
                            variant="body2"
                            style={{ color: colors.primary }}
                        >
                            Poplatok za prestup
                        </Typography>
                    </Box>
                </Box>
            </Box>

            {Object.entries(groupedCategories).map(([group, categories]) => {
                // console.log(categories)
                return (
                    <Box key={group} style={{ boxSizing: 'unset', }}>

                        { (bigScreen ? table === "old" : true) && (
                            <Box
                                // p="0rem 0 1rem 0rem"
                                style={{ 
                                    width: '100%',
                                    // marginBottom: '0.5rem',
                                    display: 'flex', flexDirection: 'column',
                                    // height: '40px', 
                                    boxSizing: 'inherit',
                                }}
                            >
                                <Box
                                    style={{ 
                                        width: '100%',
                                        paddingTop: '1rem',
                                        paddingBottom: '1rem',
                                        display: 'flex', flexDirection: 'row',
                                        height: '100%', boxSizing: 'inherit',
                                    }}
                                >
                                    <Box
                                        style={{
                                            width: '57%',
                                        }}
                                    >
                                        <Typography variant="h6" style={{ color: colors.primary }}>
                                            {group === 'null' ? 'Nezaradené' : group}
                                        </Typography>
                                    </Box>
                                    
                                    <Box
                                        style={{ 
                                            width: '21.5%',
                                            display: 'flex', flexDirection: 'row',
                                            paddingRight: '1rem', boxSizing: 'inherit'
                                        }}
                                    >
                                        <Box 
                                            style={{
                                                width: '100%',
                                                backgroundColor: '#364759',
                                                borderRadius: '10px',
                                                border: '1px solid rgba(60, 72, 88, 0.4)',
                                                color: '#F5F6F6',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                height: '1rem',
                                                visibility: "hidden",
                                            }}
                                        >
                                        </Box>
                                    </Box>

                                    <Box
                                        className={`priceToggleBtn ${expandedGroup === group ? 'expanded' : ''}`} 
                                        onClick={() => toggleGroup(group, table)}
                                        style={{ }}
                                    >
                                        <div
                                            // className={expandedGroup === group ? 'margin-expanded' : 'margin-collapsed'} 
                                            style={{ 
                                            marginTop: '5px', 
                                            height: '100%',
                                            display: 'flex', flexDirection: 'row',
                                            alignItems: 'center',
                                        }}>
                                            <i 
                                                style={{ 
                                                    fontSize: 28, color: colors.primary,
                                                    marginBottom: '18px', position: 'relative',
                                                }} 
                                                // className={`fas ${expandedGroup === group ? 'fa-sort-up expanded' : 'fa-sort-down'} toggle-icon`}
                                                className={`fas fa-sort-down ${expandedGroup === group ? 'expanded fa-sort-up' : ''} toggle-icon`}
                                            ></i>
                                        </div>
                                    </Box>
                                </Box>

                                <Divider
                                    orientation="horizontal"
                                    style={{ 
                                        backgroundColor: '#364759', 
                                        // marginTop: '1rem',
                                        height: '2px', width: '100%' }}
                                />

                            </Box>
                        )}
                        
                        <>
                        { editData && (
                            <Box 
                                display="flex" flexDirection={"column"}
                                // p="0rem 0 1rem 0rem" 
                                style={{ boxSizing: 'inherit', }}>

                                <Box 
                                    display="flex" justifyContent="space-between" 
                                    flexDirection={"row"}
                                    p="1rem 0 1rem 0rem" 
                                    style={{ boxSizing: 'inherit', }}>

                                    {(!bigScreen) && (
                                        <Box style={{ width: '33.33%' }}>
                                            <Typography 
                                                variant="body1" 
                                                style={{ color: colors.primary }}
                                            >
                                                Všetky
                                            </Typography>
                                        </Box>
                                    )}
                                    
                                    <Box 
                                        style={{ 
                                            width: bigScreen ? '100%' : '67.77%',
                                            display: 'flex', flexDirection: 'row',
                                            visibility: !editData ? 'hidden' : 'visible',
                                            boxSizing: "inherit",
                                        }}
                                    >
                                        <Box 
                                            style={{ 
                                                width: bigScreen ? '33.33%' : '100%',
                                                paddingRight: "1rem",
                                                paddingLeft: (bigScreen && editData) ? "0.5rem" : "0rem",
                                                boxSizing: "inherit",
                                            }}
                                        >
                                            <FormikField
                                                name={`${group}.standardFee`}
                                                labelText=""
                                                formikProps={formikProps}
                                                readMode={readMode ? readMode : 'textFieldInput'}
                                                inputHeight="1rem"
                                                onChange={(e) => {
                                                    const newValue = e.target.value;
                                                    categories.forEach((_, index) => {
                                                        const fieldName = editData
                                                            ? `dataEdit.pricing.categories[${index}].license.standardFee`
                                                            : `dataOld.pricing.categories[${index}].license.standardFee`;
                                                        formikProps.setFieldValue(fieldName, newValue);
                                                    });
                                                }}
                                            />
                                        </Box>

                                        <Box
                                            style={{ 
                                                width: bigScreen ? '33.33%' : '100%',
                                                paddingRight: "1rem", boxSizing: "inherit",
                                            }}
                                        >
                                            <FormikField
                                                name={`${group}.uciFee`}
                                                labelText=""
                                                formikProps={formikProps}
                                                readMode={readMode ? readMode : 'textFieldInput'}
                                                inputHeight="1rem"
                                                onChange={(e) => {
                                                    const newValue = e.target.value;
                                                    categories.forEach((_, index) => {
                                                        const fieldName = editData
                                                            ? `dataEdit.pricing.categories[${index}].license.uciFee`
                                                            : `dataOld.pricing.categories[${index}].license.uciFee`;
                                                        formikProps.setFieldValue(fieldName, newValue);
                                                    });
                                                }}
                                            />
                                        </Box>

                                        <Box
                                            style={{
                                                width: bigScreen ? '33.33%' : '100%',
                                                // paddingRight: "1rem",
                                                // paddingRight: (bigScreen && editData) ? "0.5rem" : "0rem",
                                                boxSizing: "inherit",
                                            }}
                                        >
                                            <FormikField
                                                name={`${group}.transferFee`}
                                                labelText=""
                                                formikProps={formikProps}
                                                readMode={readMode ? readMode : 'textFieldInput'}
                                                inputHeight="1rem"
                                                onChange={(e) => {
                                                    const newValue = e.target.value;
                                                    categories.forEach((_, index) => {
                                                        const fieldName = editData
                                                            ? `dataEdit.pricing.categories[${index}].license.transferFee`
                                                            : `dataOld.pricing.categories[${index}].license.transferFee`;
                                                        formikProps.setFieldValue(fieldName, newValue);
                                                    });
                                                }}
                                            />
                                        </Box>                                        
                                    </Box>
                                </Box>    

                                <Divider
                                    orientation="horizontal"
                                    style={{ 
                                        // marginTop: '0.5rem', 
                                        backgroundColor: '#364759', 
                                        height: '2px', width: '100%' }}
                                />

                            </Box>                                    
                        )}

                        {(expandedGroup === group || expandedGroupEdit === group) && 
                            categories.map(({ id, category, availableForClubs }) => {
                            const index = idToIndexMap[id];
                            const isFirst = index === 0;
                            const standardFeeName = editData ? `dataEdit.pricing.categories[${index}].license.standardFee` : `dataOld.pricing.categories[${index}].license.standardFee`;
                            const uciFeeName = editData ? `dataEdit.pricing.categories[${index}].license.uciFee` : `dataOld.pricing.categories[${index}].license.uciFee`;
                            const transferFeeName = editData ? `dataEdit.pricing.categories[${index}].license.transferFee` : `dataOld.pricing.categories[${index}].license.transferFee`;

                            console.log(availableForClubs)
                            return (
                                <Box 
                                    // key={index} 
                                    className={`${animationClass} `}
                                    display="flex" 
                                    flexDirection='column'
                                    p="0rem 0 0.5rem 0rem"
                                    style={{
                                        boxSizing: 'inherit',
                                        paddingTop: isFirst ? '0.5rem' : '0rem',
                                    }}
                                >
                                    <Box key={index} 
                                        className="priceExpandedBox"
                                        display="flex" 
                                        justifyContent="space-between" 
                                        flexDirection='row'
                                        style={{
                                            boxSizing: 'inherit'
                                        }}
                                    >
                
                                        {(bigScreen && !editData) || !bigScreen ? (
                                            <Box style={{ minWidth: '12rem', width: '33.33%', boxSizing: 'inherit' }}>
                                                <Typography 
                                                    variant="body1" 
                                                    style={{ color: colors.primary }}
                                                >
                                                    {category}
                                                </Typography>
                                            </Box>
                                        ): null}

                                        <Box 
                                            style={{ 
                                                width: bigScreen ? '100%' : '67.77%',
                                                display: 'flex', flexDirection: 'row',
                                                boxSizing: 'inherit',
                                                paddingRight: (bigScreen && !editData) ? "0.5rem" : "0rem",
                                            }}
                                        >
                                            <Box 
                                                className={index}  
                                                style={{ 
                                                    width: bigScreen ? '33.33%' : '100%',
                                                    paddingRight: "1rem",
                                                    paddingLeft: (bigScreen && editData) ? "0.5rem" : "0rem",
                                                    boxSizing: 'inherit',
                                                }}
                                            >
                                                <FormikField
                                                    name={standardFeeName}
                                                    labelText=""
                                                    formikProps={formikProps}
                                                    // readMode={(readMode) ? readMode : 'textFieldInput'}
                                                    readMode={(availableForClubs === 'All' || availableForClubs === 'Standard') ? readMode : (readMode || availableForClubs === 'UCI') ? 'readOnlyInput' : 'textFieldInput'}
                                                    inputHeight="1rem"
                                                />
                                            </Box>

                                            <Box 
                                                className={index}  
                                                style={{ 
                                                    width: bigScreen ? '33.33%' : '100%',
                                                    paddingRight:  "1rem", boxSizing: 'inherit',
                                                }}
                                            >
                                                <FormikField
                                                    name={uciFeeName}
                                                    labelText=''
                                                    formikProps={formikProps}
                                                    // readMode={readMode ? readMode : 'textFieldInput'}
                                                    readMode={(availableForClubs === 'All' || availableForClubs === 'UCI') ? readMode : (readMode || availableForClubs === 'Standard') ? 'readOnlyInput' : 'textFieldInput'}

                                                    inputHeight="1rem"
                                                />
                                            </Box>
                                            
                                            <Box 
                                                className={index}
                                                style={{ 
                                                    width: bigScreen ? '33.33%' : '100%',
                                                    boxSizing: 'inherit',
                                                }}
                                            >
                                                <FormikField
                                                    name={transferFeeName}
                                                    labelText=""
                                                    formikProps={formikProps}
                                                    readMode={readMode ? readMode : 'textFieldInput'}
                                                    inputHeight="1rem"
                                                />
                                            </Box>
                                        </Box>
                                    </Box>

                                    <Divider
                                        orientation="horizontal"
                                        style={{ 
                                            marginTop: '0.3rem', backgroundColor: '#364759', 
                                            height: '2px', width: '100%' }}
                                    />

                                </Box>
                            );
                            })}                            
                        </>
                    </Box>
                );
            })}
        </Box>
    );
};

export default PriceListCategory;