import React from 'react';
import { Grid, IconButton, Select, MenuItem } from '@material-ui/core'; // Updated to @material-ui/core
import { makeStyles } from '@material-ui/core/styles'; // Updated path for styles
import ChevronLeft from '@material-ui/icons/ChevronLeft'; // Updated to @material-ui/icons
import ChevronRight from '@material-ui/icons/ChevronRight'; // Updated to @material-ui/icons
import { setMonth, getMonth, setYear, getYear } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'space-around',
  },
  iconContainer: {
    padding: 5,
  },
  icon: {
    padding: 10,
    '&:hover': {
      background: 'none',
    },
  },
}));

const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'Máj', 'Jún', 'Júl', 'Aug', 'Sept', 'Okt', 'Nov', 'Dec'];

const generateYears = (relativeTo, count) => {
  const half = Math.floor(count / 2);
  return Array(count)
    .fill(0)
    .map((y, i) => relativeTo.getFullYear() - half + i); // TODO: make part of the state
};

const Header = ({ date, setDate, nextDisabled, prevDisabled, onClickNext, onClickPrevious }) => {
  const classes = useStyles();

  const handleMonthChange = (event) => {
    setDate(setMonth(date, parseInt(event.target.value, 10)));
  };

  const handleYearChange = (event) => {
    setDate(setYear(date, parseInt(event.target.value, 10)));
  };

  return (
    <Grid container alignItems="center" className={classes.root}>
      <Grid item className={classes.iconContainer}>
        <IconButton className={classes.icon} disabled={prevDisabled} onClick={onClickPrevious}>
          <ChevronLeft color={prevDisabled ? 'disabled' : 'action'} />
        </IconButton>
      </Grid>
      <Grid item>
        <Select
          variant="standard"
          value={getMonth(date)}
          onChange={handleMonthChange}
          MenuProps={{ disablePortal: true }}
        >
          {MONTHS.map((month, idx) => (
            <MenuItem key={month} value={idx}>
              {month}
            </MenuItem>
          ))}
        </Select>
      </Grid>

      <Grid item>
        <Select
          variant="standard"
          value={getYear(date)}
          onChange={handleYearChange}
          MenuProps={{ disablePortal: true }}
        >
          {generateYears(date, 30).map((year) => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item className={classes.iconContainer}>
        <IconButton className={classes.icon} disabled={nextDisabled} onClick={onClickNext}>
          <ChevronRight color={nextDisabled ? 'disabled' : 'action'} />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default Header;
