import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { injectIntl } from 'react-intl';
import PaymentCard from './PaymentCards/PaymentCard';
import PaymentInfoCard from './PaymentCards/PaymentInfoCard';
import PAYMENT from 'queries/PaymentsQueries/payment';
import { useQuery } from '@apollo/client';
import { pathOr } from 'rambda';
import Loading from "components-lib/Loading/Loading";
import DescriptionIcon from '@material-ui/icons/Description';
import PageHeader from "components-lib/PageHeader/PageHeader";

const PaymentDetailPage = (props) => {
    const { intl, history } = props
    const paymentId = props?.match?.params?.paymentId
    const [paymentData, setPaymentData] = useState();
    const [modalOpen, setModalOpen] = useState(false);
    const { loading: loadingPayment, data: dataPayment, refetch: refetchPayment } = useQuery(PAYMENT, { variables: { id: paymentId } });

    useEffect(() => {
        setPaymentData(pathOr('', ['payment'], dataPayment))
        // console.log(pathOr('', ['payment'], dataPayment))
    }, [dataPayment]);
    // console.log(paymentData)


    const handleInvoiceDownload = (url) => {
        window.open(url, "_blank").focus()
    }

    const getActions = () => {
        let array = [];
        if (pathOr('', ['status'], paymentData) === 'Succeeded' )
            array.push({
                title: intl.formatMessage({ id: 'payments.action.invoiceDownload' }),
                icon: <DescriptionIcon />,
                onClick: () => handleInvoiceDownload(pathOr('', ['invoiceUrl'], paymentData))
            })
        return array;
    }

    const handleBackAction = () => {
        history.goBack();
    };

    if (loadingPayment) return <Loading />
    return (
        <Grid container spacing={1}>
            <PageHeader
                withBackButton={true}
                handleBackAction={handleBackAction}
                actions={getActions()}
            />
            <Grid item xs={12} lg={4}>
                <Grid item xs={12}>
                    <PaymentInfoCard
                        data={paymentData}
                    />
                    <PaymentCard
                        refetch={refetchPayment}
                        data={paymentData}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default injectIntl(PaymentDetailPage);

//85