import React from 'react';
import { StylesProvider, createGenerateClassName } from '@material-ui/core/styles'; // Updated imports

// Import your DateRangePickerWrapper component
import DateRangePickerWrapper from './DateRangePickerWrapper';

const generateClassName = createGenerateClassName({
  productionPrefix: 'date-range-picker'
});

const DateRangePickerExporter = (props) => (
  <StylesProvider generateClassName={generateClassName}>
    <DateRangePickerWrapper
      {...props}
    />
  </StylesProvider>
);

export default DateRangePickerExporter;

