import LoginPage from "pages/AuthPages/LoginPage.js";
import RegisterPage from "pages/AuthPages/RegisterPage.js";
import ConfirmPage from "pages/AuthPages/ConfirmPage.js";
import ActivatePage from "pages/AuthPages/ActivatePage.js";
import ForgottenPassword from "pages/AuthPages/ForgottenPassword";
import PasswordResetPage from "pages/AuthPages/PasswordResetPage";
import PublicMemberListPage from "pages/PublicPages/PublicMemberListPage";
import PublicClubListPage from "pages/PublicPages/PublicClubListPage";

import ActivityListPage from "pages/Activities/ActivityListPage";
import DashboardIcon from "@material-ui/icons/Dashboard";
import SettingsIcon from '@material-ui/icons/Settings';
import paths from "paths";
import LogoutPage from "pages/AuthPages/LogoutPage";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ProfilePage from "pages/Profile/ProfilePage"
import ClubListPage from 'pages/Clubs/ClubListPage';
import RequestListPage from 'pages/Requests/RequestListPage';
import RequestManagementPage from 'pages/Requests/RequestManagementPage';
import DocumentListPage from 'pages/Documents/DocumentListPage';
import ClubDetailPage from 'pages/Clubs/ClubDetailPage';
import ClubNewPage from 'pages/Clubs/ClubNewPage';
import MemberInvitePage from 'pages/Members/MemberCards/MemberInvitePage';
import StatutoryInvitePage from "pages/Members/MemberCards/StatutoryInvitePage";
import UserInvitePage from "pages/AuthPages/UserInvitePage";
import LicenseListPage from 'pages/Licenses/LicenseListPage';
import LicenseDetailPage from 'pages/Licenses/LicenseDetailPage';
import LicenseNewPage from 'pages/Licenses/LicenseNewPage';
import TransferListPage from 'pages/Transfers/TransferListPage';
import TransferDetailPage from 'pages/Transfers/TransferDetailPage';
import TransferNewPage from 'pages/Transfers/TransferNewPage';
import PaymentListPage from 'pages/Payments/PaymentListPage';
import PaymentDetailPage from 'pages/Payments/PaymentDetailPage';
import MemeberListPage from "pages/Members/MemeberListPage";
import NotificationsListPage from "pages/Notifications/NotificationsListPage";
import MemberDetailPage from "pages/Members/MemberDetailPage";
import PriceListPage from "pages/PriceList/PriceListPage";
import BusinessIcon from '@material-ui/icons/Business';
import PersonIcon from '@material-ui/icons/Person';
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import CommentIcon from '@material-ui/icons/Comment';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import GroupIcon from '@material-ui/icons/Group';
import PieChartIcon from '@material-ui/icons/PieChart';
import SubtitlesIcon from '@material-ui/icons/Subtitles';
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import RequestNewPage from "pages/Requests/RequestNewPage";
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import DescriptionIcon from '@material-ui/icons/Description';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import PaymentIcon from '@material-ui/icons/Payment';
import NotificationsIcon from '@material-ui/icons/Notifications';

import RoleEnum from 'common/enums/RoleEnum';

const getRoutes = (intl, role, pendingRequestsNumber, licensesNumber, documentsNumber, pendingTransfersAndHostingNumber) => ([
  {
    path: paths.profile,
    name: intl.formatMessage({ id: 'routes.profile' }),
    decorate: true,
    //hideInMenu: (role === RoleEnum.USER) && true,
    icon: PersonIcon,
    component: ProfilePage,
    layout: '/admin'
  },
  {
    path: paths.activities.list,
    name: "Aktivity",
    icon: PieChartIcon,
    hideInMenu: (role !== RoleEnum.ADMIN) && true,
    forRoles: [RoleEnum.ADMIN],
    component: ActivityListPage,
    layout: '/admin'
  },
  {
    path: (role === RoleEnum.USER) ? paths.profile : paths.clubs.list,
    name: (role === RoleEnum.ADMIN) ? intl.formatMessage({ id: 'routes.clubs' }) : "Klub",
    hideInMenu: (role === RoleEnum.USER) && true,
    icon: DirectionsBikeIcon,
    //forRoles: [RoleEnum.ADMIN, RoleEnum.USER],
    component: (role === RoleEnum.USER) ? ProfilePage : ClubListPage,
    event: (pendingRequestsNumber && (role === RoleEnum.STATUTORY || role === RoleEnum.PRESIDENT)) ? pendingRequestsNumber : undefined,
    layout: '/admin'
  },
  {
    path: paths.clubs.detail,
    name: intl.formatMessage({ id: 'routes.clubs' }),
    hideInMenu: true,
    icon: DirectionsBikeIcon,
    component: ClubDetailPage,
    forRoles: [RoleEnum.ADMIN, RoleEnum.STATUTORY, RoleEnum.PRESIDENT, RoleEnum.MEMBER],
    layout: '/admin'
  },
  {
    path: paths.clubs.myDetail,
    name: intl.formatMessage({ id: 'routes.clubs' }),
    hideInMenu: true,
    icon: DirectionsBikeIcon,
    component: ClubDetailPage,
    layout: '/admin'
  },
  {
    path: paths.clubs.new,
    name: intl.formatMessage({ id: 'routes.clubs' }),
    hideInMenu: true,
    icon: BusinessIcon,
    component: ClubNewPage,
    forRoles: [RoleEnum.ADMIN],
    layout: '/admin'
  },
  {
    path: paths.clubs.invite,
    name: intl.formatMessage({ id: 'routes.clubs' }),
    hideInMenu: true,
    icon: BusinessIcon,
    component: MemberInvitePage,
    forRoles: [RoleEnum.ADMIN, RoleEnum.STATUTORY, RoleEnum.PRESIDENT],
    layout: '/admin'
  },
  {
    path: paths.members.list,
    name: intl.formatMessage({ id: 'routes.members' }),
    hideInMenu: (role === RoleEnum.USER) && true,
    icon: GroupIcon,
    component: MemeberListPage,
    forRoles: [RoleEnum.ADMIN, RoleEnum.STATUTORY, RoleEnum.PRESIDENT, RoleEnum.MEMBER],
    layout: '/admin'
  },
  {
    path: paths.members.detail,
    name: intl.formatMessage({ id: 'routes.members' }),
    hideInMenu: true,
    component: MemberDetailPage,
    forRoles: [RoleEnum.ADMIN, RoleEnum.STATUTORY, RoleEnum.PRESIDENT],
    layout: '/admin'
  },
  {
    path: paths.members.invite,
    name: intl.formatMessage({ id: 'routes.members' }),
    hideInMenu: true,
    icon: BusinessIcon,
    component: StatutoryInvitePage,
    forRoles: [RoleEnum.ADMIN],
    layout: '/admin'
  },
  {
    path: paths.licenses.list,
    name: intl.formatMessage({ id: 'routes.licenses' }),
    icon: AssignmentIndIcon,
    hideInMenu: (role === RoleEnum.MEMBER || role === RoleEnum.USER) && true,
    component: LicenseListPage,
    forRoles: [RoleEnum.ADMIN, RoleEnum.STATUTORY, RoleEnum.PRESIDENT],
    event: (licensesNumber) ? licensesNumber : undefined,
    layout: '/admin'
  },
  {
    path: paths.licenses.detail,
    name: intl.formatMessage({ id: 'routes.licenses' }),
    hideInMenu: true,
    icon: SubtitlesIcon,
    component: LicenseDetailPage,
    forRoles: [RoleEnum.ADMIN, RoleEnum.STATUTORY, RoleEnum.PRESIDENT],
    layout: '/admin'
  },
  {
    path: paths.licenses.new,
    name: intl.formatMessage({ id: 'routes.licenses' }),
    hideInMenu: true,
    icon: SubtitlesIcon,
    component: LicenseNewPage,
    forRoles: [RoleEnum.ADMIN, RoleEnum.STATUTORY, RoleEnum.PRESIDENT],
    layout: '/admin'
  },
  {
    path: paths.licenses.newCard,
    name: intl.formatMessage({ id: 'routes.licenses' }),
    hideInMenu: true,
    icon: SubtitlesIcon,
    component: LicenseNewPage,
    forRoles: [RoleEnum.ADMIN, RoleEnum.STATUTORY, RoleEnum.PRESIDENT],
    layout: '/admin'
  },
  {
    path: paths.transfers.list,
    name: intl.formatMessage({ id: 'routes.transfers' }),
    icon: TransferWithinAStationIcon,
    component: TransferListPage,
    hideInMenu: (role === RoleEnum.USER) && true,
    //hideInMenu: true,
    forRoles: [RoleEnum.ADMIN, RoleEnum.STATUTORY, RoleEnum.PRESIDENT, RoleEnum.MEMBER],
    event: (pendingTransfersAndHostingNumber) ? pendingTransfersAndHostingNumber : undefined,
    layout: '/admin'
  },
  {
    path: paths.transfers.detail,
    name: intl.formatMessage({ id: 'routes.transfers' }),
    hideInMenu: true,
    icon: TransferWithinAStationIcon,
    component: TransferDetailPage,
    forRoles: [RoleEnum.ADMIN, RoleEnum.STATUTORY, RoleEnum.PRESIDENT, RoleEnum.MEMBER],
    layout: '/admin'
  },
  {
    path: paths.transfers.new,
    name: intl.formatMessage({ id: 'routes.transfers' }),
    hideInMenu: true,
    icon: TransferWithinAStationIcon,
    component: TransferNewPage,
    forRoles: [RoleEnum.ADMIN, RoleEnum.STATUTORY, RoleEnum.PRESIDENT],
    layout: '/admin'
  },
  {
    path: paths.hosting.detail,
    name: intl.formatMessage({ id: 'routes.hosting' }),
    hideInMenu: true,
    icon: TransferWithinAStationIcon,
    component: TransferDetailPage,
    forRoles: [RoleEnum.ADMIN, RoleEnum.STATUTORY, RoleEnum.PRESIDENT, RoleEnum.MEMBER],
    layout: '/admin'
  },
  {
    path: paths.hosting.new,
    name: intl.formatMessage({ id: 'routes.hosting' }),
    hideInMenu: true,
    icon: TransferWithinAStationIcon,
    component: TransferNewPage,
    forRoles: [RoleEnum.ADMIN, RoleEnum.STATUTORY, RoleEnum.PRESIDENT],
    layout: '/admin'
  },
  {
    path: paths.requests.list,
    name: (role === RoleEnum.USER) ? "Klub" : intl.formatMessage({ id: 'routes.requests' }),
    icon: (role === RoleEnum.USER) ? DirectionsBikeIcon : PlaylistAddCheckIcon,
    hideInMenu: (role === RoleEnum.MEMBER || role === RoleEnum.STATUTORY || role === RoleEnum.PRESIDENT) && true,
    event: (pendingRequestsNumber) ? pendingRequestsNumber : undefined,
    forRoles: [RoleEnum.ADMIN, RoleEnum.STATUTORY, RoleEnum.PRESIDENT, RoleEnum.USER],
    component: RequestListPage,
    layout: '/admin'
  },
  {
    path: paths.requests.detail,
    name: "Klub",
    hideInMenu: true,
    forRoles: [RoleEnum.ADMIN, RoleEnum.STATUTORY, RoleEnum.PRESIDENT, RoleEnum.USER],
    icon: DirectionsBikeIcon,
    component: RequestManagementPage,
    layout: '/admin'
  },
  {
    path: paths.requests.new,
    name: intl.formatMessage({ id: 'routes.clubs' }),
    hideInMenu: true,
    icon: BusinessIcon,
    component: RequestNewPage,
    forRoles: [RoleEnum.STATUTORY, RoleEnum.PRESIDENT, RoleEnum.MEMBER, RoleEnum.USER],
    layout: '/admin'
  },
  {
    path: paths.documents.list,
    name: intl.formatMessage({ id: 'routes.documents' }),
    icon: DescriptionIcon,
    hideInMenu: (role !== RoleEnum.ADMIN) && true,
    event: (documentsNumber) ? documentsNumber : undefined,
    forRoles: [RoleEnum.ADMIN],
    component: DocumentListPage,
    layout: '/admin'
  },
  {
    path: paths.payments.list,
    name: intl.formatMessage({ id: 'routes.payments' }),
    icon: AccountBalanceWalletIcon,
    component: PaymentListPage,
    hideInMenu: (role === RoleEnum.MEMBER || role === RoleEnum.USER) && true,
    forRoles: [RoleEnum.ADMIN, RoleEnum.STATUTORY, RoleEnum.PRESIDENT],
    //event: 5,
    layout: '/admin'
  },
  {
    path: paths.payments.detail,
    name: intl.formatMessage({ id: 'routes.payments' }),
    hideInMenu: true,
    icon: AccountBalanceWalletIcon,
    component: PaymentDetailPage,
    forRoles: [RoleEnum.ADMIN, RoleEnum.STATUTORY, RoleEnum.PRESIDENT],
    layout: '/admin'
  },
  {
    path: paths.prices.list,
    name: intl.formatMessage({ id: 'routes.priceList' }),
    icon: MonetizationOnIcon,
    component: PriceListPage,
    hideInMenu: (role === RoleEnum.MEMBER || role === RoleEnum.USER) && true,
    forRoles: [RoleEnum.ADMIN],
    layout: '/admin'
  },
  {
    path: paths.notifications.list,
    name: intl.formatMessage({ id: 'routes.notifications' }),
    icon: NotificationsIcon,
    hideInMenu: (role === RoleEnum.MEMBER || role === RoleEnum.USER) && true,
    forRoles: [RoleEnum.ADMIN],
    component: NotificationsListPage,
    layout: '/admin'
  },





  /*(groupList) ?
    {
      path: paths.groups.list,
      name: intl.formatMessage({ id: 'routes.groups' }),
      hideInMenu: (role !== RoleEnum.ADMIN) && true,
      component: GroupPage,
      icon: GroupAddIcon,
      layout: '/admin'
    } : { hideInMenu: true },
  */
  /*(settingsDetail) ?
    {
      path: paths.settings,
      name: intl.formatMessage({ id: 'routes.settings' }),
      hideInMenu: (!settingsDetail) && true,
      component: SettingsPage,
      icon: SettingsIcon,
      layout: '/admin'
    } : { hideInMenu: true },*/

  /*{
    path: paths.auth.logout,
    name: intl.formatMessage({ id: 'routes.logout' }),
    component: LogoutPage,
    icon: ExitToAppIcon,
    layout: '/admin'
  },*/
  {
    path: paths.auth.login,
    name: intl.formatMessage({ id: 'routes.login' }),
    hideInMenu: true,
    component: LoginPage,
    layout: '/auth'
  },

  {
    path: paths.auth.register,
    name: intl.formatMessage({ id: 'routes.register' }),
    hideInMenu: true,
    component: RegisterPage,
    layout: '/auth'
  },
  {
    path: paths.auth.confirm,
    name: intl.formatMessage({ id: 'routes.confirm' }),
    hideInMenu: true,
    component: ConfirmPage,
    layout: '/auth'
  },
  {
    path: paths.auth.activate,
    name: intl.formatMessage({ id: 'routes.activate' }),
    hideInMenu: true,
    component: ActivatePage,
    layout: '/auth'
  },
  {
    path: paths.auth.passwordReset,
    name: intl.formatMessage({ id: 'routes.activate' }),
    hideInMenu: true,
    component: PasswordResetPage,
    layout: '/auth'
  },
  {
    path: paths.auth.forgottenPassword,
    name: intl.formatMessage({ id: 'routes.forgottenPassword' }),
    component: ForgottenPassword,
    hideInMenu: true,
    layout: '/auth'
  },
  {
    path: paths.auth.members,
    name: intl.formatMessage({ id: 'routes.forgottenPassword' }),
    component: PublicMemberListPage,
    hideInMenu: true,
    layout: ''
  },
  {
    path: paths.public.clubs,
    name: intl.formatMessage({ id: 'routes.forgottenPassword' }),
    component: PublicClubListPage,
    hideInMenu: true,
    layout: ''
  }
]);

export default getRoutes;


//{
//    path: paths.invite,
//    name: "Pozvanka - testing",
//    decorate: true,
//    hideInMenu: true,
//    icon: PersonIcon,
//    component: UserInvitePage,
//    layout: '/admin'
//},