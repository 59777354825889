import React from "react";

import { pathOr } from 'rambda';

// components
import Grid from '@material-ui/core/Grid';
import { Divider, Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import FormikField from "components-lib/FormikField/FormikField";

// css
import { colors } from "common/styles/configLayout.js";


const PriceListClubsMembers = ({ formikProps, readMode, bigScreen, width, editData }) => {
    const functions = editData ? pathOr([], ['pricing', 'functions'], formikProps.values.dataEdit) : pathOr([], ['pricing', 'functions'], formikProps.values.dataOld);

    // console.log(functions)
    return (
        <Box 
            display="flex" flexDirection="column" 
            width={width ? width :"100%"} 
            mt= "1rem"
        >
            {/* Poplatky za licencie členov klubu */}
            <Box p="0rem 0 1rem 1rem" style={{ visibility: bigScreen ? 'hidden' : 'visible' }}>
                <Typography 
                    variant="body1" 
                    style={{ color: colors.primary }}
                >
                Poplatky za licencie členov klubu
                </Typography>
            </Box>

            {/* Obnova poplatok a Registrácia poplatok */}
            <Box display="flex" justifyContent="space-between" p="1rem 0 1rem 1rem">
                {(!bigScreen) && (
                <Box flex={1} style={{ width: bigScreen ? '33.33%' : '100%' }}>
                    <Typography 
                        variant="body2" 
                        style={{ color: colors.primary, visibility: 'hidden' }}
                    >
                    hidden
                    </Typography>
                </Box>
                )}

                <Box flex={1} 
                    style={{ 
                        width: bigScreen ? '33.33%' : '100%',
                        padding: "0 1rem 0 0.5rem",
                    }}>
                    <Typography 
                        variant="body2" 
                        style={{ color: colors.primary }}
                    >
                        Poplatok
                    </Typography>
                </Box>

                <Box flex={1} 
                    style={{ 
                        width: bigScreen ? '33.33%' : '100%',
                        padding: "0 1rem 0 0.5rem",
                    }}>
                    <Typography 
                        variant="body2"
                        style={{ color: colors.primary }}
                    >
                        Express poplatok
                    </Typography>
                </Box>
            </Box>

            {functions.map(({ function: functionName, license }, index) => {
            const feeName = editData ? `dataEdit.pricing.functions[${index}].license.fee` : `dataOld.pricing.functions[${index}].license.fee`;
            const expressFeeName = editData ? `dataEdit.pricing.functions[${index}].license.expressFee` : `dataOld.pricing.functions[${index}].license.expressFee`;

            return (
                <Box 
                    display="flex" flexDirection="column"
                    // p="0rem 0 0rem 0rem"
                >
                    <Box display="flex" justifyContent="space-between" p="0.6rem 0 0.5rem 0rem">
                        {(!bigScreen) && (
                        <Box flex={1} style={{ width: bigScreen ? '33.33%' : '100%' }}>
                            <Typography 
                                variant="body1" 
                                style={{ color: colors.primary }}
                            >
                            {functionName}
                            </Typography>
                        </Box>
                        )}

                        <Box flex={1} 
                            style={{ 
                                width: bigScreen ? '33.33%' : '100%',
                                paddingRight: "1rem",
                                paddingLeft: bigScreen ? "0.5rem" : "0rem",
                            }}
                        >
                            <FormikField
                                name={feeName}
                                labelText={''}
                                formikProps={formikProps}
                                readMode={readMode ? readMode : 'textFieldInput'}
                                inputHeight="1rem"
                            />
                        </Box>

                        <Box flex={1} 
                            style={{ 
                                width: bigScreen ? '33.33%' : '100%',
                                paddingRight: bigScreen ? "0rem" : "0.5rem",
                            }}
                        >
                            <FormikField
                                name={expressFeeName}
                                labelText={''}
                                formikProps={formikProps}
                                readMode={readMode ? readMode : 'textFieldInput'}
                                inputHeight="1rem"
                            />
                        </Box>
                    </Box>

                    <Divider
                        orientation="horizontal"
                        style={{ 
                            // marginTop: '0.3rem', 
                            backgroundColor: '#364759', 
                            height: '2px', width: '100%' }}
                    />
                </Box>
            )
            })}

            {/* <Divider
                orientation="horizontal"
                style={{ margin: '0', backgroundColor: colors.primary, height: '1px', width: '100%' }}
            /> */}

        </Box>
    )
};

export default PriceListClubsMembers;