
import {
	addDays,
	startOfWeek,
	endOfWeek,
	addWeeks,
	startOfMonth,
	endOfMonth,
	addMonths
} from "date-fns";

export const getDefaultRanges = (date) => [
	{
		label: "Dnes",
		startDate: date,
		endDate: date
	},
	{
		label: "Včera",
		startDate: addDays(date, -1),
		endDate: addDays(date, -1)
	},
	{
		label: "Tento týždeň",
		startDate: startOfWeek(date),
		endDate: endOfWeek(date)
	},
	{
		label: "Minulý týždeň",
		startDate: startOfWeek(addWeeks(date, -1)),
		endDate: endOfWeek(addWeeks(date, -1))
	},
	{
		label: "Posledných sedem dní",
		startDate: addWeeks(date, -1),
		endDate: date
	},
	{
		label: "Tento mesiac",
		startDate: startOfMonth(date),
		endDate: endOfMonth(date)
	},
	{
		label: "Minulý mesiac",
		startDate: startOfMonth(addMonths(date, -1)),
		endDate: endOfMonth(addMonths(date, -1))
	}
];

// export const defaultRanges = getDefaultRanges(new Date());
