import QueryTable from 'components-lib/QueryTable/QueryTable';
import React from 'react';
import { injectIntl } from 'react-intl';
import moment from 'moment'

export const PublicMembersTable = (props) => {
    const { queryVariables, query, intl, defaultFilter = null, queryDataPath } = props

    return (
        <QueryTable
            query={query}
            permanentFilter={defaultFilter}
            queryDataPath={queryDataPath}
            queryVariables={queryVariables}
            columns={[
                {
                    Header: intl.formatMessage({ id: 'publicPages.members.table.federationId' }),
                    accessor: "federationId",
                    sortKey: "federationId",
                    filterKey: 'federationId',
                    filterOperator: 'eq',
                    filterDataType: 'number',
                    minWidth: '130px',
                    maxWidth: '100%'
                },
                {
                    Header: intl.formatMessage({ id: 'publicPages.members.table.uciId' }),
                    accessor: 'uciId',
                    sortKey: 'uciId',
                    filterKey: 'uciId',
                    filterOperator: 'contains',
                    minWidth: '100px',
                    maxWidth: '100%'
                },
                {
                    Header: intl.formatMessage({ id: 'publicPages.members.table.country' }),
                    accessor: "country",
                    sortKey: "country",
                    filterKey: 'country',
                    filterOperator: 'contains',
                    minWidth: '118px',
                    maxWidth: '100%'
                },
                {
                    Header: intl.formatMessage({ id: 'publicPages.members.table.firstName' }),
                    accessor: "firstName",
                    sortKey: "firstName",
                    filterKey: "firstName",
                    filterOperator: 'contains',
                    minWidth: '88px',
                    maxWidth: '100%'
                },
                {
                    Header: intl.formatMessage({ id: 'publicPages.members.table.lastName' }),
                    accessor: "lastName",
                    sortKey: "lastName",
                    filterKey: "lastName",
                    filterOperator: 'contains',
                    minWidth: '114px',
                    maxWidth: '100%'
                },
                {
                    Header: intl.formatMessage({ id: 'publicPages.members.table.year' }),
                    accessor: "year",
                    sortKey: "year",
                    filterKey: 'year',
                    filterOperator: 'eq',
                    filterDataType: 'number',
                    minWidth: '135px',
                    maxWidth: '100%'
                },
                {
                    Header: intl.formatMessage({ id: 'publicPages.members.table.gender' }),
                    accessor: (rowData) => (rowData.gender) ? (rowData.gender === "Male") ? intl.formatMessage({ id: 'select.Male' }) : intl.formatMessage({ id: 'select.Female' }) : "-",
                    sortKey: "gender",
                    filterKey: "gender",
                    filterComponent: 'select',
                    filterOperator: 'eq',
                    filterOptions: [
                        {
                            value: "Male",
                            label: intl.formatMessage({ id: 'select.Male' })
                        },
                        {
                            value: "Female",
                            label: intl.formatMessage({ id: 'select.Female' })
                        },
                    ],
                    minWidth: '121px',
                    maxWidth: '100%'
                },
                {
                    Header: intl.formatMessage({ id: 'publicPages.members.table.team' }),
                    accessor: "team",
                    sortKey: "team",
                    filterKey: "team",
                    filterOperator: 'contains',
                    minWidth: '141px',
                    maxWidth: '100%'
                },
                {
                    Header: intl.formatMessage({ id: 'publicPages.members.table.nationalCategory' }),
                    accessor: "nationalCategory",
                    sortKey: "nationalCategory",
                    filterKey: "nationalCategory",
                    filterOperator: 'contains',
                    minWidth: '159px',
                    maxWidth: '100%'
                },
                {
                    Header: intl.formatMessage({ id: 'publicPages.members.table.uciCategory' }),
                    accessor: "uciCategory",
                    sortKey: "uciCategory",
                    filterKey: "uciCategory",
                    filterOperator: 'contains',
                    minWidth: '133px',
                    maxWidth: '100%'
                },
                {
                    Header: intl.formatMessage({ id: 'publicPages.members.table.licensedAt' }),
                    accessor: (rowData) => (rowData.licensedAt) ? moment(rowData.licensedAt).format("DD.MM.YYYY") : "-",
                    sortKey: "licensedAt",
                    filterKey: "licensedAt",
                    filterOperator: 'between',
                    filterDataType: "dateBetween",
                    filterComponent: 'title',
                    minWidth: '152px',
                    maxWidth: '100%'
                },

            ]}
        />
    )
}

export default injectIntl(PublicMembersTable);
